.settingsAccordionContainer {
	width: 100%;
	min-height: 45vh;
}
.cabinetSettingsContainer h5,
.personalCabSettingsMobile h5 {
	/*margin-top: 40px;*/
	margin-bottom: 35px;
	font-family: var(--myMainFontBold) sans-serif;
	font-size: 21px;
	line-height: 21px;
	color: #3d3d3d;
}
.settingsTopBlock {
	height: 121px;
	padding: 23px 0 26px 28px;
	margin-bottom: 20px;
	background: #ffffff;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.topResume {
	display: flex;
	gap: 221px;
}
.topResumeSwitch,
.topPortfolioSwitch {
	display: flex;
	gap: 25px;
	align-items: center;
}
.topResumeSwitch span,
.topPortfolioSwitch span {
	font-size: 18px;
	color: #3d3d3d;
}
.topResumeEdit,
.topPortfolioEdit {
	display: flex;
	align-items: center;
	gap: 14px;
	cursor: pointer;
}
.topResumeEdit span,
.topPortfolioEdit span {
	font-size: 18px;
	line-height: 15px;
	text-decoration-line: underline;
	color: #00a9ce;
}
.topPortfolio {
	display: flex;
	gap: 195px;
}
.settingsPremiumSettings {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 21px 26px 15px 28px;
	height: 70px;
	background: #ffffff;
	border-radius: 4px;
}
.premiumSettingsLeft {
	display: flex;
	align-items: center;
}
.premiumLeft_Switch {
	margin-right: 25px;
}
.premiumLeft_Title {
	font-family: var(--myMainFontBold) sans-serif;
	font-size: 18px;
	line-height: 18px;
	color: #0eab1e;
	margin-right: 12px;
}
.premiumLeft_infoText {
	margin-left: 31px;
	width: 211px;
	height: 34px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 14px;
	color: #727272;
}
.premiumSettingsRight {
	font-size: 18px;
	color: #727272;
}
/*Language Switch*/

.switcherContainer {
	display: flex;
	flex-direction: column;
	height: 125px;
	padding: 0 12px;
}

.switcherTitle {
	color: #3d3d3d;
	font-size: 14px;
	padding: 20px 0;
}

.switcherButtonsContainer {
	display: flex;
	gap: 20px;
}

.switcherButton {
	border: 1px solid #898c8f;
	border-radius: 5px;
	width: 158px;
	height: 40px;
}

.switcherButtonActive {
	border: 1px solid #d9d9d9;
	color: #d9d9d9;
	border-radius: 5px;
	width: 158px;
	height: 40px;
}


@media (max-width: 1025px) {
	.personalCabSettingsMobile h5 {
		margin: 25px 22px;
		font-family: var(--myMainFontRegular) sans-serif;
		font-size: 18px;
		color: #3d3d3d;
	}
	.settingsTopBlock {
		height: 133px;
		padding: 20px 0 31px 17px;
		margin-bottom: 10px;
	}
	.topResume {
		gap: 45px;
	}
	.topPortfolio {
		gap: 19px;
	}
	.settingsPremiumSettings {
		display: block;
		padding: 26px 90px 20px 17px;
		height: 95px;
	}
	.premiumSettingsRight {
		margin-top: 4px;
		font-family: var(--myMainFontRegular) sans-serif;
		font-size: 16px;
		color: #727272;
	}
	.settingsMobileAccordionContainer {
		/*padding: 0 9px;*/
		padding: 0 9px 65px;
	}
}
