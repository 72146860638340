.popularSectionWrapper {
	width: 100%;
	background: #ffffff;
	/*z-index: 1;*/
}

.popularSectionContainer {
	/*position: relative;*/
	width: 100%;
	background: #ffffff;
	border-radius: 5px;
	text-align: center;
}

.popularSectionContainer h1 {
	padding: 35px 0 11px 0;
	font-family: var(--myMainFontBold);
	font-size: 18px;
	line-height: 18px;
	text-transform: uppercase;
	color: #2b2a38;
	border-radius: 5px;
}

.boardWrapper {
	width: 100%;
}

.boardContainer {
	/*width: 1395px;
    min-height:340px;
    margin-left: 48px;*/
}

.boardContainerRow {
	/* min-height: 565px;*/
	/*width: 100%;*/
	display: flex;
	padding: 43px 0 67px 34px;
	/*gap: 50px;*/
	flex-wrap: wrap;
}

.catalogItem {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-width: 100px;
	cursor: pointer;
	caret-color: transparent;
	align-items: center;
}

.boardContainerRow .catalogItem:first-child {
	margin-left: 15px;
}

.imageContainerFon {
	width: 90px;
	height: 90px;
	position: absolute;
	overflow: hidden;
	border-radius: 50px;
}
.imageContainerFon:hover {
	background-color: rgba(0, 0, 0, 0.05);
}
.catalogItem .imageContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	overflow: hidden;
	border-radius: 50px;
	/*background-color: #EDEFF1;*/
	/*fill: #EDEFF1;*/
}
/*.catalogItem .imageContainer img:hover {

}*/

.catalogItem .imageContainer img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 2;
}
.catalogItem p {
	padding-top: 24px;
	text-align: center;
}

.catalogItem a p {
	text-decoration: none;
	color: #727272;
}

.catalogItem:hover a p {
	color: #3d3d3d;
}

/*.catalogItem:hover .imageContainer svg {
    fill: rgba(0, 0, 0, 0.05);
}*/

.imageContainer img {
	/*background: rgba(0, 0, 0, 0.4);*/
	/*opacity: 0.9*/
}

.boardContainerRows2 {
	display: flex;
	gap: 50px;
	flex-wrap: wrap;
}

.mainCategoryMobileWrap {
	display: flex;
	flex-wrap: wrap;
	padding-top: 11px;
}

.hiddenCategory {
	display: flex;
	flex-wrap: wrap;
	max-height: 225px;
	margin-bottom: 20px;
	overflow: hidden;
}

.mainCategoryMobileBlock {
	width: 25%;
}

.mainCategoryMobileItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 0px;
}

.mainCategoryMobileImageBlock {
	width: 61px;
	height: 61px;
}

.mainCategoryMobileImage {
	width: 100%;
	height: 100%;
	border-radius: 68px;
}

.mainCategoryMobileText {
	text-align: center;
	color: #000;
	text-align: center;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 15px;
	padding: 0px 5px;
}

.mainCategoryMobileTopBlock {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ededed;
	height: 51px;
}

.mainCategoryMobileTopTitle {
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-transform: capitalize;
	margin-left: 4px;
}

.mainCategoryMobileTopSwitcherBlock {
	display: flex;
	align-items: center;
	gap: 5px;
	height: 40px;
	width: 84px;
}

.mainCategoryMobileTopSwitcherBlock p {
	color: #1754a6;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.switcher_active svg {
	rotate: 180deg;
}

@media (max-width: 2200px) {
	.popularSectionContainer h5 {
		padding: 34px 0 0 0;
		color: #000000;
	}

	.boardContainerRow {
		padding: 34px 0 40px 20px;
		gap: 49px;
		/* column-gap: 4%;*/
		flex-wrap: wrap;
	}

	.catalogItem {
		max-width: 90px;
	}

	.catalogItem .imageContainer {
		width: 90px;
		height: 90px;
	}
}

@media (max-width: 1025px) {
	.swiperContainer {
		/*padding-top: 23px;*/
		/*height: 258px;*/
		height: 270px;
	}
	.swiperContainer .swiperSlide:nth-child(-n + 4) {
		padding-left: 15px;
	}
	.swiperSlide {
		height: 75px;
	}
	.swiperSlide .catalogItem a {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
	.catalogItem .imageContainer {
		width: 55px;
		height: 55px;
	}
	.catalogItem p {
		padding-top: 10px;
		font-family: var(--myMainFontRegular);
		font-size: 10px;
		color: #000000;
	}
}
@media (max-width: 1025px) {
	.popularSectionWrapper {
		padding: 0 12px;
	}
}
