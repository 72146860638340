.topTitleBlock {
	display: flex;
	width: 100%;
	height: 45px;
	padding: 18px 0 8px 15px;
	background-color: #f2f2f2;
	cursor: pointer;
}

.topTitleBlockMobile {
	position: relative;
	margin-top: 5px;
	display: flex;
	width: 100%;
	/*height: 51px;*/
	/* padding: 18px 0 14px 15px;*/
	background-color: #fff;
	cursor: pointer;
	border-bottom: 1px solid #ededed;
	align-items: center;
}
.topTitleBlockMobileHiddenBorder {
	display: flex;
	width: 100%;
	/*height: 45px;*/
	padding: 18px 0 14px 15px;
	background-color: #fff;
	cursor: pointer;
}
.topTitleBlockArrow {
	margin-right: 15px;
	margin-left: 20px;
	height: 40px;
	display: flex;
	align-items: center;
}
.topTitleBlockTitle {
	/*margin-top: 6px;*/
	font-family: var(--myMainFontBold) sans-serif;
	font-size: 16px;
	line-height: 16px;
	color: #2b2a38;
	height: 40px;
	display: flex;
	align-items: center;
}

@media (max-width: 450px) {
	.topTitleBlockTitleCut p {
		width: 210px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
