.horizontalCardBlock {
	display: flex;
	margin: 2px 0px;
	max-height: 130px;
	height: 131px;
	gap: 10px;
	border-radius: 12px;
	background: #fff;
}
.horizontalCardBlock.row {
	width: 100%;
}
.horizontalCardBlock.column {
	width: calc(50% - 2px);
}

.horizontalCardBlock.work {
	height: auto;
	max-height: none;
	padding: 15px 13px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	font-size: 12px;
	line-height: 14px;
}
.workImage {
	height: 95px;
	width: 100%;
}
.workImage > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.horizontalCardImageBlock {
	max-width: 140px;
	width: 100%;
}

.horizontalCardImageBlock img {
	width: 100%;
	height: 100%;
	border-radius: 8px 0px 0px 8px;
	object-fit: cover;
}
.horizontalWorkCardContent {
	margin-top: auto;
	display: flex;
	flex-direction: column;
	gap: 12px;
}
.horizontalCardContentBlock {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0px 15px 13px 13px;
}

.horizontalCardContenTitle {
	padding: 7px 0px 30px 0px;
}

.horizontalCardContenTitle p {
	color: #3d3d3d;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	font-size: 11px;
}

.horizontalWorkCardTitleWrap {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	width: 100%;
	padding-bottom: 12px;
}
.horizontalWorkCardTitleWrap p {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: break-word;
}


.horizontalWorkCardTitleWrap svg {
	fill: #ffd74a;
}

.horizontalCardContenPrice {
	/* padding-bottom: 10px; */
}

.horizontalCardContenPrice span {
	color: #262424;
	font-style: normal;
	font-weight: 700;
	line-height: 28px;
	font-size: 11px;
}

.horizontalCardContenPlace {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.horizontalCardContenPlace svg {
	fill: #ffd74a;
}

.horizontalCardContenPlace p {
	color: #898c8f;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	font-size: 10px;
}

.horizontalCardContenPlaceText {
	color: #898c8f;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	font-size: 10px;
}

.horizontalCardContenPlaceForSerach {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}
.horizontalCardContenPlaceForSerach svg {
	fill: #ffd74a;
}

/* Button Listing Add */
.addAD {
	display: flex;
	flex-direction: column;
	gap: 45px;
}

.addAD h2 {
	padding-top: 8px;
	font-size: 16px;
	line-height: 130%;
	color: #3d3d3d;
}

.addAD button {
	display: flex;
	margin-right: 18px;
	width: 100%;
	background-color: #53e0ff;
	min-height: 43px;
	border-radius: 8px;
	color: #191848;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	cursor: pointer;
}

.addAD button:hover {
	background-color: #97eafe;
}

.addAD button h3 {
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	text-transform: uppercase;
}

@media screen and (max-width: 576px) {
	.addAD h2 {
		font-size: 14px;
	}
}

@media screen and (max-width: 450px) {
	.addAD {
		gap: 25px;
	}
}
