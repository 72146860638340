.favoriteMobileProductContainer {
	margin: 0px 12px 80px 12px;
}
.cardsContainerRow {
	/* padding: 0 15px; */
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.cardsContainerColumn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 25px;
	padding-right: 2px;
	flex-wrap: wrap;
	gap: 10px 0px;
}
