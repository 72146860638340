.title {
	margin: 18px 0;
}
.closeCross {
	width: 100%;
	text-align: right;
}
.closeCross svg {
	cursor: pointer;
}
.phoneStyle {
	color: #262424;
}

.timerContainer {
	margin: 24px 0 8px;
	width: 100%;
	text-align: right;
	color: #727272;
	font-size: 12px;
}
.timerContainer .timerButton {
	min-width: 100%;
	height: initial;
	text-align: right;
	color: #727272;
	font-size: 12px;
	text-decoration-line: underline;
	border: none;
	text-transform: none;
}
.enterEmailInput input {
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 40px;
	border: 1px solid #d3d3d3;
	border-radius: 5px;
	padding-left: 15px;
	caret-color: initial;
}

.enterEmailInput.error input {
	border-bottom-color: red;
}
.timerInApprove {
	margin-top: 0;
}
