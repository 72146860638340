.fullscreenGalleryWrapper {
	width: 100%;
	min-height: 100vh;
	background-color: #2a2a2b;
	caret-color: transparent;
}
.fullscreenGalleryContainer {
	max-width: 1506px;
	margin: 0 auto;
}
.topPanel {
	display: flex;
	justify-content: space-between;
}
.leftSide {
	display: flex;
	gap: 15px;
}
.topPanel .leftSide svg {
	fill: none;
	stroke: white;
}
.topPanel .leftSide:hover svg {
	fill: none;
	stroke: rgba(255, 255, 255, 0.55);
}
.topPanel .leftSide:hover h1 {
	fill: none;
	stroke: rgba(255, 255, 255, 0.55);
	color: rgba(255, 255, 255, 0.55);
}
.topPanel {
	margin-bottom: 20px;
}
.topPanel h1 {
	margin-top: 34px;
	font-family: var(--myMainFontRegular);
	font-size: 21px;
	line-height: 21px;
	color: #ffffff;
	cursor: pointer;
}
.topPanel h1:hover {
	color: rgba(255, 255, 255, 0.55);
}
.topPanel span svg {
	margin-top: 34px;
	fill: #ffffff;
	cursor: pointer;
}
.topPanel span svg:hover {
	fill: rgba(255, 255, 255, 0.55);
}
/*.content {
    display: flex;
    gap: 42px;
}*/
/*.content {
    display: flex;
    justify-content: center;
    width: 1400px;
    height: 773px;
}*/
.gallery {
	/*width:  960px;*/
}
.contacts {
	display: flex;
	flex-direction: column;
}
.contacts span {
	font-family: var(--myMainFontBold);
	font-size: 18px;
	line-height: 120%;
	color: #ffffff;
}
.contacts .sellerName:hover {
	color: rgba(255, 255, 255, 0.51);
}
.contacts .green {
	display: inline-block;
	margin-right: 7px;
	width: 10px;
	height: 10px;
	background: #34e045;
	border-radius: 25px;
}
.buttons {
	display: flex;
	margin-top: 21px;
	flex-direction: column;
	gap: 29px;
}
.buttons button {
	width: 354px;
	height: 50px;
	font-family: var(--myMainFontBold);
	font-size: 16px;
	line-height: 120%;
	color: #e7e7e7;
	border: 1px solid #e7e7e7;
	border-radius: 4px;
}
.buttons button:hover {
	color: #ffffff;
	/*border:1px solid rgba(231, 231, 231, 0.56);*/
	border: 1px solid #ffffff;
}
.advertising {
	margin-top: 30px;
	width: 302px;
	height: 250px;
	background: #c4c4c4;
}
