.searchResultMobileContainer {
	margin-bottom: 149px;
}
.searchResultMobileProductContainer {
	margin: 0 12px;
}
.topMenu {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	padding: 10px 11px 15px 11px;
	background: #ffffff;
}
.topBackBlock {
	width: 100%;
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	padding: 10px 11px 15px 11px;
	background: #ffffff;
}
.topBackBlock h3 {
	padding-left: 40px;
	font-size: 18px;
	font-family: var(--myMainFontBold) sans-serif "open-sans";
}
.clearAllButton {
	color: #00cefc;
}
.cardsContainerColumn {
	display: flex;
	justify-content: space-between;
	/* align-items: center; */
	padding-bottom: 25px;
	padding-right: 2px;
	flex-wrap: wrap;
	gap: 0 4px;
}
.topMenuElement {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 12px 10px 13px 15px;
	border: 1px solid #e7e7e7;
	border-radius: 4px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 10px;
	line-height: 10px;
	/* identical to box height */
	text-transform: uppercase;
	color: #727272;
	cursor: pointer;
}
.searchSliderContainer {
	padding: 21px 0 31px 11px;
	height: 100%;
}
.searchResultMobileAdvertising {
	margin: 0 auto;
	width: 338px;
	height: 125px;
	background: #cdcdcd;
	border-radius: 5px;
}
.filterAndSortPanelWrapper {
	margin-top: 34px;
	margin-bottom: 21px;
}
.cardsContainerRow {
	/* padding: 0 15px; */
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.cardsContainerVertical {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	/* padding: 0 15px; */
	/*justify-content: space-between;*/
	column-gap: 20px;
	row-gap: 15px;
}

.searchResultBannerContainer {
	display: flex;
	width: 100%;
	justify-content: center;
	margin-top: 5px;
}

.expandButtonWrapper {
	margin-top: 50px;
	display: flex;
	justify-content: center;
}

.topMenuArrowContainer {
	position: absolute;
	right: 5px;
	top: 100px;
	display: flex;
	justify-content: flex-end;
	padding: 10px 11px 15px 11px;
	background: white;
}

.topMenuArrowContainer svg {
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.arrowSvgActive svg {
	rotate: -180deg;
}
.filterPriceContainer {
	display: flex;
	justify-content: center;
	margin: 8px 0;
}
@media (min-width: 390px) and (max-width: 1024px) {
	.cardsContainerVertical {
		column-gap: 34px;
	}
}
