.notification {
	color: #3d3d3d;
	padding: 16px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 4px;
	z-index: 1000;
	cursor: pointer;
}

.success {
	background-color: #c2ebca;
}

.error {
	background-color: #fddddd;
}

@media (min-width: 768px) {
	.notification {
		top: 40px;
		right: 20px;
		bottom: auto;
		left: auto;
		max-width: 300px;
		width: auto;
	}
}
