/*=========Personal card start ========*/

.personalInfo {
	width: 354px;
	height: 70px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	padding: 20px 10px 20px 18px;
	background: #ebebeb;
	border-radius: 5px;
	cursor: pointer;
}
.personalInfo:hover {
	background: rgb(238, 238, 238);
}
.personalInfo_left {
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;
}
.personalInfo_info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 4px;
	font-family: var(--myMainFontRegular) open-sans sans-serif;
	font-size: 10px;
	line-height: 10px;
	color: #3d3d3d;
	cursor: pointer;
}
.personalInfo_info span:first-child {
	font-size: 16px;
	line-height: 16px;
}
.personalInfo_right {
	cursor: pointer;
}
.imageContainer {
	width: 30px;
	height: 30px;
	overflow: hidden;
	border-radius: 50%;
}
.imageContainer img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/*=========Personal card end ========*/

/*=========Nav Bar start ========*/
.navBarContainer {
	width: 354px;
	background: #f2f2f2;
	caret-color: transparent;
}
.navMenu {
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.navMenuItem a {
	width: 352px;
	height: 50px;
	display: flex;
	justify-content: space-between;
	padding: 0 28px 0 18px;
	align-items: center;
	border-radius: 5px;
}
.navMenuItemLeft {
	display: flex;
	align-items: center;
	gap: 19px;
}
.navMenu .navMenuItem {
	font-family: var(--myMainFontRegular) open-sans sans-serif;
	font-size: 16px;
	line-height: 16px;
}
.navMenu .navMenuItem a {
	color: #3d3d3d;
}

.linkBusinessProfile {
	height: auto !important;
}
/*.navMenu .navMenuItem:nth-child(2)  {
    padding-bottom: 10px;
}*/
.navMenu .navMenuItem:nth-child(1) {
	padding-bottom: 14px;
	border-bottom: 1px solid #dcdcdc;
}

.navMenu .navMenuItem:nth-child(2) {
	margin-top: 12px;
}

.navMenu .navMenuItem:nth-child(6) {
	margin-top: 10px;
	padding-top: 12px;
	border-top: 1px solid #dcdcdc;
}

.itemData {
	width: 20px;
	height: 20px;
	background: #ffd74a;
	text-align: center;
	border-radius: 15px;
	padding-top: 5px;
	font-size: 12px;
	line-height: 12px;
}
.itemDataBalance {
	font-size: 14px;
}
.green {
	background: #93c47d;
}
.adsAmount {
	color: #3d3d3d;
	font-size: 14px;
	background-color: transparent;
}

.adsWrap {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 50px;
	overflow: hidden;
}

.adsblock {
	width: 300px;
	height: 250px;
}

.navMenuItem .businessPage {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.navMenuItem .businessPage {
	height: auto;
}

.navMenuItem .status {
	width: 100%;
	font-size: 12px;
	font-weight: 400;
	font-family: var(--myMainFontRegular) open-sans sans-serif;
	padding: 0 28px 0 70px;
}

.active {
	color: #00a9ce;
}

.pending {
	color: #00a9ce;
}

.rejected {
	color: #ff5b00;
}

.inactive {
	color: gray;
}

.default {
	color: black;
}

/*=========Nav Bar end ========*/
