.filterAndSortPanelContainer {
	display: flex;
	justify-content: space-between;
	/*background:#FFFFFF;*/
	padding: 0 16px;
}
.filterButton_Button {
	width: 76px;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	/*padding: 5px 6px 5px 4px;*/
	background: #ffffff;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
	border-radius: 5px;
}
.filterButton_Button span {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 10px;
	line-height: 13px;
	/* identical to box height */
	color: #727272;
}
.rubricsSelect {
}
.sortRowSettings {
	display: flex;
	gap: 9px;
}
.sortingArrow {
	position: relative;
}
.sortingPopupContainer {
	position: absolute;
	padding: 10px 25px 10px 18px;
	width: 155px;
	/* height: 155px; */
	height: auto;
	right: 0;
	top: 37px;
	background: #ffffff;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	z-index: 10;
}
.popupMenuElement {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 12px;
	line-height: 232.5%;
	color: #3d3d3d;
}
