.searchCardContainer {
	position: relative;
	width: 230px;
	height: 355px;
	padding: 10px;
	background: #ffffff;
	cursor: pointer;
	font-family: var(--myMainFontRegular);
	z-index: 1;
}
.searchCardContainer.work {
	display: flex;
	flex-direction: column;
}
.cardImage {
	/*width: 206px;
    height: 206px;*/
	text-align: center;
	overflow: hidden;
}
.cardImage img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.workCardImage {
	height: 180px;
	width: 100%;
}
.workCardImage > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.topFlag {
	position: absolute;
	top: 50px;
	left: 10px;
}
.cardTitle {
	padding-top: 8px;
	display: flex;
	justify-content: space-between;
	font-family: var(--myMainFontRegular);
	font-size: 16px;
	line-height: 130%;
	color: #000000;
}
.cardTitle.work {
	padding: 6px 10px;
	margin-top: 8px;
	margin-bottom: 12px;
}
.cardTitle h4 {
	height: 24px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.cardTitle h4:hover {
	text-decoration: underline;
	color: #171717;
}
.searchCardContainer {
	position: relative;
}
.searchCardContainer:hover {
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
	z-index: 2;
	/*transform: matrix(1.004, 0, 0, 1.004, 0, 0);*/
}

.searchCardContainer::before {
	display: none;
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: matrix(1.006, 0, 0, 1.006, 0, 0);
	z-index: -1;
	background-color: #ffffff;
	/*box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.20);*/
}
.searchCardContainer:hover::before {
	display: block;
}
.searchCardContainer:hover .cardTitle svg {
	fill: #ffd74a;
}
.cardTime span {
	color: #939393;
}
.searchCardContainer:hover .cardTime span {
	color: #000000;
}
.cardTitle svg {
	fill: #c8c8c8;
}
.cardPrice {
	padding-top: 20px;
	font-size: 18px;
	line-height: 130%;
	color: #000000;
}

.cardLocation {
	height: 25px;
	padding-top: 4px;
	font-size: 16px;
	line-height: 130%;
	color: #939393;
	overflow: hidden;
}
.hiddenInfo svg {
	stroke: #cecece;
}

.hiddenInfo svg:hover {
	stroke: rgba(140, 140, 140, 0.7);
}
.hiddenInfo svg:hover .hiddenPopup.popup {
	display: flex;
	flex-direction: column;
}
.menuButton:hover .hiddenPopup.popup {
	display: flex;
	flex-direction: column;
}
.searchCardContainer:hover + .panelBottom {
	display: block;
	cursor: pointer;
}
.hiddenInfo {
	display: none;
}
.searchCardContainer:hover .hiddenInfo {
	display: block;
}
.searchCardContainer:hover .cardTime {
	display: flex;
	justify-content: space-between;
	position: relative;
}
.hiddenPopup.popup {
	position: absolute;
	top: 25px;
	right: -2.5vh;
	width: 299px;
	height: 171px;
	background: #000000;
	opacity: 0.75;
	border-radius: 5px;
	color: #ffffff;
	z-index: 50;
	padding: 12px 13px;
}
.popup {
	display: none;
}
.hiddenInfo:hover .popup {
	display: block;
	cursor: pointer;
}

/* Button Listing Add */
.addAD {
	display: flex;
	flex-direction: column;
	gap: 35px;
}

.addAD h2 {
	padding-top: 8px;
	font-size: 16px;
	line-height: 130%;
	color: #3d3d3d;
}

.addAD button {
	display: flex;
	margin-right: 18px;
	width: 100%;
	background-color: #53e0ff;
	min-height: 43px;
	border-radius: 8px;
	color: #191848;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	cursor: pointer;
}

.addAD button:hover {
	background-color: #97eafe;
}

.addAD button h3 {
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	text-transform: uppercase;
}

.cardWorkContent {
	margin-top: auto;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 18px;
	color: #777777;
	padding: 0 9px;
	display: flex;
	flex-direction: column;
	gap: 12px;
}
.cardWorkContent img {
	object-fit: cover;
	max-width: 100%;
	max-height: 120px;
	align-self: center;
	
}
.cardWorkContent svg {
	max-width: 14px;
}
.cardWorkInfo {
	display: flex;
	flex-direction: column;
	gap: 12px;
}