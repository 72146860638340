.homeSeoContainer {
	width: 100%;
	padding: 15px 15px 40px 15px;
	position: relative;
	z-index: 1;
	caret-color: transparent;
	font-size: 18px;
}

.contentWrapper {
	position: relative;
}

.seoContent {
	font-family: var(--myMainFontLight);
	line-height: 18px;
	color: #444444;
	transition: max-height 0.3s ease-out;
}

.collapsed {
	overflow: hidden;
}
.fadeOverlay {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100px;
	background: linear-gradient(
		to bottom,
		rgba(250, 250, 250, 0) 0%,
		rgba(250, 250, 250, 0.3) 20%,
		rgba(250, 250, 250, 0.5) 40%,
		rgba(250, 250, 250, 0.7) 60%,
		rgba(250, 250, 250, 0.85) 80%,
		rgba(250, 250, 250, 0.95) 90%,
		rgba(250, 250, 250, 0.98) 100%
	);
	pointer-events: none;
}

.seoContent h3 {
	padding-bottom: 15px;
}

.seoBottomPanel {
	font-family: var(--myMainFontRegular);
	line-height: 18px;
	padding-top: 15px;
	color: #00a9ce;
	cursor: pointer;
}

.seoContent h1 {
	text-align: center;
	font-size: 30px;
	padding: 8px 0;
	font-family: var(--myMainFontBold);
	text-align: center;
}
.seoContent h2 {
	font-size: 26px;
	line-height: 1.2;
	padding: 6px 0;
	font-family: var(--myMainFontBold);
}
.seoContent h3 {
	font-size: 22px;
	padding: 4px 0;
	font-family: var(--myMainFontRegular);
}

.seoContent a {
	color: #009ccd;
	text-decoration: none !important;
	font-style: normal;
	font-weight: 600;
	padding-left: 0px;
}

.seoContent p {
	text-indent: 80px;
	line-height: 30px;
	padding: 10px 0px;
}

.seoContentSecond {
	font-family: var(--myMainFontLight);
	line-height: 18px;
	/* color: #C4C4C4; */
	overflow: hidden;
	color: #444444;
}

.seoContentSecond p {
	text-indent: 80px;
	line-height: 30px;
	padding: 10px 0px;
}

.seoContentSecond h1 {
	text-align: center;
	font-size: 30px;
	padding: 8px 0;
	font-family: var(--myMainFontBold);
	text-align: center;
}
.seoContentSecond h2 {
	font-size: 26px;
	padding: 6px 0;
	font-family: var(--myMainFontBold);
}
.seoContentSecond h3 {
	font-size: 22px;
	padding: 4px 0;
	font-family: var(--myMainFontRegular);
}

.seoContentSecond a {
	color: #009ccd;
	text-decoration: none !important;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
}

.seoContentSecond ol,
.seoContent ol {
	padding: 10px 0px 10px 80px;
}

.seoContentSecond ul,
.seoContent ul {
	padding: 10px 0px 10px 80px;
}

.seoContentSecond ul li,
.seoContent ul li {
	line-height: 26px;
	list-style: disc !important;
}

.seoContentSecond ol li,
.seoContent ol li {
	line-height: 26px;
	list-style: decimal !important;
}

.searchValueText {
	font-weight: 600;
}

.underlineText {
	text-decoration: underline #009ccd;
}

@media (max-width: 1025px) {
	.homeSeoContainer {
		padding: 15px 12px 40px 12px !important;
		font-size: 16px;
	}
	.seoContent h2 {
		font-size: 20px;
		line-height: 1.1;
	}
}
