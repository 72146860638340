.personalCabinetWrapper {
	width: 100%;

	background-color: #f2f2f2;
}

.personalCabinetContainer {
	max-width: 1506px;
	margin: 0 auto;
	display: flex;
	gap: 30px;
	padding-top: 38px;
	padding-bottom: 40px;
	background: #f2f2f2;
	caret-color: transparent;
	min-height: 830px;
}
.cabinetContent {
	width: 100%;
	border-radius: 10px;
}
.adverts {
	margin: 43px auto;
	width: 300px;
	height: 250px;
}
.paginatorContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 50px;
	align-items: center;
}
/*==========Content Top start======*/
.cabinetContent .cabinetContent_Top {
	padding: 26px 0 30px 24px;
	background: #ffffff;
}
.cabinetContent_Top h4 {
	font-family: var(--myMainFontRegular);
	font-size: 18px;
	line-height: 18px;
	text-transform: uppercase;
	color: #3d3d3d;
}
.cabinetContent_Top_LinkPanel {
	display: flex;
	gap: 20px;
	overflow: hidden;
	max-width: 100%;
}
.linkPanel_element a {
	display: flex;
	justify-content: space-around;
	max-width: 100%;
	transition: transform 0.3s ease;
	align-items: center;
	margin-top: 22px;
	padding: 7px 15px;
	border: 1px solid #e7e7e7;
	border-radius: 5px;
	color: #3d3d3d;
	background: #f2f2f2;
	font-family: var(--myMainFontRegular);
	font-size: 18px;
}
/*.linkPanel_element {
  min-width: 20%;
   !* min-width: 10rem;*!
}*/
.linkPanel_element a:hover {
	color: #f2f2f2;
	background: #2b2a38;
}
.elementData {
	font-size: 14px;
}
.caretElement {
	margin: 0 15px;
	width: 1px;
	height: 25px;
	background-color: #d9d9d9;
}
.slideMobile {
	margin-right: 33px;
}
/*==========Content Top end========*/

/*==========Content CheckBox start======*/
.checkBoxContainer {
	max-width: 100%;
}
.cabinetContent_CheckBoxPanel {
	padding-left: 24px;
	padding-right: 10px;
	height: 60px;
	background: #f8f8f8;
	display: flex;
	align-items: center;
	gap: 34px;
	max-width: 100%; /*05.04.2024*/
	white-space: nowrap;
	/*overflow-y: hidden;*/
}
.checkboxEl {
	max-width: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.checkboxInput {
	/*min-width: 20px;*/
	opacity: 0;
	position: absolute;
	z-index: -1;
}
.checkboxInput + label {
	display: flex;
	user-select: none;
	align-items: center;
}
.checkboxInput + label::before {
	content: "";
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 20px;
	height: 20px;
	border: 1px solid #727272;
	border-radius: 3px;
	margin-right: 10px;
	cursor: pointer;
	background-color: #ffffff;
}
.checkboxInput:checked + label::before {
	content: url("/src/images/checkBox.jpg");
	border: 1px solid #00a9ce;
}
.labelText {
	color: #2b2a38;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
/*==========Content CheckBox end========*/

/*==========Content CardTemplate start======*/
.cabinetContent_CardTemplate {
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding: 30px 24px;
	background: #ffffff;
	min-height: 100vh;
	min-width: 100%;
}

/*==========Content CardTemplate end========*/
@media (max-width: 1540px) {
	.personalCabinetContainer {
		margin: 0 auto;
		display: flex;
		gap: 20px;
		padding: 38px 0;
		background: #f2f2f2;
		caret-color: transparent;
		/* min-height: 830px;*/
	}
}
@media (max-width: 1025px) {
	.cabinetMobileSwiper div div {
		max-width: 150px;
	}

	.linkPanel_element a {
		white-space: nowrap;
		justify-content: space-around;
		font-size: 14px;
		margin-top: 5px;
		height: 40px;
		padding: 7px 8px;
	}
	.cabinetContent_Top_LinkPanel {
		/*gap: 0;*/
	}
	.caretElement {
		white-space: nowrap;
		margin: 0 5px;
	}
}
