.noOrdersCardContainer {
	/*padding: 25px 38px 30px 7px;*/
	padding: 25px 10px 30px 7px;
	background: #ffffff;
	border-radius: 3px;
}
.noOrdersCardContainer h4 {
	padding-bottom: 30px;
	text-align: center;
	font-family: var(--myMainFontBold);
	font-size: 16px;
	line-height: 130%;
	color: #2b2a38;
}
.menu {
	display: flex;
	flex-direction: column;
	row-gap: 30px;
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	line-height: 130%;
	color: #2b2a38;
}
.menuItem:first-child {
	margin-left: 32px;
}
.menuItem {
	display: flex;
	gap: 12px;
}
.menuItem a {
	color: #2b2a38;
}
.verifiedSeller {
	display: flex;
	align-items: center;
	margin-top: 4px;
	gap: 7px;
	font-family: var(--myMainFontBold);
	color: #2b2a38;
}
