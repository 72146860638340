.messengerIcons {
    display: flex;
    column-gap: 15px;
    /*cursor: pointer;*/
    align-items: center;
}
.cabinetIconsContainer {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
    column-gap: 22px;
}
.messengerContainer {
    display: flex;
    align-items: center;
cursor: pointer;
}
.messengerName {
    padding-left: 22px;
    color: #727272;
}
.messengerName.active {
    color: #202020;
}
.messengerIcons span {
    /*height: 40px;*/
}
.messengerIcons svg {
    fill: #bdbdbd;
    /*height: 35px;*/
    /*fill:#B5B5B5;*/
}

@media (max-width: 1024px) {
    .messengerIcons span {
        cursor: pointer;
    }
    .messengerIcons {
        padding-bottom: 30px;
        width: 100%;
        justify-content: flex-start;
        align-items: initial;
        flex-wrap: wrap;
        /*margin-bottom: 17px;*/
    }
}