.uiInputContainer {
	position: relative;
	width: 200px;
}

.uiInput {
	width: 100%;
	padding: 10px 10px 10px 35px;
	font-size: 12px;
	border: none;
	border-bottom: 1px solid #b5b5b5;
	outline: none;
	background-color: transparent;
	transition: border-color 0.3s;
	color: #fff;
    caret-color: transparent;
}

.uiInput:focus {
	border-color: #b1b1b1;
}

.uiInput::placeholder {
	color: #b5b5b5;
}

.uiInputUnderline {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 2px;
	width: 100%;
	background-color: #b1b1b1;
	transform: scaleX(0);
	transition: transform 0.3s;
}

.uiInput:focus + .uiInputUnderline {
	transform: scaleX(1);
}

.uiInputHighlight {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: rgba(186, 186, 186, 0.1);
	transition: width 0.3s;
}

.uiInput:focus ~ .uiInputHighlight {
	width: 100%;
}

.uiInputIcon {
	position: absolute;
	left: 10px;
	top: 55%;
	transform: translateY(-50%);
	color: #999;
	transition: color 0.3s;
}

.uiInput:focus ~ .uiInputIcon {
	color: #ffffff;
}

.uiInput:focus::placeholder {
	color: #fff;
}

.uiInputIcon svg {
	width: 15px;
	height: 15px;
}
