.container {
	width: 100%;
	display: flex;
	justify-content: center;
	min-height: 100vh;
}

.elementCompact {
	width: 150px;
}
.elementBig {
	width: 630px;
}
.elementMedium {
	width: 369px;
}
