.wrapper {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #00000026;
	z-index: 5;
}
.tintContainer {
	width: 683px;
	height: 568px;
	background-color: #ffffff;
	padding: 13px 25px;
	border-radius: 5px;
}
.tintContainer .closeCrossContainer {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
.tintContainer .closeCross {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tintContainer .closeCross svg {
	fill: #727272;
	cursor: pointer;
}
.tintContainer .closeCross:hover svg {
	fill: #2b2a38;
}

.textElement {
	position: relative;
	font-size: 16px;
	padding-bottom: 25px;
	text-align: justify;
}
b {
	position: relative;
	z-index: 2;
}
.geniusAddKaliaky {
	position: absolute;
	top: -10px;
	left: -10px;
	z-index: 1;
}
.descriptionText {
	line-height: 26px;
}
@media (max-width: 500px) {
	.wrapper {
		z-index: 51;
	}
	.tintContainer {
		height: 90vh;
		overflow-x: auto;
		width: 436px;
	}
	.descriptionText {
		position: relative;
		z-index: 2;
		font-size: 14px;
		line-height: 14px;
	}
	.textElement {
		font-size: 14px;
		padding-bottom: 20px;
	}
}

@media (max-width: 450px) {
	.tintContainer {
		width: 390px;
	}
}

@media (max-width: 390px) {
	.tintContainer {
		width: 350px;
	}
}
