.wrapper {
	width: 100%;
	background-color: #fff;
}

.createADContainer {
	padding: 0 12px;
}

.titleWrap {
	display: flex;
	gap: 10px;
	align-items: center;
	padding: 20px 0;
}

.titleWrap h1 {
	color: #3d3d3d;
	font-family: var(--myMainFontBold) sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.required {
	color: red;
	vertical-align: center;
}

.formElement {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 8px;
	width: 100%;
	color: #2b2a38;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 16px;
}

.descriptionField .fieldSide {
	width: 100%;
	margin-top: 0;
	/* max-width: 937px; */
}

.descriptionField .fieldSide textarea {
	max-width: 100%;
	width: 100%;
	height: 200px;
	padding: 20px 0 0 20px;
	border-radius: 4px;
	border: 1px solid #dcdcdc;
	background: #ffffff;
	/*font-size: 16px;*/
	color: #939393;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 21px */
}

.fieldSide textarea {
	caret-color: initial;
}

.fieldSide div input {
	width: 100% !important;
}

.fieldSide {
	width: 100%;
}
.fieldSide input {
	width: 100%;
	padding-left: 26px;
	height: 40px;
	border-radius: 4px;
	border: 1px solid #e7e7e7;
	background: #ffffff;
	color: #727272;
	font-size: 16px;
}

.fieldSidePrice {
	width: 100%;
	display: flex;
}

.fieldSidePrice input {
	max-width: 156px;
	width: 100%;
	padding-left: 26px;
	height: 40px;
	border-radius: 4px;
	border: 1px solid #e7e7e7;
	background: #ffffff;
	color: #727272;
	font-size: 16px;
}

.priceSelectBlock {
	display: flex;
	align-items: center;
	gap: 10px;
	color: #727272;
	padding-left: 10px;
}

.stateButtons {
	margin-top: 20px;
	display: flex;
	gap: 32px;
}
.fieldSide button {
	width: 140px;
	/*height: 26px;*/
	height: 40px; /*https://app.clickup.com/t/86939jf1m*/
	border-radius: 4px;
	background: #f2f2f2;
	color: #3d3d3d;
	font-size: 16px;
	font-family: var(--myMainFontRegular) sans-serif;
	line-height: normal;
}
.fieldSide button.active {
	background: #00cefc;
	color: #fff;
}

.priceButtons button {
	width: 140px;
	/*height: 26px;*/
	height: 40px; /*https://app.clickup.com/t/86939jf1m*/
	border-radius: 4px;
	background: #f2f2f2;
	color: #3d3d3d;
	font-size: 16px;
	font-family: var(--myMainFontRegular) sans-serif;
	line-height: normal;
}

.priceButtons button.active {
	background: #00cefc;
	color: #fff;
}

.messengersIconsblock div {
	display: flex;
	justify-content: flex-start;
}

.otherAds {
	display: flex;
	flex-wrap: wrap;
	gap: 7px;
	font-size: 14px;
}

.otherAdsBold {
	font-weight: 600;
}

.otherAdsWrap {
	display: flex;
	gap: 12px;
	font-size: 14px;
}

.createAdCheckboxContainer {
	display: flex;
	align-items: center;
}
.createAdCheckbox {
	/*min-width: 20px;*/
	opacity: 0;
	position: absolute;
	z-index: -1;
}
.createAdCheckbox + label {
	display: flex;
	user-select: none;
	align-items: center;
}
.createAdCheckbox + label::before {
	content: "";
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 20px;
	height: 20px;
	border: 1px solid #727272;
	border-radius: 3px;
	margin-right: 10px;
	cursor: pointer;
	background-color: #ffffff;
}
.createAdCheckbox:checked + label::before {
	content: url("/src/images/checkBox.jpg");
	border: 1px solid #00a9ce;
}

.createboardFooter {
	margin-top: 50px;
	position: relative;
}

.bottomContainer {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 63px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
	background-color: #353535;
	z-index: 10;
	left: 0;
}

.buttonsBlock {
	display: flex;
	justify-content: flex-end;
	gap: 30px;
}
.bottomButton {
	text-align: center;
	width: 155px;
	height: 36px;
	border-radius: 5px;
	border: 1px solid #727272;
	text-transform: uppercase;
	color: #fff;
	font-size: 14px;
}
.bottomButton.touched {
	color: #3d3d3d;
}

.buttonActive {
	background-color: #fff;
	color: #3d3d3d;
}

/*Photo Drop Zone Mobile*/

/*.dropZoneContainer {
	width: 100%;
	max-width: 1294px;
	min-height: 112px;
	border: 1px solid #fffbef;
	background: #fff6d9;
	overflow: hidden;
	border-radius: 4px;
}

.dropZoneBlock {
	min-height: 112px;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 38px;
	padding: 20px 20px;
}

.photoSvgBlock {
	height: 70px;
	width: 70px;
	border-radius: 4px;
	background: #ffdb5c;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.svgPlus {
	position: absolute;
	color: white;
}

.dropZoneContainer input {
	min-width: 100%;
	min-height: 100%;
}

.dropZoneInfo {
	width: 100%;
	height: 155px;
	padding-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #939393;
	font-size: 18px;
	line-height: 150%; !* 27px *!
}

.checkPhotoSvgContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.checkPhotoModalSvgContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.checkPhotoModalSvgContainer span {
	color: #9d9d9d;
}

!* .checkPhotoSvgContainer span {
    color: #9D9D9D;
} *!

.checkPhotoLogoContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.checkPhotoLogoContainer p {
	color: #00a9ce;
	font-size: 12px;
}

.svgDescription {
	width: 60px;
	text-align: center;
	position: absolute;
	top: 80px;
	color: #939393;
	font-size: 14px;
	line-height: normal;
}

.photoDescription {
	margin: 20px auto;
	width: 100%;
	text-align: center;
	color: #939393;
	font-size: 16px;
	line-height: 150%; !* 27px *!
}

.photo .photoDescription {
	margin-left: 30px;
}

.dropZoneImagesContainer {
	background-color: #fff6d9;
	height: 184px;
	width: 100%;
}

.imageList {
	padding: 20px 0 5px 10px;
	display: flex;
	width: 100%;
	gap: 6px;
	overflow-x: auto;
}

.imageItem {
}

.imageItem button {
	width: 100%;
	text-align: center;
}

.imageContainer {
	width: 70px;
	height: 70px;
	overflow: hidden;
}
.imageContainer img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 8px;
}

.deleteButton {
	margin-top: 5px;
	color: #939393;
	text-align: center;
	font-size: 14px;
	line-height: 150%; !* 21px *!
}

.addNewPhotoBlock {
	display: flex;
	justify-content: center;
	padding: 30px 0 20px 0;
}

.addNewPhotoBlock {
	color: #2b2a38;
	font-size: 12px;
}

!*Choosing A Photo Upload Method Modal*!

.modalWrapper {
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 60;
}

.modalContainer {
	padding: 25px 16px 16px 15px;
	background: #ffffff;
	border-radius: 5px;
	width: 336px;
	height: 183px;
	overflow: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.modalContentWrap {
	display: flex;
	flex-direction: column;
	gap: 23px;
}

.modalContentBlock {
	display: flex;
	gap: 5px;
	padding: 0 5px;
	height: 45px;
	align-items: center;
}

.modalContentBlock:hover {
	background-color: rgb(237, 237, 237);
	border-radius: 5px;
	cursor: pointer;
}

.closeButtonWrap {
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
}

.closeButton {
	border-bottom: 1px solid #727272;
	color: #727272;
	font-size: 14px;
}*/

/*Promotion Block*/

.promotionBlockContainer {
	width: 336px;
	height: 130px;
	border-radius: 5px;
	background: #f8f8f8;
}

.promotionBlockContent {
	padding: 12px 16px 22px 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.promotionInfoBlock {
	display: flex;
	color: #727272;
}

.promotionInfoBlock p {
	font-size: 12px;
}

.promotionBottomBlock {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.promotionBottomBlock p {
	font-size: 14px;
	color: #3d3d3d;
}

.promotionBottomBlock select {
	min-width: 101px;
	min-height: 32px;
}

.filtersList {
	/* display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 23px; */
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 31px;
	width: 100%;
	color: #2b2a38;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 18px;
}

.choseCategoryBlock {
	position: relative;
}
.choseCategoryBlock svg {
	position: absolute;
	right: 15px;
	top: 15px;
}

.error {
	font-size: 14px;
	color: red;
}

/*Take A Photo Modal*/

.takeAPhotoModalWrapper {
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 6000;
}

.takeAPhotoModalContainer {
	padding: 25px 16px 16px 15px;
	background: #ffffff;
	border-radius: 5px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.takeAPhotoModalButton {
	padding: 10px 20px;
	margin: 10px 0;
	border: 1px solid #727272;
	border-radius: 8px;
	text-align: center;
	color: #727272;
}

.bottomBlock {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.bottomBlock svg {
	/* margin-left: 40px; */
	width: 50px;
	height: 50px;
}

.cameraWrap div video {
	width: 728px;
}

@media (max-width: 500px) {
	.checkPhotoLogoContainer {
		width: 336px;
		overflow: hidden;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.checkPhotoLogoContainer p {
		color: #00a9ce;
		font-size: 12px;
		font-family: var(--myMainFontMedium) sans-serif;
	}

	.svgDescription {
		width: 60px;
		text-align: center;
		position: absolute;
		top: 80px;
		color: #939393;
		font-size: 14px;
		line-height: normal;
	}
}

.takeAPhotoModalContainer {
	padding: 25px 16px 16px 15px;
	background: #ffffff;
	border-radius: 5px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.takeAPhotoModalButton {
	padding: 10px 20px;
	margin: 10px 0;
	border: 1px solid #727272;
	border-radius: 8px;
	text-align: center;
	color: #727272;
}

.bottomBlock {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.bottomBlock svg {
	/* margin-left: 40px; */
	width: 50px;
	height: 50px;
}

.cameraWrap div video {
	width: 728px;
}

.fieldSide.error input {
	border-bottom-color: red !important;
}

@media (max-width: 500px) {
	.checkPhotoLogoContainer {
		width: 336px;
		overflow: hidden;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.checkPhotoLogoContainer p {
		color: #00a9ce;
		font-size: 12px;
		font-family: var(--myMainFontMedium) sans-serif;
	}

	.svgDescription {
		width: 60px;
		text-align: center;
		position: absolute;
		top: 80px;
		color: #939393;
		font-size: 14px;
		line-height: normal;
	}
}
