.modalContainer {
	width: 634px;
	height: 374px;
	background: #ffffff;
	border-radius: 4px;
	caret-color: transparent;
}
.topCardSelectLine {
	margin: 28px 23px 16px 30px;
	display: flex;
	/*justify-content: space-between;*/
	align-items: center;
}
.topCardSelectLine_Info {
	font-family: var(--myMainFontRegular);
	font-size: 16px;
	line-height: 19px;
	color: #2b2a38;
}
.topCardSelectLine_Select {
	margin-left: 23px;
	margin-right: 217px;
}

.topCardSelectBuyInfo {
	margin: 10px 106px 32px 30px;
	font-weight: 400;
	font-size: 14px;
	line-height: 15px;
	color: #727272;
}
.topCardSelectBuyInfo p {
	color: #727272;
}

.topCardSelectPayTitle {
	padding: 15px 7px 20px;
	margin: 0;
	font-size: 16px;
	line-height: 150%;
	color: #000000;
}

.topCardSelectPrice {
	margin-left: 5px; /* Відступ ліворуч для більш гармонійного розташування ціни */
	margin-right: 5px; /* Відступ праворуч для додаткового простору між текстом */
	font-family: var(--myMainFontBold);
	font-size: 18px;
	color: #79be01;
}

@media (max-width: 1025px) {
	.modalContainer {
		width: 97%;
		height: 324px;

		/* min-height: 100%; */
	}
	.topCardSelectLine {
		padding: 12px 7px 15px;
		margin: 0;
		flex-direction: column;
		align-items: flex-start;
	}
	.topCardSelectLine_Info {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-family: var(--myMainFontRegular);
		font-size: 16px;
		line-height: 19px;
		color: #2b2a38;
	}
	.topCardSelectLine_Select {
		margin: 15px 0 0;
		width: 100%;
	}
	.topCardSelectBuyInfo {
		margin: 0 0 25px;
		padding: 0 7px;
	}
}
