.staticPageWrap {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.container {
	margin: 0 auto;
	max-width: 1240px;
	min-width: 380px;
	width: 100%;
	margin: 0 0 50px 0;
	padding: 0 20px;
}

.content {
	width: 100%;
	min-height: 800px;
	height: auto;
	font-size: 18px;
	background-color: #fff;
	border-radius: 10px;
	color: #444444;
	padding: 0 60px 50px;
	box-shadow: 5px 4px 40px 0 rgba(0, 0, 0, 0.15);
}

.content p {
	line-height: 30px;
	padding: 10px 0;
	text-indent: 80px;
	text-align: justify;
}

.content ul,
.content ol {
	padding-left: 20px;
}

.content h1 {
	font-size: 30px;
	font-weight: 400;
	padding: 50px 0 61px;
	text-align: center;
	text-align: justify;
}
.content h2 {
	font-size: 26px;
	font-weight: 400;
	padding: 6px 0;
	text-align: justify;
}
.content h3 {
	font-size: 22px;
	font-weight: 400;
	padding: 4px 0;
	text-align: justify;
}

.breadCrumbs {
	display: flex;
	margin: 20px 0;
	width: 100%;
	max-width: 1240px;
	min-width: 300px;
}
b1 {
	padding-left: 20px;
	padding-top: 10px;
	background-image: url("./b1.svg"); /* Переконайтесь, що шлях до зображення вірний */
	background-size: 100px 100px; /* Розмір зображення */
	background-repeat: no-repeat; /* Зображення не повторюється */
	background-position: left center; /* Розміщення зображення зліва та по центру по вертикалі */
}
b2 {
	padding-left: 20px;
	padding-top: 10px;
	background-image: url("./b2.svg"); /* Переконайтесь, що шлях до зображення вірний */
	background-size: 100px 100px; /* Розмір зображення */
	background-repeat: no-repeat; /* Зображення не повторюється */
	background-position: left center; /* Розміщення зображення зліва та по центру по вертикалі */
}

b3 {
	padding-left: 20px;
	padding-top: 10px;
	background-image: url("./b3.svg"); /* Переконайтесь, що шлях до зображення вірний */
	background-size: 100px 100px; /* Розмір зображення */
	background-repeat: no-repeat; /* Зображення не повторюється */
	background-position: left center; /* Розміщення зображення зліва та по центру по вертикалі */
}

b4 {
	padding-left: 20px;
	padding-top: 10px;
	background-image: url("./b4.svg"); /* Переконайтесь, що шлях до зображення вірний */
	background-size: 100px 100px; /* Розмір зображення */
	background-repeat: no-repeat; /* Зображення не повторюється */
	background-position: left center; /* Розміщення зображення зліва та по центру по вертикалі */
}

y1 {
	padding-left: 20px;
	padding-top: 10px;
	background-image: url("./y1.svg"); /* Переконайтесь, що шлях до зображення вірний */
	background-size: 100px 100px; /* Розмір зображення */
	background-repeat: no-repeat; /* Зображення не повторюється */
	background-position: left center; /* Розміщення зображення зліва та по центру по вертикалі */
}

y2 {
	padding-left: 20px;
	padding-top: 10px;
	background-image: url("./y2.svg"); /* Переконайтесь, що шлях до зображення вірний */
	background-size: 100px 100px; /* Розмір зображення */
	background-repeat: no-repeat; /* Зображення не повторюється */
	background-position: left center; /* Розміщення зображення зліва та по центру по вертикалі */
}

y3 {
	padding-left: 20px;
	padding-top: 10px;
	background-image: url("./y3.svg"); /* Переконайтесь, що шлях до зображення вірний */
	background-size: 100px 100px; /* Розмір зображення */
	background-repeat: no-repeat; /* Зображення не повторюється */
	background-position: left center; /* Розміщення зображення зліва та по центру по вертикалі */
}

y4 {
	padding-left: 20px;
	padding-top: 10px;
	background-image: url("./y4.svg"); /* Переконайтесь, що шлях до зображення вірний */
	background-size: 100px 100px; /* Розмір зображення */
	background-repeat: no-repeat; /* Зображення не повторюється */
	background-position: left center; /* Розміщення зображення зліва та по центру по вертикалі */
}
@media (max-width: 768px) {
	.content {
		font-size: 16px;
		padding: 20px;
	}

	.content p {
		text-indent: 20px;
	}

	.content h1 {
		font-size: 22px;
		padding: 20px 0;
	}
}

@media (max-width: 480px) {
	.content {
		font-size: 14px;
		padding: 15px;
	}

	.content p {
		text-indent: 15px;
		line-height: 25px;
	}

	.content h1 {
		font-size: 20px;
		padding: 15px 0;
	}

	.breadCrumbs {
		flex-direction: column;
		align-items: flex-start;
		margin: 10px 0;
	}
}
