.newAdvertsContainer {
	padding-top: 43px;
	width: 100%;
}

.bestsellersContainerTopPanel {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* margin-top:60px; */
	padding-bottom: 25px;
	padding-right: 2px;
	flex-wrap: wrap;
}

.newAdvertsContainer h5 {
	margin-left: 10px;
	padding-bottom: 15px;
	font-family: var(--myMainFontMedium);
	font-size: 22px;
	line-height: 30px;
	color: #2b2a38;
}

.newAdvertsContent {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, minmax(318px, 1fr));
	/*display: flex;
	  flex-wrap: wrap;*/
	gap: 1px;
}
@media (max-width: 1280px) {
	.newAdvertsContent {
		grid-template-columns: repeat(4, minmax(248px, 1fr));
	}
}
.newAdvertsContentMobile {
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
}
.newAdvertsBottomPanel {
	display: flex;
	justify-content: flex-end;
	padding-top: 10px;
}
.newAdvertsBottomPanel button {
	color: #1754a6;
	text-transform: capitalize;
}
.newAdvertsBottomPanel svg {
	margin-left: 10px;
}
@media (max-width: 1285px) {
	.newAdvertsContent {
		gap: 0;
	}
}
@media (max-width: 1025px) {
	.newAdvertsContainer {
		padding-top: 0;
	}
	.newAdvertsContentMobile {
		justify-content: center;
		gap: 15px;
	}
	.newAdvertsContent {
		width: 100%;
		/*padding: 0 5px;*/
		padding: 0;
		justify-content: center;
		column-gap: 20px;
		row-gap: 15px;
	}
	.newAdvertsContainer h5 {
		margin-left: 10px;
		padding-bottom: 25px;
		font-family: var(--myMainFontBold);
		font-size: 16px;
		line-height: 16px;
		color: #2b2a38;
	}
	.newAdvertsBottomPanel {
		justify-content: center;
		padding: 35px 0;
	}
}
@media (max-width: 440px) {
	.newAdvertsContentMobile {
		justify-content: center;
		gap: 15px;
	}
}
