.searchRequestContainer,
.filterContainer {
	width: 100%;
	/*padding: 10px 15px 10px 12px;*/
	padding: 15px 11px 5px 10px;
	background: #f8f8f8;
	border-radius: 5px;
	caret-color: transparent;
	overflow: hidden;
	min-width: 354px;
	max-width: 354px;
}
.filterContainer:hover {
	background: #ececec;
}
.searchRequestTitle {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
}
.searchRequestTitle h5 {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 16px;
	line-height: 130%;
	text-align: center;
	text-transform: uppercase;
	color: #3d3d3d;
	cursor: pointer;
}
.searchRequestTitle span {
	font-family: var(--myMainFontLight) sans-serif;
	font-weight: 300;
	font-size: 14px;
	line-height: 15px;
	color: #939393;
	cursor: pointer;
}
.searchRequestTitle span:hover {
	color: red;
}
.filterTitle {
	/*height: 100%;*/
	cursor: pointer;
}
.filterTitle span {
	color: #3d3d3d;
	font-size: 16px;
	font-family: var(--myMainFontBold) sans-serif;
	line-height: 15px;
}
.filterTitle span:hover {
	color: #ff3030;
}
.paramsContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}
.paramsContainer .paramsElement {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 14px;
	text-align: center;
	padding: 4px 12px;
	background: #ffe898;
	border-radius: 5px;
	color: #2e2e2e;
	cursor: pointer;
}
/*=============Filter Region =================*/
.regionFilterParams {
	padding-bottom: 19px;
}
.filterTitle {
	display: flex;
	justify-content: space-between;
	font-family: var(--myMainFontMedium) sans-serif;
	font-size: 16px;
	line-height: 130%;
	color: #3d3d3d;
	/*margin-bottom: 10px;*/
	padding-bottom: 10px;
}

/*=============Filter Price ================*/
.filterSlider {
	width: 87%;
	padding-top: 10px;
	padding-bottom: 10px;
}
.sliderPrice {
	caret-color: transparent;
}
.infoSliderPanel {
	display: flex;
	align-items: center;
	/*gap: 9px;*/
	justify-content: space-between;
	max-width: 90%;
}

.infoSliderPanel .out {
	/*add new class because change font-size*/
	width: 76px;
	height: 26px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 12px;
}

.infoSliderPanel input {
	width: 60px;
	height: 26px;
	text-align: end;
	padding-right: 5px;
	border: 1px solid #e7e7e7;
	border-radius: 5px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 12px;
	caret-color: initial;
}

.infoSliderPanel input::-webkit-outer-spin-button,
.infoSliderPanel input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.infoSliderPanel input[type="number"] {
	-moz-appearance: textfield; /* Firefox */
}
.infoSliderPanel button {
	font-family: var(--myMainFontMedium) sans-serif;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 56px;
	height: 26px;
	margin-left: 5px;
	background: #dadada;
	border-radius: 5px;
	color: #ffffff;
	text-transform: uppercase;
}
.infoSliderPanel button:hover {
	background: #e5e5e5;
}
.infoSliderPanel button.active {
	background: #00cefc;
}
.infoSliderPanel button.active:hover {
	background: rgba(0, 206, 252, 0.71);
}
/*=========Manufacturer=========*/
.filterContainerManufacturer {
	/*padding:20px 15px 11px 7px;*/
	padding: 15px;
	background: #f8f8f8;
	border-radius: 5px;
}
.filterContainerManufacturer .filterTitle {
	display: flex;
	justify-content: space-between;
}
.filterContainerManufacturer .filterTitle,
.filterContainerManufacturer .filterParams {
	padding-left: 5px;
}
.filterContainerManufacturer .filterParams input {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}
.manufacturerSearch {
	width: 100%;
	padding: 8px 25px 8px 14px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 14px;
	line-height: 14px;
	color: #727272;
	border: 1px solid #e7e7e7;
	border-radius: 5px;
}
.searchResult {
	padding-top: 10px;
	/*padding-bottom: 5px;*/
}
.resultList {
	display: flex;
	flex-wrap: wrap;
	/*gap:5px;*/
}
.resultListItem {
	min-height: 28px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 14px;
	line-height: 130%;
	color: #8d8d8d;
}
.resultListItem button {
	background: #f8f8f8;
	padding: 5px 5px;
}
.resultListItem.active button {
	background: #ffe898;
	border-radius: 3px;
	padding: 5px 5px;
	color: #3d3d3d;
}
.resultListItem button {
	color: #8d8d8d;
}
.resultListItem button:hover {
	color: #3381bd;
}
.resultListItem span {
	padding: 5px 1px;
	font-size: 11px;
}
.bottomPanel {
	display: flex;
	justify-content: flex-end;
	font-size: 12px;
	line-height: 15px;
	color: #939393;
	cursor: pointer;
}
.bottomPanel span:hover {
	color: #3381bd;
}
/*=========Filter COLOR==================*/
.colorsListBig {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 13px;
	padding: 15px 0;
}
.colorsListSmall {
	display: flex;
	flex-wrap: wrap;
	gap: 13px;
}
/*.colorsListSmall .colorsListElement {
    width: 30px;
    height: 30px;
}*/
.colorsListElement {
	width: 30px;
	height: 30px;
	border-radius: 4px;
}

.colorFilterArrow {
	margin-left: 7px;
}
.bigListElement {
	display: flex;
	align-items: center;
	cursor: pointer;
	white-space: nowrap;
	/*margin-top: 13px;*/
}
.bigListElement img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.colorExample .colorExampleImage {
	width: 30px;
	height: 30px;
}
.bigListElement span {
	margin-right: 10px;
	/*margin-top: 13px;*/
	width: 30px;
	height: 30px;
	border-radius: 4px;
}
.bigListElement .colorName {
	width: 115px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.bigListElement .colorCounter {
	padding-left: 5px;
	font-size: 12px;
}
.colorCounter {
	padding-top: 1.5%;
}
.colorExample.active {
	outline: 1px solid #000;
	border: 2px solid #fff;
	border-radius: 4px;
}
.colorExample:hover {
	outline: 1px solid #000;
	border: 2px solid #fff;
	border-radius: 4px;
}
.colorNameContainer {
	display: flex;
	white-space: nowrap;
}
.colorNameContainer:hover {
	color: #000000;
}
.colorNameContainer .colorName {
	max-width: 105px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 200;
	font-size: 14px;
	color: #727272;
}
/*=========Filter SIZE==================*/
/*.sizeList {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}*/
.sizeList {
	display: grid;
	gap: 7px;
	padding-bottom: 20px;
}

.sizeListElement {
	width: 30px;
	height: 30px;
	background: #ffffff;
	border: 1px solid #e7e7e7;
	border-radius: 4px;
	text-align: center;
	padding-top: 8px;
	color: #666666;
	cursor: pointer;
}
.sizeListElement.disabled {
	background: #ededed;
	border: 1px solid #e1e1e1;
	color: #bdbdbd;
}
.sizeListElement.active {
	background: #fff3c9;
	border: 1px solid #ffd74a;
	color: #727272;
}
/*====Search Options start === =====*/
.searchOptionsContainer {
	width: 100%;
	padding: 20px 17px 20px 16px;
	background: #f8f8f8;
	border-radius: 5px;
}
.searchOptionsTitle {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
.searchOptionsTitle h5 {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 16px;
	line-height: 16px;
	color: #000000;
}
.searchOptionsTitle svg {
	cursor: pointer;
}
.searchOptionsOptions {
	display: flex;
	gap: 5px;
	color: #6f6f6f;
}
.searchOptionsOptions li:not(:last-child) {
	padding-right: 5px;
	border-right: 1px solid #6f6f6f;
}
.searchOptionsButtons {
	margin-top: 35px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.saveSearch a {
	font-weight: 300;
	font-size: 12px;
}
.saveSearch a:hover {
	text-decoration-line: underline;
}
.resetSearch button {
	width: 130px;
	height: 32px;
	background: rgba(255, 255, 255, 0.04);
	border: 1px solid #d6d6d6;
	border-radius: 8px;
	font-family: var(--myMainFontMedium) sans-serif;
	font-size: 12px;
	color: #727272;
}
.resetSearch button:hover {
	border: 1px solid #c0c0c0;
}
/*=========Match Filter =============*/
.filterContent.matchFilter {
	max-width: 330px;
	display: flex;
	gap: 18px;
}
.dynamicChangedList {
}
.filterMatchButtonsList,
.dynamicChangedList {
	display: flex;
	flex-direction: column;
	flex: 0 0 50%;
	gap: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.filterMatchButton {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	border: 1px solid #dadada;
	border-radius: 5px;
	cursor: pointer;
}
.dynamicChangedElement {
	max-width: 130px;
	display: flex;
	align-items: center;
	height: 30px;
	color: #939393;
}
.dynamicChangedElement.active {
	color: #3d3d3d;
}
.dynamicChangedElement span {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.filterMatchButton button {
	color: #c4c4c4;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 130%;
}
.filterMatchButton.active {
	color: #ffffff;
	font-size: 14px;
	line-height: 130%; /* 18.2px */
	background-color: #00d1ff;
}
.filterMatchButton.active button {
	color: #ffffff;
}

.filerContent .sizeList {
	margin-top: 25px;
}
/*=========Match Filter End =============*/
.sizeListItem {
	display: flex;
	gap: 10px;
	align-items: center;
}
.sizeListItem .buttonCounterCheckBox {
	color: #939393;
	/*font-size: 12px;
    line-height: 20px;*/
}
.dropdownCounter {
	color: #939393;
	padding-left: 10px;
	line-height: 100%;
	/*font-size: 12px;*/
}
/*====SearchOptions end    =====*/
@media (max-width: 1700px) {
	.resultListItem {
		min-height: 30px;
		font-size: 13px;
	}
	.resultListItem.active a {
		background: #ffe898;
		border-radius: 3px;
		padding: 5px 5px 8px;
		color: #3d3d3d;
	}
}
