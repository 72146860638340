.retreat {
	margin: 0px 30px;
}

.modalWrapper {
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 60;
}

.modalContainer {
	/* padding: 25px 0px; */
	padding: 25px 0px 50px 0px;
	background: #ffffff;
	border-radius: 5px;
	width: 650px;
	/* height: 532px; */
	overflow: hidden;
	overflow-y: auto;
}

.topContainer {
	display: flex;
	justify-content: space-between;
}

.topContainer_secondWindow_wrap {
	display: flex;
	gap: 15px;
}

.topContainer_text {
	color: #383748;
	/* font-family: Open Sans; */
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	/* 21.6px */
	text-transform: uppercase;
	position: relative;
}

.topContainer_text::before {
	content: "";
	height: 2px;
	width: 100%;
	background-color: #ffd74a;
	position: absolute;
	bottom: -4px;
}

.topAttentionText {
	display: flex;
	justify-content: center;
	padding-bottom: 10px;
}

.topAttentionText p {
	color: #727272;
	/* font-family: Open Sans; */
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding-top: 10px;
}

.sellerContainer {
	width: 100%;
	height: 52px;
	flex-shrink: 0;
	border-radius: 5px 5px 0px 0px;
	background: rgba(0, 169, 206, 0.1);
	display: flex;
	align-items: center;
}

.sellerContainer_content {
	display: flex;
	gap: 10px;
	padding-left: 43px;
}

.sellerContainer_content_name {
	border-bottom: 1px solid grey;
	/* text-decoration: underline; */
}

.lastVisit_text {
	color: #747474;

	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
}

.productContainer {
	width: 100%;
	height: 52px;
	flex-shrink: 0;
	border-radius: 5px 5px 0px 0px;
	background: #f8f8f8;
	display: flex;
	/* align-items: center; */
}

.productContainer_content {
	display: flex;
	gap: 10px;
	padding-left: 43px;
	align-items: center;
}

.productContainer_content_imageWrap {
	width: 40px;
	height: 40px;
	border-radius: 60px;
	overflow: hidden;
}

.productContainer_content_image {
	width: 100%;
	height: auto;
}

.productContainer_content_name {
	color: #2b2a38;
	/* font-family: Muller; */
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
}

.productContainer_content_price {
	color: #3d3d3d;
	/* font-family: Muller; */
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.compainListWrap {
}

.compainListItemWrap {
	display: flex;
	justify-content: space-between;
	/* padding: 10px 0px; */
	height: 52px;
	border-bottom: 1px solid #e7e7e7;
	align-items: center;
}

/* .compainListItemWrap::before {
    content: "";
    height: 2px;
    width: 100%;
    background-color: #ffd74a;
    position: absolute;
    bottom: 0px;
} */

.compainListItem_text {
	color: #2b2a38;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	/* font-weight: 600; */
	line-height: normal;
	cursor: pointer;
}

.openListItemWrap {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #e7e7e7;
	justify-content: space-between;
	cursor: pointer;
	margin-left: 35px;
	padding-left: 10px;
}

.openListItem {
	color: #3d3d3d;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	height: 44px;
	display: flex;
	align-items: center;
}

.secondWindowWrap {
}

.secondWindow_mainCompain {
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	border-bottom: 1px solid #e7e7e7;
	height: 44px;
	display: flex;
	align-items: center;
}

.secondWindow_subCompain_wrap {
	color: #3d3d3d;
	margin-left: 43px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #e7e7e7;
}

.secondWindow_subCompain {
}

.secondWindow_number_symbols_wrap {
	display: flex;
	justify-content: flex-end;
}

.secondWindow_number_symbols {
	color: #939393;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.secondWindow_textarea_wrap {
	padding-top: 40px;
}

.secondWindow_textarea {
	width: 100%;
	height: 99px;
	border-radius: 4px;
	border: 1px solid #e7e7e7;
	background: var(--, #fff);
	resize: none;
	padding: 16px 0px 0px 35px;
	caret-color: currentColor;
}

.secondWindow_button_wrap {
	padding-top: 25px;
	width: 100%;
}

.secondWindow_button {
	width: 100%;
	height: 40px;
	border-radius: 5px;
	background: #fd6;

	color: #3d3d3d;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	/* 18.2px */
	text-transform: uppercase;
}

.arrowBack {
	cursor: pointer;
}

.buttonClose {
	cursor: pointer;
}

.arrowSvg svg {
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.arrowSvgActive svg {
	rotate: -180deg;
}

.ownerImage_wrap {
	width: 40px;
	height: 40px;
	border-radius: 60px;
	overflow: hidden;
}

.ownerImage {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.errorMessage {
	padding-top: 10px;
	color: red;
}
