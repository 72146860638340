.cardContainer {
	/* width: 48.5%; */
	width: calc(50% - 5px);
	display: flex;
	flex-direction: column;
	background: #fff;
	border-radius: 12px;
	margin-bottom: 10px;
	/* height: 238px; */
	max-height: 320px;
	min-height: 320px;
	position: relative;
	overflow: hidden;
}
.infoContainer {
	padding: 10px 10px 2px 10px;
}
.cardWorkContent {
	font-size: 11px;
	line-height: 14px;
	color: #777777;
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 12px 0 8px;
	margin-top: 12px;
	position: relative;
}
.cardImage {
	position: relative;
	width: 100%;
	border-radius: 8px 8px 0 0;
	overflow: hidden;
	/* height: 70%; */
	height: 163px;
}
.cardImage img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
.topMobileFlag {
	position: absolute;
	top: 26px;
}
.topHeart {
	position: absolute;
	top: 12px;
	right: 15px;
}
.cardWorkImage {
	height: 180px;
	width: 100%;
}
.cardWorkImage > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.topHeartWork button {
	display: flex;
	align-items: center;
}
.topHeart svg, .topHeartWork svg {
	fill: #ffd74a;
}
.cardName {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
}
.cardName h4 {
	line-height: 22px;
	color: #000000;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 11px;
}
.cardPrice {
	line-height: 28px;
	color: #363636;
	font-size: 11px;
	font-weight: 700;
}
.cardLocation {
	display: flex;
	width: 100%;
	justify-content: space-between;
	height: 18px;
	overflow: hidden;
}
.cardLocation span {
	line-height: 18px;
	color: #898c8f;
	display: block;
	font-size: 11px;
}

.timeAndState {
	display: flex;
	justify-content: space-between;
	color: #c4c4c4;
	font-size: 11px;
	padding-bottom: 5px;
}

/* @media(max-width: 360px) {
    .cardImage {
        height: 163px;
    }
    .сardContainer {
        max-height: 238px;
    }
} */

/* Button Listing Add */
.addAD {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.addAD h2 {
	font-size: 14px;
	line-height: 130%;
	color: #3d3d3d;
}

.addAD button {
	display: flex;
	margin-right: 18px;
	width: 100%;
	background-color: #53e0ff;
	min-height: 43px;
	border-radius: 8px;
	color: #191848;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	cursor: pointer;
}

.addAD button:hover {
	background-color: #97eafe;
}

.addAD button h3 {
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	text-transform: uppercase;
}

.fullWidth {
	width: 100%;
}
