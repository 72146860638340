.adsCardContainer {
	display: flex;
	gap: 10px;
	/*max-width: 1075px;*/
	max-width: 100%;
	height: 190px;
	padding: 19px 11px 15px 17px;
	background: #ffffff;
	box-shadow: 5px 4px 30px rgba(0, 0, 0, 0.1);
	border-radius: 15px;
}
.adsCardContainer_left {
	display: flex;
	gap: 20px;
}
.left_image {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.left_image_Image {
	width: 160px;
	height: 120px;
	position: relative;
	cursor: pointer;
	overflow: hidden;

	/*aspect-ratio: 146 / 127;*/
}

.left_image_Image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.left_image_Image svg {
	position: absolute;
	top: 8px;
	left: 0;
}
.left_image_Date {
	margin-top: 10px;
}
.left_image_Date span {
	font-family: var(--myMainFontRegular);
	font-size: 12px;
	line-height: 12px;
	color: #6c6c6c;
}
.left_main {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.left_main_Top {
	display: flex;
	width: 553px;
	padding-right: 19px;
}
.main_TopTitle {
	flex: 0 0 75%;
	overflow: hidden;

	text-overflow: ellipsis;
	padding-right: 10px;
	/*display: flex;
    align-items: center;*/
}
.left_main_Top h4 {
	max-height: 50px;
	font-family: var(--myMainFontRegular);
	font-size: 16px;
	line-height: 18px;
	color: #000000;
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
}
.left_main_Top h4:hover {
	color: #484848;
}

.main_TopTPrice {
	font-family: var(--myMainFontRegular);
	font-size: 18px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	color: #727272;
}
.priceSum input {
	text-align: right;
	padding-right: 6px;
	width: 101px;
	height: 30px;
	background: #ffffff;
	border: 1px solid #e7e7e7;
	border-radius: 4px;
	color: #727272;
	font-size: 18px;
	caret-color: #727272;
	cursor: pointer;
}
.priceSum button {
	margin-top: 6px;
	width: 142px;
	height: 25px;
	flex-shrink: 0;
	border-radius: 4px;
	border: 1px solid #0bb82e;
	background: #fff;
	color: #0bb82e;
	font-size: 12px;
	text-transform: uppercase;
}
.priceSum button:hover {
	color: #fff;
	background: #7bbc23;
}
.priceSumInput {
	display: flex;
	/*padding-bottom: 6px;*/
}
.currencyPrice {
	padding: 2px 0 0 8px;
	color: #727272;
	font-size: 18px;
}
.left_main_Middle {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-right: 22px;
}
.category {
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	line-height: 14px;
	color: #3d3d3d;
}
.autoRenewal {
	display: flex;
	align-items: center;
}
.autoRenewal_icon {
	margin-right: 24px;
}
.autoRenewal_main {
	margin-right: 24px;
	padding-bottom: 3px;
}
.autoRenewal_main span {
	color: rgba(157, 157, 157, 1);
}
.statistics {
	display: flex;
	justify-content: space-between;
	/*margin-right: 14px;*/
	/*border-bottom: 1px solid #9c9c9d;*/
	padding-bottom: 11px;
}
.statistics_statistics span {
	margin-left: 10px;
	font-family: var(--myMainFontRegular);
	font-size: 12px;
	line-height: 12px;
	color: #2b2a38;
}
.statistics_info {
	display: flex;
	gap: 30px;
}
.statistics_info svg {
	fill: #727272;
}
.statistics_info span {
	margin-left: 10px;
	font-family: var(--myMainFontRegular);
	font-size: 12px;
	line-height: 130%;
	text-align: center;
	color: #727272;
}
.left_main_Bottom_Bottom {
	border-top: 1px solid #9c9c9d;
	padding-top: 10px;
	display: flex;
	justify-content: space-between;
}
.left_main_Bottom {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
}
.main_Bottom_Position {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.main_Bottom_Position span {
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	line-height: 14px;
	color: #3d3d3d;
}
.left_main_Bottom {
	display: flex;
	justify-content: space-between;
	/*border-top: 1px solid #9c9c9d;*/
	/*padding-top: 10px;*/
	margin-right: 15px;
}
.main_Bottom_Options {
	display: flex;
	gap: 20px;
	color: #262424;
	font-family: var(--myMainFontRegular);
	font-size: 12px;
	line-height: normal;
}
.main_Bottom_Options div:not(:last-child) button {
	padding-right: 17px;
	border-right: 1px solid #939393;
}

/*.main_Bottom_Options a {
    cursor: pointer;
    color: #262424;
}*/
.errorPrice {
	font-size: 10px;
	color: red;
	max-height: 25px;
}
/*===========Right SIDE ===================*/
.adsCardContainer_Right {
	width: 100%;
	/*margin-left: 10px;*/
	padding-left: 15px;
	/*height: 152px;*/
	border-left: 1px solid #dcdcdc;
	/*width: 220px;*/
}
.adsCardContainer_Right_noActive {
	max-width: 300px;
	display: flex;
	justify-content: flex-end;
	color: #545454;
}
.adsCardContainer_Right_noActive span {
	cursor: pointer;
}
.adsCardContainer_Right_noActive span:hover {
	text-decoration-line: underline;
}
.adsCardContainer_Right_underReview {
	/*width: 310px;*/
	min-height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.adsCardContainer_Right_underReview span:first-child {
	font-family: var(--myMainFontRegular);
	font-size: 16px;
	line-height: 19px;
	color: #000000;
}
.adsCardContainer_Right_underReview span:last-child {
	font-family: var(--myMainFontRegular);
	font-size: 12px;
	line-height: 15px;
	padding-bottom: 1px;
	color: #727272;
}
.underReviewTop {
	max-width: 235px;

	display: flex;
	flex-direction: column;
	gap: 7px;
}
.underReviewTop span:last-child {
	font-size: 16px;
	line-height: 16px;
	color: #6c6c6c;
}
/*===========ACTIVE START =============*/
.adsCardContainer_Right_Active {
	min-width: 100%;
	display: flex;
	justify-content: space-between;
	/*justify-content: flex-end;*/
	/*width: 310px;*/
}
.advertiseButton {
	font-family: var(--myMainFontRegular);
	color: #545454;
	cursor: pointer;
}
.advertiseButton:hover {
	text-decoration-line: underline;
}
.right_ActiveRight {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	position: relative;
}
/*.hiddenInfo {
    display: none;
}
.advertiseButton:hover+.hiddenInfo {
    display: block;
}
.hiddenInfo:hover {
    display: block;
}*/

.hiddenInfo {
	position: absolute;
	width: 279px;
	height: 124px;
	/*padding: 15px 30px 15px 13px;*/

	top: 30px;
	right: -5px;
	background: #000000;
	opacity: 0.75;
	border-radius: 5px;
}
.hiddenInfoText {
	padding: 15px 20px 5px 13px;
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	line-height: 18px;
	color: #fafafa;
}
.hiddenInfoButton {
	margin-top: 8px;
	margin-left: 62.3%;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	text-decoration-line: underline;
	color: #ffffff;
	cursor: pointer;
}
.hiddenInfo::after {
	content: "";
	width: 11px;
	height: 6px;
	background: url("/src/images/arrowFromPersonalCabinet.svg");
	position: absolute;
	top: -6px;
	right: 65px;
}

@media (max-width: 1025px) {
	.myAdsMobileContainer {
		margin-bottom: 57px;
		background-color: #f2f2f2;
	}
	.topHead {
		background-color: #ffffff;
		padding: 15px 0 15px 15px;
	}
	.rubricsSelectContainer {
		margin: 10px 0 12px 15px;
	}
	.cardsContainer {
		margin: 0 auto;
		width: 97.5%;
		display: flex;
		flex-direction: column;
		row-gap: 10px;
	}
}
