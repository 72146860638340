.myOrdersContainer h5 {
	margin-bottom: 23px;
}

.myOrdersCardContainer {
	display: flex;
	height: 162px;
	background: #ffffff;
	box-shadow: 5px 4px 30px rgba(0, 0, 0, 0.1);
	border-radius: 15px;
}

.myOrdersCardLeft {
	flex: 1 1 423px;
	display: flex;
	gap: 20px;
}

.myOrdersCardLeft .left_image_Image {
	position: relative;
	padding: 19px 0 16px 17px;
	cursor: pointer;
}

.myOrdersCardLeft .left_image_Image svg {
	position: absolute;
	top: 26px;
	left: 17px;
}

.cardLeftInfo {
	margin: 19px 0 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.cardLeftInfo_Title {
	font-size: 16px;
	color: #000000;
	margin-bottom: 11px;
	cursor: pointer;
}
.cardLeftInfo_Title:hover {
	color: #484848;
}
.cardLeftInfo_Prise {
	margin-left: 3px;
	font-size: 18px;
	color: #727272;
}

.cardLeftInfo_Time {
	margin-left: 3px;
	/*margin-top: 68px;*/
}

.myOrdersCardCenter {
	flex: 1 1 319px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 19px 13px 16px 20px;
}

.cardCenter_topLocation {
	margin-bottom: 16px;
	font-size: 16px;
	color: #3d3d3d;
}

.cardCenter_topDelivery {
	font-size: 12px;
}

.cardCenter_topDelivery .cardCenter_topDeliveryDepartment {
	display: block;
	color: rgba(0, 0, 0, 1);
}

.cardCenter_topDelivery .cardCenter_topDeliveryAddress {
	display: block;
	margin-top: 4px;
	color: rgba(114, 114, 114, 1);
}

.cardCenter_bottom .approvedButton {
	width: 100%;
	height: 30px;
	background: #0bb82e;
	border-radius: 4px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
}

.cardCenter_bottom input {
	width: 172px;
	height: 30px;
	padding-left: 9px;
	margin-right: 14px;
	background: #ffffff;
	border: 1px solid #cecece;
	border-radius: 4px;
}

.cardCenter_bottom .submitButton {
	width: 100px;
	height: 30px;
	background: #0bb82e;
	border-radius: 4px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
}

.myOrdersCardRight {
	flex: 1 1 314px;
	padding: 18px 27px 16px 20px;
	display: flex;
	justify-content: space-between;
}

.sellerInfo {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.sellerInfo .sellerInfoChat {
	display: flex;
	gap: 16px;
	align-items: center;
	justify-content: center;
	width: 162px;
	height: 30px;
	background: #ffffff;
	border: 1px solid #cecece;
	border-radius: 4px;
	cursor: pointer;
}

.sellerInfoChatText {
	font-size: 14px;
	color: #727272;
}

.sellerInfoCross svg {
	fill: #bebebe;
	cursor: pointer;
}
.sellerInfoCross svg:hover {
	fill: #797979;
	cursor: pointer;
}
.sellerInfoChatNumber {
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffd74a;
	border-radius: 15px;
	font-size: 10px;
}

.sellerInfoPersonalInfo_name {
	margin-bottom: 3px;
}

.cardCenter_bottomDate span {
	margin-bottom: 17px;
	color: rgba(114, 114, 114, 1);
}

.cardCenter_bottomTTN span,
.bottomClosed_date span {
	color: rgba(114, 114, 114, 1);
}

.cardCenter_bottomSent {
	display: flex;
	flex-direction: column;
	gap: 17px;
}

.sellerInfoFeedback {
	width: 162px;
	height: 30px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 1px solid #cecece;
	border-radius: 4px;
	color: #727272;
	cursor: pointer;
}

.verticalLine {
	width: 1px;
	height: 120px;
	margin-top: 23px;
	background-color: #dcdcdc;
	border-radius: 15px;
}
