.activeLeftOptionContainer {
	padding: 0 11px 14px 0;
	display: flex;
	justify-content: space-between;
	min-width: 100%;
	/* max-width: 310px; */
	/*cursor: pointer;*/
	min-height: 170px;
}

.optionsLeft {
	display: flex;
	max-height: 156px;
	flex-direction: column;
	justify-content: space-between;
}

.optionsLeftButtons {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.optionsLeft .optionsLeftButtons button {
	width: 143px;
	height: 25px;
	background: #ffffff;
	border: 1px solid #bbbbbb;
	border-radius: 5px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 12px;
	/*font-size: 0.8vw;*/
	text-align: center;
	text-transform: uppercase;
	color: #bbbbbb;
}
.optionsLeft .optionsLeftButtons .offButton {
	cursor: pointer;
}
.optionsLeft .optionsLeftButtons button.activeRenewal {
	border: 1px solid #0bb82e;
	color: #0bb82e;
}
.optionsLeft .optionsLeftButtons button.activeRenewal:hover {
	border: 1px solid #0bb82e;
	color: #ffffff;
	background-color: #0bb82e;
}
.optionsLeft .optionsLeftButtons button.activeTop {
	border: 1px solid #00cefc;
	color: #00cefc;
}
.optionsLeft .optionsLeftButtons button.activeTop:hover {
	border: 1px solid #00cefc;
	color: #ffffff;
	background-color: #00cefc;
}
.optionsLeft .optionsLeftButtons button:hover {
	border: 1px solid #a9a9a9;
	color: #a9a9a9;
}

.optionsLeftBalance {
	/*width: 125px;*/
	/*white-space: nowrap;*/
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 12px;
	line-height: 150%;
	color: #727272;
	overflow: hidden;
}

/*==========Right Option==========================*/
.optionsRight {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
}

.optionsRightTop {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
	/* gap: 28px; */
}
.optionsRightTop span {
}
.optionsRightTop span svg {
	fill: #bebebe;
	cursor: pointer;
	margin-bottom: 90%;
}

.optionsRightTop span:hover svg {
	fill: #9a9a9a;
}

.optionsRightTop span {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 20px;
	/*line-height: 20px;*/
	color: #bbbbbb;
}

.pitchByNow {
	font-family: var(--myMainFontRegular) sans-serif;
	padding-top: 20px;
	font-size: 14px;
	/*line-height: 130%;*/
	color: #3d3d3d;
}

.optionsRightBottom button {
	text-transform: uppercase;
}

.optionsRightBottom button {
	min-width: 110px;
	justify-content: center;
	height: 25px;
	line-height: 130%;
	border: 1px solid #bbbbbb;
	border-radius: 5px;
	color: #bbbbbb;
}

.optionsRightBottom button:hover {
	border: 1px solid #a9a9a9;
	color: #a9a9a9;
}

/*=========POPUPS=============*/
.autoRenewalPopup {
	position: absolute;
	top: 32px;
	width: 143px;
	padding: 6px 12px;
	background: #ffffff;
	border: 1px solid #0bb82e;
	box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
	border-radius: 5px;
}

.autoRenewalPopupTop {
	width: 120px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 12px;
	text-align: center;
	text-transform: uppercase;
	padding-bottom: 6px;
	color: #0bb82e;
	border-bottom: 1px solid #cecece;
}
.autoRenewalPopupTop svg {
	margin-left: 10px;
	/*margin-bottom: 1px;*/
}
.autoRenewalPopupMain {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 12px;
	text-align: right;
	color: #727272;
	padding-top: 8px;
	padding-bottom: 11px;
	border-bottom: 1px solid #cecece;
}

.autoRenewalPopupBottom {
	padding-top: 2px;
	text-align: right;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 12px;
	text-transform: capitalize;
	color: #c4c4c4;
}

.pitchToTopPopup {
	position: absolute;
	top: 70px;
	width: 143px;
	/*height: 50px;*/
	background: #ffffff;
	border: 1px solid #00cefc;
	box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
	border-radius: 5px;
	padding: 6px 5px 5px 5px;
}

.pitchToTopPopupTitle {
	padding-bottom: 6px;
	font-size: 12px;
	text-align: center;
	text-transform: uppercase;
	color: #00cefc;
	border-bottom: 1px solid #cecece;
}

.pitchToTopPopupInfo {
	font-size: 12px;
	text-align: center;
	text-transform: uppercase;
	color: #727272;
	padding-top: 5px;
}
.optionsLeft .optionsLeftButtons .buttonOneUp {
	/*font-size: 0.7vw;*/
}
@media (max-width: 1025px) {
	.activeLeftOptionContainer {
		padding: 20px 11px 14px 0;
		display: flex;
		justify-content: space-between;
		max-width: 100%;
		width: 100%;
	}
	.optionsLeft .optionsLeftButtons {
		display: flex;
		/*flex-direction: row;*/
		min-width: 100%;
		padding-bottom: 15px;
	}
	.optionsLeft .optionsLeftButtons button {
		max-width: 100%;
		height: 25px;
		font-size: 12px;
		text-align: center;
	}
	.optionsLeft {
		flex: 0 0 90%;
		/*height: 125px;*/
		& button {
			width: 100%;
		}
	}
	.optionsLeft .optionsLeftButtons button {
		height: 36px;
	}
	.autoRenewalPopup {
		top: 45px;
	}
	.pitchToTopPopup {
		top: 90px;
	}
}
