.myPurchasesContainer h5 {
	margin-bottom: 21px;
}
.myPurchasesCardContainer {
	display: flex;
	height: 162px;
	background: #ffffff;
	box-shadow: 5px 4px 30px rgba(0, 0, 0, 0.1);
	border-radius: 15px;
}
.myPurchasesCardCenter {
	flex: 1 1 319px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 19px 13px 16px 20px;
}
.myPurchasesCardCenter_bottom {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 44px;
	background: #ffe898;
	border-radius: 4px;
}
.cardCenter_bottomContent {
	padding: 8px 0 6px 0;
	font-size: 14px;
	text-align: center;
	color: #3d3d3d;
	cursor: pointer;
}
.cardCenter_bottomContent span {
	display: block;
	color: #000000;
}
.myPurchasesCardCenter_bottomDate span {
	color: #727272;
}
