.verticalCardContainer {
	width: 160px;
	height: 238px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #ffffff;
	border-radius: 12px;
	padding: 15px;
}
.cardImage {
	position: relative;
	width: 100%;
	height: 140px;
	border-radius: 8px 8px 0 0;
	overflow: hidden;
}
.cardImage img {
	max-width: 100%;
	height: auto;
	border-radius: 8px 8px 0 0;
}
.cardImage .topMobileFlag {
	position: absolute;
	top: 20px;
}
/*.infoContainer {
    padding-left: 10px;
    padding-right: 8px;
}*/
.cardName {
	height: 52px;
}
.cardName h4 {
	padding-top: 10px;
	font-size: 1em;
	line-height: 22px;
	color: #000000;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.cardPrice {
	font-family: var(--myMainFontBold);
	/*font-size: 10px;*/
	font-size: 1em;
	line-height: 28px;
	color: #ff7020;
}
.cardLocationAndHeart {
	display: flex;
	width: 100%;
	justify-content: space-between;
	height: 18px;
	overflow: hidden;
}
.cardLocationAndHeart span {
	font-family: var(--myMainFontRegular);
	font-size: 1em;
	line-height: 18px;
	color: #898c8f;
	display: block;
}
.cardLocationAndHeart span svg {
	fill: #ffd74a;
}
@media (max-width: 1024px) {
	.verticalCardContainer {
		width: 180px;
		padding: 15px;
	}
}
@media (max-width: 990px) {
	.verticalCardContainer {
		width: 170px;
		padding: 10px;
	}
}
@media (max-width: 940px) {
	.verticalCardContainer {
		width: 160px;
		padding: 15px;
	}
}
@media (max-width: 890px) {
	.verticalCardContainer {
		width: 180px;
		padding: 15px;
	}
}
@media (max-width: 800px) {
	.verticalCardContainer {
		width: 170px;
		padding: 15px;
	}
}
@media (max-width: 580px) {
	.verticalCardContainer {
		width: 190px;
		padding: 15px;
	}
}
@media (max-width: 440px) {
	.verticalCardContainer {
		width: 160px;
		padding: 15px;
	}
}
