.advertisingContainer {
	margin: 0 auto;
}

/*
@media (max-width: 970px) {
    .advertisingContainer {
        display: none;
    }
}*/
