/*===================CheckBox start ==================*/
.checkBoxContainer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 15px;
}
.checkboxInput {
	/*-webkit-appearance: none;
    appearance: none;*/
	opacity: 0;
	position: absolute;
	z-index: -1;
}
.checkboxInput + label {
	display: flex;
	user-select: none;
	align-items: center;
	text-decoration-line: underline;
	color: #3d3d3d;
}
.checkboxInput + label::after {
	content: "";
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border: 1px solid #00a9ce;
	border-radius: 3px;
	margin-left: 10px;
	cursor: pointer;
}
.checkboxInput:checked + label::after {
	content: url("/src/images/checkBox.jpg");
}

@media (max-width: 1025px) {
	.checkboxInput + label {
		height: 34px !important;
	}
}

/*===================CheckBox end ==================*/
