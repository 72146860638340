.header {
	position: fixed;
	z-index: 60;
	width: 100%;
}
.headerContainer {
	min-width: 100%;
	height: 63px;
	display: flex;
	justify-content: space-between;
	gap: 40px;
	align-items: center;
	background: #2b2a38;
	caret-color: transparent;
	padding: 0 70px;
}
.headerContainer_leftSide {
	display: flex;
	align-items: center;
	gap: 32px;
}
.headerContainer_central {
	flex: 1 1 10%;
	display: flex;
	align-items: flex-start;
}
.headerContainer_RightSide {
	display: flex;
	align-items: center;
	gap: 35px;
}
.menuLogoBlock {
	display: flex;
	align-items: center;
	margin-left: 32px;
	margin-right: 100px;
	gap: 20px;
}
.menuButton svg {
	fill: #e5e5e5;
}
.menuButton {
	text-align: center;
	fill: #e3e3e4;
	cursor: pointer;
}
.menuButton:hover {
	fill: #ffffff;
}
.customCatalogBoard {
	color: #e3e3e4;
}

.search {
	width: 100%;
	position: relative;
}

.searchIcon {
	position: absolute;
	top: 20%;
	left: 15px;
	z-index: 1;
	cursor: pointer;
}

.searchInput input {
	width: 100%;
	height: 35px;
	padding-left: 40px;
	background: #ffffff;
	border: 1px solid #949494;
	backdrop-filter: blur(2.5px);
	border-radius: 10px;
	caret-color: initial;
}

.iconGroups {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 25px;
}

.iconGroups p {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 8px;
	text-transform: uppercase;
	color: #b7b7b7;
}

.iconGroups a:hover p {
	color: #ffffff;
}

.iconGroups a {
	width: 50px;
	display: flex;
	flex-direction: column;
	height: 34px;
	justify-content: space-between;
	align-items: center;
}

.headerRightBlock {
	display: flex;
	align-items: center;
	gap: 15px;
}

.addAD a {
	display: flex;
	margin-right: 18px;
	width: 190px;
	/*padding: 0 10px;*/
	background-color: #53e0ff;
	min-height: 33px;
	border-radius: 8px;
	color: #191848;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	cursor: pointer;
}

.addAD a:hover {
	background-color: #97eafe;
}

.addAD a h3 {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 14px;
	text-transform: uppercase;
}
.authBlock {
	cursor: pointer;
}

@media (max-width: 1400px) {
	.headerContainer {
		gap: 15px;
	}
	.headerContainer_leftSide {
		gap: 0;
	}
	.iconGroups {
		gap: 10px;
	}
	.iconGroups a {
		width: 55px;
	}
}
@media (max-width: 1025px) {
	.iconsGroupsElement {
		display: none;
	}

	.headerContainer {
		height: 63px;
		gap: 10px;
	}
	.headerContainer_RightSide {
		gap: 5px;
	}
	.headerRightBlock {
		gap: 8px;
	}
	.languageGroupsElement,
	.addAD {
		display: none;
	}
	.searchIcon {
		top: 10px;
		left: 12px;
	}
	.searchInput input {
		color: #c4c4c4;
		/*background: rgba(255, 255, 255, 0.11);*/
		border: 1px solid #949494;
		/*backdrop-filter: blur(2.5px);*/
		border-radius: 20px;
		caret-color: initial;
	}
	::placeholder {
		color: #c4c4c4;
	}
	.menuLogoBlock {
		margin-left: 15px;
		gap: 10px;
	}
	.auth {
		margin-top: 8px;
		width: 62px;
		margin-right: 14px;
	}
}

/*
@media (max-width: 768px) {
    .headerContainer_RightSide  {
        gap: 10px;
    }
}
@media (max-width: 762px) {
    !* .languageGroupsElement,
     .addAD  {
       display: none;
     }*!
}*/
