/*Template block*/
.container {
	width: 380px;
	height: 110px;
	background: #eaf2ff;
	padding: 5px 9px 9px 9px;
	border-radius: 5px;
}

.topBlock {
	border-bottom: 1px solid #9ac1ff;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.topBlock p {
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	font-size: 14px;
	font-weight: 600;
	margin: 0 0 5px 4px;
	color: #3d3d3d;
	text-overflow: ellipsis;
}

.mainContent {
	display: flex;
	justify-content: space-between;
	padding: 9px 0;
}

.imageBlock {
	width: 64px;
	height: 64px;
	background-color: white;
	border-radius: 10px;
	overflow: hidden;
}

.imageBlock img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.imageBlockTemplate {
	width: 64px;
	height: 64px;
}

.imageBlockTemplate img {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	border: 1px solid grey;
}

.noneImage {
	background-color: white;
	width: 64px;
	height: 64px;
}

.textBlock {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	max-width: 200px;
	overflow: hidden;
}

.textBlock p {
	font-size: 12px;
	color: #727272;
}

.buttonBlock {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.buttonBlock p {
	margin-right: 9px;
	font-size: 14px;
	font-weight: 600;
	color: #3d3d3d;
	border-bottom: 1px solid #3d3d3d;
}

/*Desktop modal*/

.modalWrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);
	z-index: 60;
	display: flex;
	/*align-items: center;*/
	justify-content: center;
}
.modalWrap ::-webkit-scrollbar {
	margin-right: 5px;
	width: 10px; /* Ширина вертикального скроллбара */
}
/* Стиль для ползунка скроллбара */
.modalWrap ::-webkit-scrollbar-thumb {
	background-color: #bfbfbf;
	border-radius: 10px; /* Закругленные углы ползунка */
}

/* Стиль для фона скроллбара */
.modalWrap ::-webkit-scrollbar-track {
	background-color: #d9d9d980; /* Цвет фона скроллбара */
	border-radius: 10px; /* Закругленные углы фона */
}

/* Стиль при наведении на ползунок */
.modalWrap::-webkit-scrollbar-thumb:hover {
	background-color: #555; /* Цвет ползунка при наведении */
}
.modalContainer {
	width: 1258px;
	max-height: 830px;
	/*margin-top: 14px;*/
	position: absolute;
	top: 70px;
	background-color: #fff;
	padding: 35px 35px 50px 35px;
	border-radius: 10px;
	overflow-y: auto;
	z-index: 100;
}

.modalContainerTopBlock {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.modalContainerContent {
}

.modalContainerContentTop {
	padding-bottom: 30px;
	border-bottom: 1px solid #dcdcdc;
}

.modalContainerContentTop_title {
	margin: 41px 0 30px 0;
}

.modalContainerContentTop_title p {
	font-size: 18px;
	font-weight: 600;
	color: #2b2a38;
}

.modalContainerContentTop_blocks {
	display: flex;
	justify-content: flex-start;
	gap: 16px;
}

.modalContainerContentBottom {
}

.modalContainerContentBottom_title {
	margin: 23px 0 10px 0;
	display: flex;
	/* justify-content: center; */
	align-items: center;
	/* gap: 20px; */
}

.modalContainerContentBottom_arrowBack {
	width: 30px;
	cursor: pointer;
}

.modalContainerContentBottom_title p {
	font-size: 18px;
	font-weight: 600;
}

.modalContainerTopBlock_inputWrap {
	width: 80%;
}

.modalContainerTopBlock_inputWrap input {
	border: 1px solid black;
	width: 100%;
}

.boardWrapper {
	width: 100%;
}

.boardContainerRow {
	display: flex;
	padding: 13px 0 0 0;
	flex-wrap: wrap;
	gap: 40px 42px;
}

.catalogItem {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 90px;
	cursor: pointer;
	caret-color: transparent;
	/* margin-bottom: 45px; */
	align-items: center;
}

.catalogItem .imageContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90px;
	height: 90px;
	overflow: hidden;
}

.catalogItem .imageContainer img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 2;
}
.squarePhoto {
	border-radius: 10px !important;
}
.roundPhoto {
	border-radius: 50px !important;
}
.catalogItem p {
	padding-top: 24px;
	text-align: center;
}

.catalogItem a p {
	text-decoration: none;
	color: #727272;
}

.catalogItem:hover a p {
	color: #3d3d3d;
}

.imageContainerFon {
	width: 90px;
	height: 90px;
	position: absolute;
	overflow: hidden;
	border-radius: 50px;
}
.imageContainerFon:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.mobileHeaderSearchBlock {
	/*width: 100%;*/
	/* max-height: 26px; */
	background-color: #fff;
	/* height: 100%;*/
	/*border-radius: 20px;*/
	display: flex;
	align-items: center;
	border: 1px solid #00a9ce;
	border-radius: 5px;
	width: 70%;
	height: 46px;
}

.mobileHeaderSearchBlock input {
	width: 90%;
	background: transparent;
	/* border-radius: 20px;
    border: 1px solid #FFF;
    height: 100%; */
}

.mobileHeaderSearchBlock svg {
	margin: 0 10px;
}
/*=================Mobile modal =====================*/

@media only screen and (max-width: 1024px) {

	.modalContainer {
		position: fixed;
		width: 100%;
		height: 100%;
		margin-top: 0;
		top: 60px;
		left: 0;
		padding: 35px 35px 20px 20px;
		border-radius: 0;
	}

	.modalContainerTopBlock {
		margin-bottom: 15px;
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	.modalContainerContent {
	}

	.modalContainerContentTop {
		padding-bottom: 30px;
		border-bottom: 1px solid #dcdcdc;
	}

	.modalContainerContentTop_title {
		margin: 25px 0 30px 0;
	}

	.modalContainerContentTop_title p {
		font-size: 18px;
		font-weight: 600;
		color: #2b2a38;
	}

	.modalContainerContentTop_blocks {
		flex-direction: column;
		justify-content: start;
		gap: 20px;
	}

	.modalContainerContentBottom {
	}

	.modalContainerContentBottom_title {
		margin: 30px 0 10px 0;
		display: flex;
		/* justify-content: center; */
		align-items: center;
		/* gap: 20px; */
	}

	.modalContainerContentBottom_arrowBack {
		width: 30px;
		cursor: pointer;
	}

	.modalContainerContentBottom_title p {
		font-size: 18px;
		font-weight: 600;
	}

	.modalContainerTopBlock_inputWrap {
		width: 80%;
	}

	.modalContainerTopBlock_inputWrap input {
		border: 1px solid black;
		width: 100%;
	}

	.boardWrapper {
		width: 100%;
	}

	.boardContainerRow {
		display: flex;
		padding: 43px 0 0 12px;
		flex-wrap: wrap;
		gap: 12px;
	}

	.catalogItem {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		width: 75px;
		cursor: pointer;
		caret-color: transparent;
		/* margin-bottom: 45px; */
		align-items: center;
	}

	.catalogItem .imageContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 75px;
		height: 75px;
		overflow: hidden;
	}

	.catalogItem .imageContainer img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: 2;
	}
	.squarePhoto {
		border-radius: 10px !important;
	}
	.roundPhoto {
		border-radius: 50px !important;
	}
	.catalogItem p {
		padding-top: 24px;
		text-align: center;
	}

	.catalogItem a p {
		text-decoration: none;
		color: #727272;
	}

	.catalogItem:hover a p {
		color: #3d3d3d;
	}

	.imageContainerFon {
		width: 90px;
		height: 90px;
		position: absolute;
		overflow: hidden;
		border-radius: 50px;
	}
	.imageContainerFon:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}

	.mobileHeaderSearchBlock {
		/* max-height: 26px; */
		background-color: #fff;
		display: flex;
		align-items: center;
		border: 1px solid #00a9ce;
		border-radius: 5px;
		width: 90%;
		height: 46px;
	}

	.mobileHeaderSearchBlock input {
		width: 70%;
		background: transparent;
		text-overflow: ellipsis;
		/* border-radius: 20px;
        border: 1px solid #FFF;
        height: 100%; */
	}

	.mobileHeaderSearchBlock svg {
		margin: 0 10px;
	}
}
@media only screen and (max-width: 500px) {
	.container {
		width: 336px;
		height: 110px;
	}
	.imageBlock {
		min-width: 64px;
	}
	.textBlock {
		max-width: 150px;
	}
}
@media only screen and (max-width: 374px) {
	.container {
		width: 300px;
		height: 110px;
	}
}
