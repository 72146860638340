.filtersWrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 30px;
	height: 22px;
	margin: 30px 0px 20px 0px;
}

.buttonDeleteBlock {
	display: flex;
	align-items: center;
	gap: 10px;
}

.rightBlock {
	display: flex;
	gap: 10px;
}

.changeVisibleIconBlock {
	display: flex;
	align-items: center;
}
