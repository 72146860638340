.cardContainer {
	display: flex;
	justify-content: space-between;
	padding: 14px 25px;
	background: #ffffff;
	box-shadow: 5px 4px 40px rgba(0, 0, 0, 0.15);
	border-radius: 15px;
}

.leftSide {
	display: flex;
	flex-direction: column;
	gap: 27px;
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	line-height: 14px;
	color: #727272;
	justify-content: space-between;
}

.leftSideTop h4 {
	font-family: var(--myMainFontBold);
	font-size: 18px;
	line-height: 120%;
	margin-bottom: 4px;
	cursor: pointer;
}

.leftSideTop h4.onLineIndicator::before {
	content: "";
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 11px;
	background: #34e045;
	border-radius: 15px;
}

.leftSideTop span {
	font-family: var(--myMainFontRegular);
	font-size: 12px;
	line-height: 15px;
	align-items: center;
	color: #8d8d8d;
}

.leftSideBottomLocation {
	max-width: 330px;
	/*height: 40px;*/
	display: flex;
	gap: 7px;
	align-items: center;
	/*white-space: nowrap;*/
	overflow: hidden;
}

.leftSideBottomButtons {
	display: flex;
	gap: 20px;
	margin-top: 12px;
}

.leftSideBottomButtons button {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 7px;
	width: 129px;
	height: 36px;
	border: 1px solid #a3a1a1;
	border-radius: 5px;
}

.leftSideBottomButtons button:hover {
	border: 1px solid #3d3d3d;
}

.rightSide {
	/*flex: 0 0 49%;*/
}

.rightSide .price {
	color: #000;
	font-size: 28px;
	font-weight: 500;
	line-height: normal;
}

.heart_Price {
	display: flex;
	justify-content: flex-end;
	gap: 50px;
	align-items: center;
}

.heart_Price svg {
	fill: #727272;
	cursor: pointer;
}

.heart_Price svg:hover {
	fill: #ffd74a;
}

.heart_Price span {
	font-family: MullerMedium, open-sans, sans-serif;
	font-size: 28px;
	line-height: 34px;
	color: #000000;
}

.orderButton {
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;
	height: 35px;
}

.orderButton button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 11px;
	width: 160px;
	height: 35px;
	border-radius: 4px;
	color: #ffffff;
	background: #00ab3a;
}

.orderButton button:hover {
	background: #0bb82e;
}

.seeInfoPanel {
	margin-top: 30px;
	display: flex;
	justify-content: flex-end;
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	line-height: 17px;
	color: #8c8c8c;
}

/*============Popup start =================*/
.wrapper {
	/* padding-bottom: 400px; */
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 50;
}

.sellerCardPopupContainer {
	width: 462px;
	/*height: 654px;*/
	/*min-width: 100%;
      min-height: 100%;*/
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border-radius: 5px;
	/*padding: 29px 19px 18px 30px;*/
	padding: 15px 19px 18px 20px;
	caret-color: transparent;
}

.sellerCardPopupTop {
	color: #6d6d6d;
}

.titleTop {
	display: flex;
	justify-content: space-between;
}

.sellerCardPopupTop h4 {
	padding-top: 4px;
	padding-bottom: 16px;
	margin-right: 12px;
	font-family: var(--myMainFontBold);
	font-size: 18px;
	text-transform: uppercase;
	color: #727272;
	border-bottom: 2px solid #ffd74a;
	overflow: hidden;
	text-overflow: ellipsis;
}

.titleTop span svg {
	fill: #727272;
	cursor: pointer;
}

.titleTop span:hover svg {
	fill: #2b2a38;
}

.sellerCardPopupBottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-decoration-line: underline;
	color: #727272;
}

.sellerCardPopupBottom .complain {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.sellerCardPopupBottom .complain svg {
	fill: #e0e0e0;
	margin-right: 8px;
	margin-top: 2px;
}

.sellerCardPopupBottom .complain:hover svg {
	fill: red;
}

.sellerCardPopupBottom svg {
	cursor: pointer;
}

.aboutSellerContainer {
	padding-top: 8px;
}

.verifiedSeller {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #2b2a38;
}

.verifiedSeller svg {
	margin-right: 8px;
}

.presentInService {
	margin-left: 26px;
	font-size: 16px;
	color: #2b2a38;
}

.deals {
	margin-left: 26px;
	font-size: 16px;
	color: #939393;
}

.deals span {
	padding: 0 5px;
	height: 19px;
	background: #ffe3e3;
	border-radius: 4px;
	color: #2b2a38;
}

.provenReputation {
	margin-left: 26px;
	font-size: 16px;
	color: #939393;
}

.useSafeDeal {
	display: flex;
	align-items: center;
}

.useSafeDeal svg {
	margin-right: 7px;
	color: #000000;
}

.useSafeDeal span a {
	color: #000000;
}

.recommendYou {
	margin: 38px 0 19px 26px;
	font-family: var(--myMainFontMedium);
	font-size: 18px;
	color: #2b2a38;
}

.attention {
	display: flex;
	align-items: center;
	color: #000000;
}

.attention svg {
	margin-right: 8px;
}

.contacts {
	gap: 20px;
	display: flex;
	margin: 35px 0 30px 26px;
}

.phoneNumbers a {
	/*flex: 0 0 60%;*/
	font-family: var(--myMainFontMedium);
	font-size: 28px;
	color: #009a2b;
}

.socialLinks {
	display: flex;
	align-items: flex-end;
	gap: 10px;
}
.socialLinks a {
	opacity: 0.85;
}
.socialLinks a:hover {
	opacity: 1;
}
.socialLinks svg {
	width: 30px;
	height: 30px;
}
.socialLinks.popup svg {
	width: 26px;
	height: 26px;
}

.buttons {
}

.buttons button {
	margin-left: 26px;
	margin-bottom: 15px;
	text-align: left;
	padding-left: 19px;
	padding-right: 17px;
	color: #595959;
	font-size: 16px;
}

.buttons button:hover {
	color: #2b2a38;
	border: 1px solid #797979;
}

.buttons button svg {
	/*stroke: #FFD74A;*/
}

.buttons button:hover svg {
	stroke-width: 2;
}

.addSellerToBusinessCards {
	width: 86.5%;
	height: 60px;
	border: 1px solid #b0adad;
	border-radius: 5px;
	display: flex;
	align-items: center;
	gap: 15px;
}

.trackNewItems {
	width: 86.5%;
	height: 70px;
	border: 1px solid #b0adad;
	border-radius: 5px;
	display: flex;
	align-items: center;
	gap: 15px;
}

.otherAdsSeller {
	display: inline-flex;
	justify-content: center;
	margin-bottom: 10px;
	width: 100%;
	border: 1px solid #b0adad;
	color: #3d3d3d;
	margin-left: 0 !important;
	padding: 12px 0;
	text-align: center !important;
	border-radius: 5px;
	font-size: 16px;
	transition: all 0.3s ease-in-out;
}

.otherAdsSeller:hover {
	border: 1px solid #3d3d3d;
}

@media (max-width: 1025px) {
	.sellerCardPopupContainer {
		width: 462px;
		height: 654px;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		/*background-color: #FFFFFF;*/
		border-radius: 5px;
		padding: 10px 20px 0 20px;
		caret-color: transparent;
	}

	.sellerCardPopupTop {
	}

	.sellerCardPopupTop h4 {
		white-space: nowrap;
		margin-right: 0;
	}

	.sellerCardPopupBottom {
		margin-bottom: 17px;
	}

	.buttons {
		width: 100%;
		display: flex;
		gap: 15px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.buttonAddVisit button,
	.buttonAddTrack button {
		margin: 0;
		padding: 0;
		width: 356px;
		height: 60px;
		/* margin-left: 26px;
            margin-bottom: 15px;
            text-align: left;
            padding-left: 19px;
            padding-right: 17px;*/
		display: block;
		color: #595959;
		font-size: 16px;
		text-align: center;
	}

	.buttonAddVisit svg,
	.buttonAddTrack svg {
		margin-right: 15px;
	}
}

/*============Popup end   =================*/
