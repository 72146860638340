.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);
	z-index: 50;
	display: flex;
	align-items: center;
	justify-content: center;
}
.container {
	padding: 25px 38px 28px 39px;
	width: 400px;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	background-color: #fff;
}
.topNavigate {
	text-align: right;
	color: #6d6d6d;
	font-size: 16px;
}
.topNavigate svg {
	width: 20px;
	cursor: pointer;
}
.title {
	color: #383748;
	text-align: center;
	font-family: var(--myMainFontBold);
	font-size: 18px;
	margin-bottom: 30px;
}
.info {
	/*margin-top: 25px;*/
	margin: 0 auto 20px;
	width: 270px;
	overflow: hidden;
	/*padding: 0 20px;*/
	color: #6d6d6d;
	text-align: center;
	font-size: 14px;
}
.changePhoneInfo {
	margin-top: 50px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	gap: 30px;
}
.sendNewCode {
	color: #6d6d6d;
	font-size: 12px;
	text-decoration-line: underline;
	cursor: pointer;
}
.enterSmsCodeInput input {
	height: 40px;
	border-radius: 5px;
	border: 1px solid #d3d3d3;
	margin-top: 15px;
	width: 100%;
	text-align: center;
	color: #9a9a9a;
	font-size: 16px;
}
.button {
	margin-top: 25px;
}
.wrapper .container .button button {
	width: 100%;
	height: 45px;
	border-radius: 5px;
	background: #ffd74a;
	color: #2b2a38;
	text-align: center;
	font-size: 16px;
	text-transform: uppercase;
}
.error {
	color: red;
}
.phoneInputContainer {
	text-align: center;
	margin-top: 20px;
}
.phoneInput {
	min-width: 100%;
	height: 40px !important;
}
