.workContent {
  width: 100%;
  margin-top: 20px; /*https://app.clickup.com/t/86933gzbe */
  margin-bottom: 60px;
  display: flex;
  gap: 31px;
  font-size: 16px;
  line-height: 18px;
  color: #3D3D3D;
}
.workContentLeft {
	flex-grow: 1;
	overflow: hidden;
  align-self: stretch;
  display: flex;
  flex-direction: column;

  padding: 24px 35px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 5px 4px 40px rgba(0, 0, 0, 0.15);
  color: #3d3d3d;
}
.workContentRight {
	min-width: 354px;
  max-width: 354px;
  height: min-content;
}
.workRightInfo {
  padding: 24px 27px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 5px 4px 40px rgba(0, 0, 0, 0.15);
  color: #3d3d3d;
  margin-bottom: 52px;
}
.advertising {
  display: grid;
  grid-template-columns: 1fr 354px;
  gap: 31px;
}

.workTitle {
  font-family: var(--myMainFontRegular);
  font-size: 18px;
  font-weight: 400;
  line-height: 24.51px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.workTitle.mobile {
  text-transform: uppercase;
  margin-top: 12px;
  font-family: var(--myMainFontMedium);
  color: #2B2A38;
}
.workTop {
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding-bottom: 14px;
  margin-bottom: 20px;
  border-bottom: 1px solid #DCDCDC
}

.heart {
	display: flex;
	justify-content: flex-end;
	gap: 50px;
	align-items: center;
}
.heart svg {
	fill: #727272;
	cursor: pointer;
}
.heart svg:hover {
	fill: #ffd74a;
}
.heart span {
	font-family: MullerMedium, open-sans, sans-serif;
	font-size: 28px;
	line-height: 34px;
  color: #3d3d3d;
}

.list {
  font-size: 16px;
  line-height: 18px;;
}
.list.mobile {
  background: #F6F4F4;
  padding: 21px 39px;
}
.list li {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.list li:not(:last-child) {
  margin-bottom: 20px;
}
.list li svg {
  min-width: 16px;
}

.properties {
  margin: 22px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.properties.mobile {
  margin: 0 0 40px;
}
.properties li {
  padding: 8px 10px;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  font-size: 12px;
  line-height: 120%;
}

.description {
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  white-space: pre-wrap;
}

.workBottom {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: auto;
  border-top: 1px solid #DCDCDC;
  color: #727272;
  font-size: 14px;
  line-height: 20px;
}
.views {
  display: flex;
  align-items: center;
  gap: 5px;
}
.warning {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.warning svg {
  fill: #E0E0E0;
}
.warning:hover svg {
  fill: red;
}

.owner {
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	line-height: 120%;
	color: #727272;
}

.owner h4 {
	font-family: var(--myMainFontBold);
	font-size: 18px;
	line-height: 120%;
	margin-bottom: 6px;
	cursor: pointer;
  display: flex;
  align-items: center;
  gap: 11px;
}
.owner h4:hover {
  color: #3d3d3d;
}

.logoWrap {
  margin: 15px 0 17px;
  display: flex;
  justify-content: center;
}

.owner h4.onLineIndicator::before {
	content: "";
	display: inline-block;
	width: 10px;
	height: 10px;
	background: #34e045;
	border-radius: 15px;
}

.seeNow {
  color: #8D8D8D;
  font-size: 12px;
	line-height: 120%;
  font-weight: 400;
  margin-bottom: 15px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.buttonWrapper button {
  border: 1px solid #A3A1A1;
  border-radius: 5px;
  padding: 15px 32px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #3D3D3D;
}
.buttonWrapper button:hover {
  border-color: #3d3d3d;
}
.buttonWrapper button span {
  margin: 0 auto;
}

.workTopMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  padding-right: 12px;
}

.buttonGrey {
  background-color: transparent;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.buttonGrey:hover {
  background-color: #0000000D;
}
.buttonGrey path {
  stroke: #9D9D9D;
}

.workHeadMobile {
  padding: 20px 12px;
  border-radius: 15px 15px 0 0;
  border-top: 1px solid #3D3D3D63
}
.workTitleWrap {
  margin-bottom: 27px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}

.seeNowMobile {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #3D3D3D;
  padding: 25px;
  width: 100%;
  border: 1px solid #62D4ED59;
  border-radius: 10px;
  background: #15CDF526;
  display: flex;
  justify-content: center;
}

.workContentMobile {
  padding: 20px 12px;
}
.descriptionMobile {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 25px;
}

.comments {
  padding: 0 32px;
  max-width: calc(100% - 385px);
  width: 100%;
}