.footerContainer {
	padding: 21px 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	width: 100%;
	height: 85px;
	bottom: 0;
	background: #3d3d3d;
	box-shadow: 5px 4px 40px rgba(0, 0, 0, 0.15);
	z-index: 50;
}
.footerContainer a {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	height: 42px;
	border: 1px solid #a3a1a1;
	border-radius: 5px;
	color: #ffffff;
	width: 100%;
	color: #3d3d3d;
	background: #ffffff;
}
