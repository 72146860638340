.recentlyWatchedContainer {
	margin-top: 50px;
	width: 100%;
}
.recentlyWatchedMobileContainer {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.recentlyWatchedContainer h5 {
	padding-bottom: 32px;
	font-family: var(--myMainFontMedium);
	font-size: 22px;
	margin-left: 10px;
	/*line-height: 30px;*/
	color: #2b2a38;
}
.recentlyWatchedRow {
	display: flex;
	/* justify-content: stretch; */
	justify-content: flex-start;
	width: 100%;
	gap: 5px 10px;
	margin-bottom: 50px;
	/*overflow: hidden;*/
	flex-wrap: wrap;
	/*-webkit-line-clamp: 1;
    -webkit-box-orient: vertical;*/
}
@media (max-width: 1025px) {
	.recentlyWatchedContainer {
		margin-top: 35px;
	}
	.recentlyWatchedContainer h5 {
		padding-bottom: 30px;
		font-family: var(--myMainFontMedium);
		font-size: 16px;
		line-height: 16px;
		color: #2b2a38;
	}
	.recentlyWatchedRow {
		padding: 0 5px;
	}
}
