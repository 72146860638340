.modalContainer {
	width: 634px;
	height: 404px;
	background: #ffffff;
	border-radius: 4px;
	caret-color: transparent;
}
.topTitle {
	padding-right: 18px;
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 30px;
	background: #fff3c9;
	border-radius: 5px 5px 0 0;
}
.topTitle h4 {
	display: flex;
	align-items: center;
	font-family: var(--myMainFontRegular);
	font-size: 18px;
	text-transform: uppercase;
	color: #3d3d3d;
}
.topTitle svg {
	margin-right: 12px;
	cursor: pointer;
}
.topCardInfo {
	padding: 15px 15px 3px 30px;
	display: flex;
	gap: 16px;
	/*justify-content: space-between;*/
	border-bottom: 1px solid #dcdcdc;
}
.topCardInfo_Left {
	flex: 0 0 9%;
}
.topCardInfoImage {
	width: 56px;
	height: 42px;
}
.topCardInfoImage img {
	width: 100%;
	height: 100%;
	object-fit: fill;
}
.topCardInfo_Right {
	flex: 1 1 90%;
	display: flex;
	flex-direction: column;
}
.rightInfoTop {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}

.topCardInfoName_Title {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 16px;
	line-height: 16px;
	color: #000000;
	max-width: 70%;
	max-height: 30px;
	overflow: hidden;
}
.topCardInfoName_Location {
	margin-top: 3px;
	font-size: 14px;
	color: #3d3d3d;
}
.rightInfoBottom {
	display: flex;
	justify-content: flex-end;
}
.topCardInfoPrice_Sum {
	min-width: 25%;
	white-space: nowrap;
	overflow: hidden;
	font-size: 18px;
	line-height: 18px;
	color: #727272;
	text-overflow: ellipsis;
	text-align: right;
}
.topCardInfoPrice_Balance {
	font-size: 14px;
	line-height: 150%;
	color: #727272;
}
.topCardSelectLine {
	margin: 28px 23px 16px 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.topCardSelectLine_Info {
	font-family: var(--myMainFontRegular);
	font-size: 16px;
	line-height: 19px;
	color: #2b2a38;
}
.topCardSelectBuyInfo {
	margin: 0 72px 11px 30px;
	font-size: 16px;
	line-height: 150%;
	color: #727272;
}
.topCardSelectBuyInfo span {
	color: rgba(14, 171, 30, 1);
	font-family: var(--myMainFontBold);
}
.topCardSelectPayTitle {
	margin: 11px 300px 25px 32px;
	font-size: 16px;
	line-height: 150%;
	color: #000000;
}
.topCardSelectPayBottomFunction {
	margin-right: 26px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.topCardSelectPayBottomFunction button {
	width: 140px;
	height: 35px;
	margin-left: 42px;
	background: #79be01;
	border-radius: 5px;
	font-family: var(--myMainFontBold);
	font-size: 12px;
	text-transform: uppercase;
	color: #ffffff;
}
.bottomFunction_Sum {
	margin-left: 29px;
	font-family: var(--myMainFontBold);
	font-size: 18px;
	color: #79be01;
}
.topCardSelectPayBottomFunction button.disabled {
	color: #bbbbbb;
	background-color: #ffffff;
	border: 1px solid #bbbbbb;
}
.topCardSelectPayBottomFunction span:first-child {
	font-family: var(--myMainFontRegular);
	font-size: 16px;
	color: #000000;
}
@media (max-width: 1025px) {
	.modalContainer {
		width: 97%;
		height: 100%;
	}
	.topTitle {
		height: 44px;
		justify-content: space-between;
		padding-left: 0;
		padding-right: 12px;
	}
	.topTitle h4 {
		margin-top: 0;
		margin-left: 12px;
		margin-right: 0;
		font-size: 16px;
		white-space: nowrap;
	}
	.topCardInfo {
		padding: 15px 8px 5px;
		gap: 16px;
		/*justify-content: space-between;*/
		border-bottom: 1px solid #dcdcdc;
	}
	.rightInfoTop {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
	}
	.topCardInfoName_Title {
		max-width: 470px;
		white-space: nowrap;
		font-size: 15px;
		line-height: 15px;
		color: #000000;
	}
	.topCardInfoName_Title h5 {
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.topCardInfoName_Location {
		font-size: 14px;
		color: #3d3d3d;
	}

	.topCardInfoPrice_Balance {
		font-size: 12px;
		line-height: 150%;
		text-align: right;
		color: #000000;
		white-space: nowrap;
	}
	.topCardSelectLine {
		margin: 0;
		padding: 20px 0 15px 7px;
		flex-direction: column;
		align-items: flex-start;
		row-gap: 17px;
	}
	.topCardSelectLine_Info {
		padding-left: 14px;
		font-size: 14px;
		line-height: 14px;
	}
	.topCardSelectLine_Select {
		width: 100%;
		padding-right: 7px;
	}
	.topCardSelectBuyInfo {
		padding: 0 7px;
		margin: 0;
		font-size: 14px;
	}
	.topCardSelectPayTitle {
		padding: 15px 7px 20px;
		margin: 0;
	}
	.topCardSelectPayBottomFunction {
		margin: 0;
		padding: 0 7px 25px;
		/*display: flex;*/
		justify-content: space-between;
	}
	.topCardSelectPayBottomFunction button {
		margin: 0;
		background: #79be01;
		border-radius: 5px;
		font-family: var(--myMainFontBold);
		font-size: 12px;
		text-transform: uppercase;
		color: #ffffff;
	}
	.bottomFunction_Sum {
		margin: 0;
		font-family: var(--myMainFontBold);
		font-size: 18px;
		color: #79be01;
	}
}
