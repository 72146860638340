.swiperForMainMobile .swiper-wrapper {
	height: initial;
}
.swiperForMainMobile .swiper-pagination {
	/* display: flex;
     justify-content: center;
     align-items: flex-end;*/
	/*padding: 20px 0;*/
	padding-bottom: 20px;
}
.swiperForMainMobile .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
	/* bottom: 200px !important;*/
}
.swiperForMainMobile .swiper-pagination-bullet-active,
.swiperForMainCategory .swiper-pagination-bullet-active {
	background-color: #000000;
}
.swiperForMainCategory .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
	bottom: -10px !important;
}

.swiper,
swiper-container {
	overflow: visible !important;
}
