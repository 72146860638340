.container {
    margin: 60px 0;
    background: white;
    padding: 30px 33px
}
.cardContainer {
    min-height: 133px;
    display: flex;
    gap: 80px;
}
.logo {
    padding-left: 30px;
    min-height: 100%;
    display: flex;
    align-items: center;
}
.content {
    width: 100%;
    padding: 15px 25px 20px 0;
}
.title {
    font-size: 22px;
    padding-bottom: 15px;
}

.linksContainer {
    display: flex;
    column-gap: 16px;
    row-gap: 15px;
    flex-wrap: wrap;
}
.linkItem a {
    font-family: var(--myMainFontRegular);
    color: #3D3D3D;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
}
.linkItem a:hover {
    color: #6c6c6c;
}