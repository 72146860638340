.checkBoxContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	margin-bottom: 10px;
}

.label {
	display: flex;
	align-items: flex-start;
	cursor: pointer;
	position: relative;
	user-select: none;
	width: 100%;
}

.labelText {
	display: flex;
	align-items: flex-start;
	cursor: pointer;
	position: relative;
	user-select: none;
	width: 100%;
	gap: 5px;
}

.checkboxInput {
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
	width: 25px;
	height: 23px;
	margin: 0;
	cursor: pointer;
	z-index: 1;
}

.customCheckbox {
	width: 25px;
	height: 23px;
	border: 1.5px solid #a8a8a8;
	border-radius: 4px;
	margin-right: 10px;
	background-color: #fff;
	display: inline-block;
	position: relative;
	transition:
		border-color 0.3s,
		background-color 0.3s;
}

.checkboxInput:checked + .customCheckbox {
	border-color: #00a9ce;
}

.checkboxInput:checked + .customCheckbox::after {
	content: "";
	position: absolute;
	top: 2px;
	left: 7px;
	width: 6px;
	height: 12px;
	border: solid #00a9ce;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.customCheckbox:hover {
	border-color: #007bb5;
}

.checkboxInput:focus + .customCheckbox {
	box-shadow: 0 0 0 2px rgba(0, 169, 206, 0.5);
}

.checkboxInput:checked + .customCheckbox::after {
	opacity: 0;
	transform: rotate(45deg) scale(0);
	transition:
		opacity 0.3s ease,
		transform 0.3s ease;
}

.checkboxInput:checked + .customCheckbox::after {
	opacity: 1;
	transform: rotate(45deg) scale(1);
}

.labelText {
	/* user-select: none; */
	text-decoration: none;
	color: #3d3d3d;
	cursor: pointer;
}

.checkboxInput:disabled + .customCheckbox {
	background-color: #e6e6e6;
	border-color: #cccccc;
	cursor: not-allowed;
}

.fieldSide textarea:focus + .descriptionOptions .descriptionOptions_Size {
	color: #00a9ce;
}

.checkboxInput:disabled + .customCheckbox::after {
	border-color: #cccccc;
}

.descriptionOptions {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.fieldSide {
	width: 100%;
}

.descriptionOptions_Size {
	color: #939393;
	font-size: 18px;
}

.fieldSide textarea {
	caret-color: initial;
}
.fieldSide textarea:focus {
	border-bottom: 2px solid #00a9ce;
}
.fieldSide textarea:focus + .descriptionOptions .descriptionOptions_Size {
	color: #00a9ce;
}

.fieldSide textarea {
	max-width: 100%;
	width: 500px;
	height: 200px;
	padding: 20px 0 0 20px;
	border-radius: 4px;
	border: 1px solid #dcdcdc;
	background: #ffffff;
	/*font-size: 16px;*/
	font-size: 18px;
	color: #939393;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

@media (max-width: 1025px) {
	.labelText {
		height: 34px !important;
	}

	.fieldSide textarea {
		width: 300px;
	}

	.label {
		flex-direction: column;
		gap: 5px;
	}

	.label .customCheckbox {
		width: 23px;
	}
}
