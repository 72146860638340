.linkPanel_element a.active {
	font-family: var(--myMainFontBold);
	color: #3d3d3d;
	background: white;
}
@media (max-width: 1025px) {
	.linkPanel_element a.active {
		font-size: 14px;
	}
	.caretElement {
		margin: 0 5px;
	}
}
