.nameInputsContainer {
	display: flex;
	width: 100%;
	/*justify-content: space-between;*/
	align-items: flex-end;

	/*margin-top: 23px;*/
}

.phoneInputContainer .phoneInputStyle,
.nameInputsInputs .nameInputsInputs {
	color: #9a9a9a;
	font-size: 16px;
	height: 40px !important;
}
.nameInputsInputs {
	width: 100%;
}
.nameInputsInputs .nameField {
	width: 280px;
}
.nameInputsInputs input::placeholder {
	color: rgba(155, 155, 155, 0.7);

}
.lastNameContainer {
	margin-top: 15px;
}
.descriptionInput {
	padding-bottom: 5px;
}
.phoneInputStyle {
	height: 40px !important;
	width: 100% !important;
}
.phoneInputContainer {
	align-items: flex-end;
}
.phoneInputContainer img {
	border-radius: 50%;
	overflow: hidden;
	background-color: #ccc;
}
.phoneInputStyle.error,
.emailInputInput.error input {
	border-bottom-color: red;
}
.verifyContainer input,
.checkRatingContainer input,
.banksCardContainer input,
.emailInputContainer input,
.changedPassContainer input,
.nameInputsContainer input {
	width: 280px;
	height: 40px;
	padding-left: 16px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #e7e7e7;
	background: #ffffff;
	color: #727272;
	caret-color: initial;
}
.phoneInputContainer input {
}
.verifyContainer button,
.limitedPossibilityContainer button,
.checkRatingContainer button,
.deliveryContainer button,
.banksCardContainer button,
.emailInputContainer button,
.changedPassContainer button,
.phoneInputContainer button,
.nameInputsContainer button,
.deliveryDetails button {
	min-width: 187px;
	height: 40px;
	border-radius: 5px;
	border: 1px solid #c4c4c4;
	color: #c4c4c4;
	background: #fff;
	font-size: 14px;
	line-height: 130%;
	text-transform: uppercase;
}

.nameInputsInputs input {
	width: 350px;
	/*display: flex;*/
	/*gap: 40px;*/
	/*flex-wrap: wrap;
    align-items: flex-end;*/
}
.phoneAndMessenger {
	margin-top: 15px;
	display: flex;
	align-items: center;
	gap: 20px;
	flex-direction: column;
	width: 280px;
}
.nameInputsInputs .detailsElementDescription p {
	padding: 32px 0;
	font-size: 14px;
}

.phoneInputContainer {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-top: 10px;
}
.verifyContainer,
.limitedPossibilityContainer,
.checkRatingContainer,
.deliveryContainer,
.banksCardContainer,
.emailInputContainer,
.changedPassContainer {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.changedPassInputs {
	position: relative;
}
.changedPassInputs input:nth-child(2) {
	margin-left: 20px;
}

.changedPassInputs .seePassButton {
	position: absolute;
	top: 7px;
	right: 10px;
}
.changedPassInputs .seePassButton svg {
	margin-left: 13px;
}
.checkBoxContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	/*margin-top: 15px;*/
}
.checkboxInput {
	opacity: 0;
	position: absolute;
	z-index: -1;
}
.changedPassContainer input {
	padding-right: 95px;
	text-overflow: ellipsis;
}
.checkboxInput + label {
	display: flex;
	user-select: none;
	align-items: center;
	color: #6f6f6f;
	font-family: var(--myMainFontRegular) sans-serif;
}
.checkboxInput + label::before {
	content: "";
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border: 1px solid #00a9ce;
	border-radius: 3px;
	margin-right: 10px;
	cursor: pointer;
}
.checkboxInput:checked + label::before {
	content: url("/src/images/checkBox.jpg");
}
.emailInputWrapper {
	margin-top: 13px;
	margin-bottom: 13px;
}
.banksCardInputs input {
	margin: 13px 0;
}
.deliveryTable {
	margin-top: 35px;
	display: flex;
	flex-direction: column;
	gap: 18px;
}
.deliveryTable button {
	display: flex;
	align-items: center;
	padding-left: 20px;
	gap: 20px;
	width: 198px;
	height: 50px;
	border-radius: 4px;
	border: 1px solid #dfdfdf;
	background: #fff;
}
.deliveryInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.deliveryInfo span:first-child {
	color: #373737;
	font-size: 14px;
}
.deliveryInfo span:last-child {
	color: #9b9b9b;
	font-size: 12px;
}
.checkRatingInputs {
	display: flex;
	flex-direction: column;
	gap: 15px;
}
.limitedPossibilityInputs p {
	margin-top: 25px;
}
.limitedPossibilityInputs p span {
	color: #3d3d3d;
}

.verifyInputs {
	color: #727272;
	line-height: normal;
}
.checkedSeller {
	display: flex;
	align-items: center;
	column-gap: 15px;
}
.checkedSeller span {
	color: #000;
}
.upYourRating {
	margin-top: 10px;
}
.verifyInputsInputs {
	margin: 16px 0 20px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	font-size: 16px;
}
.addDocText {
	font-size: 16px;
	margin-bottom: 18px;
}
.checkDocImgContainer {
}
.addNoteText {
	color: #000;
	font-size: 14px;
	margin-top: 26px;
}
.verificationText {
	margin-top: 18px;
	color: #6f6f6f;
	font-size: 14px;
}
.approvedGreenButton {
	margin-right: 20px;
}
.approvedGreenButton.activeGreen button {
	background-color: green;
	color: white;
}


@media (max-width: 1024px) {
	.nameInputsInputs .nameField {
		width: 100%;
	}
	.phoneInput {
		width: 100%;
	}

	.phoneInputContainer {
		align-items: center;
	}
	.nameInputsInputs .detailsElementDescription p {
		padding: 0;
		font-size: 14px;
	}
	.changedPassInputs {
		width: 100%;
	}
	.changedPassInputs input {
		width: 100%;
	}
	.phoneAndMessenger {
		margin-top: 0;
		flex-direction: column;
		width: 100%;
	}
	.phoneInputContainer .phoneInputStyle {
		width: 100%;
	}
	.nameInputsContainer {
		width: 100%;
		margin-top: 5px;
		flex-direction: column;
	}
	.nameInputsContainer button {
		width: 100%;
	}
	.nameInputsInputs {
		display: flex;
		gap: 25px;
		align-items: flex-end;
		flex-direction: column;
		width: 100%;
	}
	.nameInputsInputs input {
		width: 100%;
	}

	.nameInputsButtonContainer {
		width: 100%;
	}
	.phoneInputContainer {
		display: flex;
		padding-top: 0;
		flex-direction: column;
		row-gap: 30px;
	}
	.phoneInputContainer button {
		width: 100%;
	}
	.changedPassContainer {
		flex-direction: column;
		align-items: flex-start;
		row-gap: 30px;
	}
	.changedPassContainer button {
		width: 100%;
	}
	.emailInputContainer {
		display: flex;
		flex-direction: column;
		row-gap: 15px;
	}
	.emailInputContainer .emailInputInput,
	.emailInputContainer .emailInputInput input,
	.emailInputContainer button {
		width: 100%;
	}
	.checkboxInput + label::before {
		margin-right: 5px;
	}
	.deliveryContainer,
	.banksCardContainer {
		display: flex;
		flex-direction: column;
		row-gap: 15px;
	}
	.verifyContainer,
	.limitedPossibilityContainer,
	.checkRatingContainer,
	.deliveryContainer {
		display: flex;
		flex-direction: column;
		row-gap: 30px;
	}
	.verifyContainer button,
	.verifyContainer input,
	.limitedPossibilityContainer button,
	.checkRatingContainer button,
	.checkRatingContainer input,
	.deliveryContainer button,
	.deliveryContainer input,
	.banksCardContainer button,
	.banksCardContainer input {
		width: 100%;
	}

	.btn {
		justify-content: center;
	}

	.btn button {
		width: 100%;
		justify-content: center;
	}
	.lastNameContainer {
		width: 100%;
	}
}
.approvedInfoText {
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: 20px;
	height: 40px;
}
.error {
	color: red;
}
.bindAccContainer {
}
.bindAccTextContainer {
	margin-bottom: 30px;
	color: #6f6f6f;
	font-size: 14px;
	line-height: 130%; /* 18.2px */
}
.buttonsContainer {
	display: flex;
	gap: 25px;
	align-content: center;
}
.buttonsContainer button {
	width: 200px;
	height: 46px;
	border-radius: 5px;
	border: 1px solid #d1d1d1;
	background: #f0f0f0;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 14px;
}

.buttonsContainerButName {
	color: #000;
	text-align: center;
	font-size: 16px;
}
.buttonsContainer button .icon {
	width: 30px;
	height: 30px;
}
.appleIcon {
	margin-bottom: 5px;
}
.buttonsContainer button:hover {
	background: #f3f3f3;
	border: 1px solid #dfdfdf;
}

.dynamicPages {
	min-height: 42px;
	border-bottom: 1px solid #dcdcdc;
	display: flex;
	align-items: center;
	font-size: 14px;
	color: #3d3d3d;
	padding-left: 6px;
}

.dynamicPagesWrap {
	border-top: 1px solid #dcdcdc;
	width: 100%;
}
.deleteAccContainer .dearText {
	padding: 5px 0 13px 12px;
	color: #6f6f6f;
	line-height: 18px;
}
.deleteAccContainer ol li {
	margin-left: 28px;
	/*list-style: disc inside;*/
	color: #262424;
}
.seeYouAgain {
	padding-top: 10px;
	line-height: 18px;
	color: #6f6f6f;
}
.deleteAccLogo {
	/*width: 100%;*/
	/*margin: 30px auto;*/
	margin: 64px 0 3px 41px;
	/*text-align: center;*/
}
/*
@media (max-width: 700px) {
    .messengerIcons {
        justify-content: center;
    }
}*/
