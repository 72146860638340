.wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
}
.container {
	padding: 0 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 1280px;
	margin-top: 112px;
}
.animationContainer {
	max-width: 500px;
}
.imageContainer {
	position: relative;
}
.veryFunnyText {
	position: absolute;
	top: 10px;
	left: 40px;
	font-family: var(--MyFunnyFont) open-sans sans-serif;
	color: #00a9ce;
	text-align: center;
	font-size: 16px;
}
.title {
	font-family: var(--myMainFontMedium) open-sans sans-serif;
	font-size: 26px;
}
.image {
	margin-top: 95px;
}
.button {
	margin-top: 105px;
}
.errorPageButton {
	width: 180px;
	height: 35px;
	border-radius: 5px;
	background-color: rgba(137, 208, 14, 1);
	color: rgba(255, 255, 255, 1);
	font-family: var(--myMainFontBold) open-sans sans-serif;
	font-size: 12px;
}
@media screen and (max-width: 635px) {
	.container {
		padding: 0 15px;
		max-width: 400px;
		justify-content: space-between;
		height: 100vw;
	}
	.title {
		width: 100%;
		font-size: 5vw;
		text-align: center;
	}
	.veryFunnyText {
		position: initial;
		text-align: center;
		font-size: 4vw;
	}
	.imageMobile {
		margin-top: 50px;
		text-align: center;
	}
	.image {
		margin-top: 0;
	}
	.button {
		margin-top: 0;
		margin-bottom: 60px;
	}
}
