.container {
	position: relative;
	font-size: 16px;
	min-width: 247px;
	background: #fff;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.09);
	border-radius: 5px;
	cursor: pointer;
}
.selectInput {
	height: 34px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px;
}
.selectMenuContainer {
	position: absolute;
	background: #fff;
	/*width: 247px;*/

	z-index: 10;
	border-radius: 0 0 5px 5px;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.09);
}
.selectMenu {
	margin-right: 10px;
	max-height: 344px;
	overflow: auto;
	border-top: 1px solid #e2e2e2;
}
.selectMenuItem {
	padding-left: 10px;
	display: flex;
	height: 35px;
	max-width: 100%;
	white-space: nowrap;
	align-items: center;
	margin-bottom: 6px;
	overflow: hidden;
}

.selectMenuItem:hover {
	background-color: #f2f2f2;
}
.collapsedBorder {
	border-radius: 5px 5px 0 0;
}
.selectMenu::-webkit-scrollbar {
	width: 4px;
}
.selectMenu::-webkit-scrollbar-thumb {
	background-color: #00a9ce; /* Цвет ползунка */
	border-radius: 26px; /* Закругленные углы ползунка */
	cursor: pointer;
}
.selectMenu::-webkit-scrollbar-track {
	padding-right: 20px;
	border-radius: 26px;
	background: #eee;
	margin: 10px 10px 10px 0;
	cursor: pointer;
}
