.homePageContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: 63px;
}
.homeSwiper {
	margin-top: 6px;
	position: relative;
	width: 100%;
	height: 140px;
	z-index: 0;
}
.homeSwiper img {
	width: 100%;
	/*height: 305px;*/
	height: 100%;
	object-fit: fill;
}
.advertContainer {
	width: 100%;
	margin: 0 auto;
}
/*.adverts {
    width: 970px;
    height: 250px;
    margin-top: 50px;
    text-align: center;
}*/
@media (max-width: 1700px) {
	.homeSwiper {
		height: 140px;
	}
}
@media (max-width: 1024px) {
	.advertsMobileBlock {
		width: 360px;
		height: 300px;
		background: rgba(0, 0, 0, 0.2);
	}
}
/* @media (min-width: 390px) and (max-width: 1024px){
    .paddingWrapper {
        padding: 0 15px;

    }
} */
