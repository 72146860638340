.container {
	width: 100%;
	margin: 50px 0;
	padding: 30px 33px;
	background: #ffffff;
	caret-color: transparent;
	box-shadow: 1px 1px 100px rgba(0, 0, 0, 0.03);
}

.cardContainer {
	display: flex;
	height: 133px;
	/* background: #E2ECFF; */
	border-radius: 5px;
	/* flex-wrap: wrap; */
	/* justify-content: space-between; */
}

.contentAuthorWrap {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
}

.contentDescriptionAuthor {
	/* font-size: 18px;
    line-height: 20px;
    color: #000000;
    padding-right: 20px; */
	text-align: center;
	/* font-family: Open Sans; */
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* padding-bottom: 5px; */
}

.contentDescriptionWrap_register {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 30px 150px 0px 150px;
}
.contentDescriptionWrap_noRegister {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.contentDescription {
	/* text-align: center;
    font-size: 18px;
    line-height: 20px;
    color: #000000; */
	text-align: center;
	/* font-family: Open Sans; */
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* padding: 30px 150px 0px 150px; */
}

.contentDescription {
	/* text-align: center;
    font-size: 18px;
    line-height: 20px;
    color: #000000; */
	text-align: center;
	/* font-family: Open Sans; */
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.contentDescriptionTop,
.contentDescriptionBottom {
	font-size: 18px;
	line-height: 20px;
	color: #000000;
}
.contentDescriptionTop {
	padding-top: 20px;
	text-align: center;
}
.contentDescriptionBottom {
	text-align: right;
	padding-right: 50px;
	/*padding-bottom:18px;*/
}
.logo {
	flex: 0 0 10%;
	padding: 63px 26px 20px 29px;
	margin-left: 20px;
}
.content {
	flex: 0 0 43%;
	flex-wrap: wrap;
	display: flex;
}

.contentRegister {
	display: flex;
	margin-left: 10px;
	width: 70%;
	flex-direction: column;
	justify-content: space-around;
}

.content h5 {
	font-family: var(--myMainFontRegular);
	font-size: 22px;
	line-height: 20px;
	color: #000000;
	text-align: center;
	padding-top: 15px;
	padding-right: 50px;
}

.buttons {
	flex: 0 0 31.5%;
	padding-bottom: 23px;
	padding-right: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	gap: 35px;
	flex-wrap: nowrap;
}

.buttons button {
	padding-bottom: 2px;
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	border-radius: 8px;
	text-transform: uppercase;
}
.buttons .buttonEnter {
	color: #3d3d3d;
	background: #ffffff;
	border: 1px solid #a3a1a1;
}
.buttons .buttonEnter:hover {
	color: #2b2a38;
	border: 1px solid #3d3d3d;
}
.buttons .registration {
	background: #3d3d3d;
	border: 0.5px solid #898c8f;
	color: #ffffff;
}
.buttons .registration:hover {
	background: #2b2a38;
	border: 0.5px solid #898c8f;
}
@media (max-width: 2200px) {
	.logo {
		flex: 0 0 274px;
		padding: 63px 0 20px 0;
	}
	.buttons .buttonEnter {
		width: 129px;
		height: 36px;
	}
	.buttons .registration {
		width: 160px;
		height: 36px;
	}
	.contentDescriptionAuthor {
		padding-right: 15px;
	}
}
@media (max-width: 1255px) {
	.logo {
		overflow: hidden;
		padding: 63px 0 20px 0;
	}
	.buttons {
		flex: 0 0 20.5%;
		padding-bottom: 23px;
		padding-right: 10px;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		gap: 10px;
	}
}
@media (max-width: 1105px) {
	.content {
		flex: 0 0 30%;
	}
	.content h5 {
		padding-top: 15px;
		text-align: center;
		padding-right: 0;
	}
	.contentDescriptionTop,
	.contentDescriptionBottom {
		font-size: 18px;
		color: #000000;
	}
	.contentDescriptionTop {
		padding-top: 20px;
		text-align: center;
	}
	.contentDescriptionBottom {
		text-align: center;
		padding-right: 50px;
		/*padding-bottom:18px;*/
	}
	.buttons {
		flex: 0 0 35.5%;
		padding-bottom: 23px;
		padding-right: 10px;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		gap: 10px;
	}
}
.boldText {
	font-weight: bold;
}
