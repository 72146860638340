.navContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #e5e7eb;
	overflow-y: auto;
	width: 100%;
}

.activeAll {
	width: 300px;
}

.tabButton {
	padding: 10px 15px;
	font-size: 18px;
	font-weight: 400;
	color: #727272;
	background: none;
	border: none;
	cursor: pointer;
	transition: color 0.2s ease-in-out;
	white-space: nowrap;
}

.activeTab {
	color: #00a9ce;
	border-bottom: 2px solid #00a9ce;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 1rem;
}

.resultFilterPanelViewButton {
	display: flex;
	gap: 25px;
}

.resultFilterPanelViewButton button svg {
	fill: #cecece;
}
.resultFilterPanelViewButton button svg:hover {
	fill: #b1b1b1;
}
.resultFilterPanelViewButton .active svg {
	fill: #00a9ce;
}

.isSticky {
	position: sticky;
	top: 0;
	width: 100%;
	background-color: white;
	left: 0;
	right: 0;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 0;
	z-index: 99999;
}

.sticky__block {
	position: sticky;
	width: 100%;
	left: 0;
	right: 0;
	width: 100%;
	gap: 24px;
	margin: 0 auto;
	padding-bottom: 0;
	z-index: 1;
	padding-left: 5px;
	padding-right: 0;
}

