.mobileGalleryContainer {
	display: flex;
	flex-direction: column;
	min-height: 100vmax;
	background: #2b2b2b;
}

.topTitle {
	display: flex;
	align-items: flex-start;
	margin: 40px 0;
	margin-left: 15px;
}

.mobileGalleryContainer .topTitle h1 {
	color: #fff;
	font-size: 16px;
	margin-left: 25px;
}

.galleryWrapper {
	padding-bottom: 150px;
}
