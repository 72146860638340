.personalCabinetContainer {
	display: flex;
	align-items: flex-end;
	caret-color: transparent;
	flex-direction: column;
}

.cabinetTopCard {
	padding: 29px;
	background: #2b2a38;
	display: flex;
	flex-direction: column;
	width: 388px;
}
.cabinetTopCard svg {
	cursor: pointer;
	fill: white;
}
.topCardInfo {
	display: flex;
	align-items: center;
}
.topCardInfo a {
	flex: 1 1 90%;
	overflow: hidden;
}
.topCardInfo_left {
	display: flex;
	align-items: center;
	gap: 15px;
}
.topCardInfo_left:hover .cardInfoUserInfo {
	color: rgba(255, 255, 255, 0.51);
}
.topCardInfo_left:hover .cardInfoSettings svg {
	fill: rgba(255, 255, 255, 0.51);
	color: rgba(255, 255, 255, 0.51);
}
.cardInfoAvatar {
	/*flex: 0 0 5%;*/
	/*margin-right: 17px;*/
}
.cardInfoUserInfo {
	display: flex;
	flex-direction: column;
	font-family: var(--myMainFontRegular);
	font-size: 12px;
	line-height: 16px;
	color: #ffffff;
}
.cardInfoUserInfo span:first-child {
	white-space: nowrap;
	overflow: hidden;
}
.cardInfoUserInfo:hover {
	color: rgba(255, 255, 255, 0.51);
}
.cardInfoSettings {
	/*flex: 0 0 5%;*/
	/*margin-right: 16px;*/
}
.cardInfoOut {
	flex: 1 1 10%;
	display: flex;
	justify-content: flex-end;
}
.cardInfoOut span {
	margin-right: 10px;
	font-size: 12px;
	line-height: 12px;
	text-decoration: underline;
	color: #ffffff;
	cursor: pointer;
}
.cardInfoOut:hover span {
	color: rgba(255, 255, 255, 0.51);
}
.cardInfoOut:hover svg {
	fill: rgba(255, 255, 255, 0.51);
}
.cardInfoOutSvg {
	padding-top: 2px;
}
.topCardButtons {
	display: flex;
	justify-content: space-between;
}
.topCardButtons button {
	margin-top: 15px;
	width: 150px;
	height: 30px;
	border: 1px solid #ffffff;
	border-radius: 5px;
	text-align: center;
}
.topCardButtons button:hover {
	border: 1px solid rgba(255, 255, 255, 0.5);
}
.topCardButtons a button {
	font-family: var(--myMainFontRegular);
	font-size: 12px;
	line-height: 12px;
	color: #ffffff;
}
/*.topCardButtons a button:hover {
    color:rgba(255, 255, 255, 0.5);
}*/
/*=========Nav Menu ==============*/
.cabinetNavMenu {
	padding: 23px 30px;
	background: #3d3d3d;
	width: 388px;
}
.navMenu {
	display: flex;
	flex-direction: column;
	gap: 14px;
}
.navMenuItem,
.navMenuItem a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	line-height: 14px;
	color: #ffffff;
}
.navMenuItemLeft {
	display: flex;
	align-items: center;
	gap: 10px;
}
/*==========Cabinet Select ========*/
.cabinetSelect {
	padding: 0 40px 0 30px;
	width: 100%;
	height: 55px;
	border-top: 1px solid #5f5f5f;
	border-bottom: 1px solid #5f5f5f;
	background: #3d3d3d;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.cabinetSelectTitle {
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	line-height: 14px;
	color: #ffffff;
}
.dropdownIndicator svg {
	stroke: #a6a6a6;
}
.cabinetSelectInfo:hover svg {
	stroke: white;
}
/*=============Settings ================*/
.cabinetSettings {
	padding: 0 40px 0 30px;
	width: 100%;
	height: 136px;
	background: #3d3d3d;
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	line-height: 14px;
	color: #ffffff;
}
.cabinetSettingsLanguage,
.cabinetSettingsCurrency {
	display: flex;
	justify-content: space-between;
	margin-top: 18px;
	align-items: center;
}
.cabinetSettingsButtons {
	display: flex;
	gap: 10px;
}
.cabinetSettingsButtons button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 20px;
	border: 1px solid #727272;
	border-radius: 5px;
	font-family: var(--myMainFontBold);
	font-size: 12px;
	color: #727272;
	line-height: 1;
}
.cabinetSettingsButtons button:hover {
	border: 1px solid #ffffff;
	color: #ffffff;
}
.cabinetSettingsButtons button.active {
	border: 1px solid #ffffff;
	color: #ffffff;
}
.cabinetSettingsButtons button.active:hover {
	border: 1px solid rgba(255, 255, 255, 0.5);
	color: rgba(255, 255, 255, 0.5);
}
.cabinetSettingsSound {
	display: flex;
	justify-content: space-between;
	/*align-items: center;*/
	margin-top: 18px;
}
/*.cabinetSettingsSound_sound {
    margin-top: 5px;
}*/
.switchContainer {
	position: relative;
	width: 40px;
}
.switchIconPlay {
	width: 16px;
	height: 16px;
	/*  margin: 4px 0 0 6px;*/
}
.switchIconPause {
	/*position: absolute;*/
	width: 16px;
	height: 16px;
	/*margin: 5px 0 0 5.5px;*/
}
