.formWrapper {
	min-width: 100%;
	position: absolute;
	top: 44px;
}
.mainSearchContainer {
	max-width: 1220px;
	width: 100%;
}
.inputBlock {
	width: 100%;
	height: 55px;
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 8px;
	position: relative;
	border: 1px solid #cecece;
}
.inputBlockInput {
	flex: 1 1 100%;
	padding-left: 23px;
	height: 100%;
}
.inputBlockInput input {
	width: 100%;
	height: 100%;
}
.inputBlock .inputBlockInput input::placeholder {
	color: #353434;
	opacity: 0.5;
}
.inputBlockLocation {
	flex: 0 0 360px;
}
.button {
	flex: 0 0 177px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0 8px 8px 0;
	background-color: #ffd74a;
	gap: 13px;
	color: #2b2a38;
	font-family: var(--myMainFontBold) sans-serif;
	font-size: 16px;
}
.button:hover {
	background-color: #ffe27c;
}
.popupBlock {
	position: relative;
}
.popupBlock.popupSearch {
	margin-top: 20px;
}
/*===========MOBILE===============*/
@media (max-width: 1024px) {
	.formWrapper {
		min-width: 100%;
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		background-color: #f3f3f3;
	}
	.mainSearchContainer {
		max-width: 1024px;
		width: 100%;
	}
	.inputBlock {
		/*padding: 0 15px;*/
		margin: 20px auto 0;
		max-width: 91.67%;
		height: 50px;
		display: flex;
		align-items: center;
		background: #ffffff;
		position: relative;
		border: initial;
	}
	.inputBlockInput {
		flex: 1 1 100%;
		display: flex;
		padding-left: 0;
		height: 50px;
	}
	.backArrowContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		cursor: pointer;
		border-radius: 0 8px 8px 0;
	}
	.inputBlockInput input {
		width: 100%;
		height: 100%;
		border-radius: 10px;
		margin-left: 6px;
		font-size: 14px;
	}
	.inputBlock .inputBlockInput input::placeholder {
		color: #353434;
		opacity: 0.5;
	}
	.inputBlockLocation {
		/* flex: 0 0 360px;*/
	}
	.changeLocationContainerMobile {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.changeLocationMobile {
		width: 200px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #d9d9d947;
		border-radius: 4px;
		color: #727272;
		font-size: 16px;
		margin: 2px 0;
	}
}
