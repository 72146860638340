.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.1);
	z-index: 70;
	display: flex;
	align-items: center;
	justify-content: center;
}
.container {
	padding: 25px 38px 28px 39px;
	width: 400px;
	min-height: 200px;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	background-color: #fff;
}
.title {
	font-family:
		var(--myMainFontBold) "open-sans",
		serif;
	font-size: 18px;
	text-align: center;
}
.continue {
	margin-top: 10px;
	font-family:
		var(--myMainFontBold) "open-sans",
		serif;
	font-size: 18px;
	text-align: center;
}
.logoContainer {
	margin-top: 10px;
	display: flex;
	justify-content: center;
}
.buttonsContainer {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	gap: 10px;
}
.buttonsContainer .button {
	width: 150px;
	height: 45px;
	background-color: #ffdd66;
	font-size: 14px;
	font-family:
		var(--myMainFontBold) "open-sans",
		serif;
	font-weight: 600;
	border-radius: 5px;
}
.buttonsContainer .button:hover {
	background-color: #ffe898;
}
