.topNavigateContainer {
	display: flex;
	gap: 19px;
	align-items: center;
	font-size: 14px;
	line-height: 10px;
	text-transform: uppercase;
	color: #3d3d3d;
}
.topNavigateContainer {
	margin-bottom: 15px;
}
