.menu {
	margin-top: 10px;
	font-family: var(--myMainFontRegular) open-sans sans-serif;
	font-size: 16px;
	color: #3d3d3d;
	background-color: #fdfdfe;
}
.menuItem {
	height: 52px;
}
.menuItem:hover {
}
.menuItem a {
	display: flex;
	align-items: center;
	gap: 4px;
}
/*.menuItem:nth-child(2) {
    padding-bottom: 65px;
    margin-bottom: 15px;
    border-bottom:1px solid #DCDCDC;
}*/
.mobileBarContainer {
	margin-left: 7px;
	margin-bottom: 100px;
}
.mobileBarContainer .subMenu:first-child {
	padding-top: 30px;
}

.subMenuItem {
	max-height: 41px;
	/* display: flex; */
	/* align-items: center; */
	/* width: 100%; */
}
.navMenuItemExit {
	/*padding-top: 5px;*/
	border-top: 1px solid #dcdcdc;
}
.subMenu .subMenuItem {
	/* height: 48px;*/
}
.suffixStyle {
	font-family: var(--myMainFontRegular) open-sans sans-serif;
	font-size: 14px;
	color: #7d7d7d;
}
.subMenuUl {
	margin-right: 37px;
}
.subMenuUl li {
	border-bottom: 1px solid #dcdcdc;
}

.balanceWrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 25px 36px 25px 19px;
	color: #7d7d7d;
	font-size: 12px;
	height: 30px;
}

.balanceLeftBlock {
	display: flex;
	align-items: center;
}

.balanceTitle {
	padding-left: 12px;
	font-size: 16px;
	color: #3d3d3d;
}

.balanceValue {
	font-size: 16px;
	color: #7d7d7d;
}

.navMenuItem .businessPage {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.navMenuItem .businessPage {
	height: auto;
}

.navMenuItem .status {
	width: 100%;
	font-size: 12px;
	font-weight: 400;
	font-family: var(--myMainFontRegular) open-sans sans-serif;
	padding: 0 28px 0 70px;
}

.active {
	color: green;
}

.pending {
	color: #00a9ce;
}

.rejected {
	color: #ff5b00;
}

.inactive {
	color: gray;
}

.default {
	color: black;
}

.navMenuItemLeft {
	display: flex;
	align-items: center;
	gap: 19px;
}
