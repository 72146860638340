.modernButtonContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 222px;
	height: 60px;
	background: #fffdfd;
	border: 1px solid #dadada;
	border-radius: 5px;
	cursor: pointer;
}
.modernButtonContainer button {
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase !important;
	color: #727272 !important;
}
.modernButtonContainer button svg {
	margin-left: 26px;
}
@media (max-width: 1280px) {
	.modernButtonContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		/*width: 222px;*/
		width: 100%;
		height: 60px;
		background: #fffdfd;
		border: 1px solid #dadada;
		border-radius: 5px;
	}
}
@media (max-width: 1025px) {
	.modernButtonContainer button {
		font-size: 14px;
	}
}
