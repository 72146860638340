.container {
	position: relative;
}
.cloudContainer {
	position: absolute;
	left: 45px;
}
.textInsideCloud {
	position: absolute;
	top: 50px;
	left: 50px;
	text-align: center;
	color: #00a9ce;
	font-size: 16px;
	font-family: Comfortaa;
}
.logoContainer {
	top: 156px;
	position: absolute;
}
