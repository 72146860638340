.cardLocationDeliveryInfo {
	padding-top: 15px;
}
.bottomHiddenLocation {
	color: #3d3d3d;
}
.postOffice {
	padding-top: 14px;
	font-size: 12px;
	color: #000000;
}
.postOffice span {
	color: #727272;
}
.arrivalDate {
	padding-top: 20px;
	font-size: 12px;
	color: #000000;
}
.arrivalDate span {
	color: #727272;
}
.deliveryNumber {
	padding-top: 17px;
	padding-bottom: 23px;
	font-size: 12px;
	color: #000000;
	border-bottom: 1px solid #9d9d9d;
}
.deliveryNumber span {
	color: #727272;
}
