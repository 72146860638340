.wrapper {
	width: 100%;
	background-color: #ffffff;
}

.swiperSlide {
	width: 100%;
	/*overflow-y: auto;*/
	/*padding-left: 15px; */
	background-color: #ffffff;
}

.outputBoardAdContainer {
	max-width: 1500px;
	/*контейнер больше на размер отступов безопасности */
	margin: 0 auto;
	padding: 0 20px;
	caret-color: transparent;
}

.backArrowContainer {
	max-width: 240px;
	margin-top: 30px;
	display: flex;
	align-items: center;
	gap: 25px;
	cursor: pointer;
}

.homePageGreyFon {
	display: block;
	position: relative;
	top: 0;
	background-color: #fafafa;
	width: 100%;
	height: 100%;
}

.outputBoardAdContainer h1 {
	margin-top: 12px /*https://app.clickup.com/t/86933gzbe*/;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 28px;
	line-height: 28px;
	color: #2b2a38;
}

.breadCrumbs {
	/*padding-top: 20px;*/
}

.adContent {
	width: 100%;
	margin-top: 20px; /*https://app.clickup.com/t/86933gzbe */
	/*margin-top: 5px;*/
	display: flex;
	gap: 29px;
}

.adContentLeft {
	flex: 0 0 738px;
	/*flex: 1 1 49%;*/
	overflow: hidden;
}

.adContentLeftAdvertising {
	width: 728px;
	height: 90px;
	margin-top: 20px;
}

.adContentRight {
	/*flex: 0 0 738px;*/
	flex: 1 1 49%;
}

/*===============Tabs start===============*/
.tabsButtons {
	/*margin-top: 34px;*/
	display: flex;
	justify-content: space-between;
	width: 100%;
	/*background-color: #f5f5f5;*/
	/*padding: 15px;*/
	border-bottom: 1px solid #8d8d8d; /*09.09.2024*/
}

.tabsButtons button {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 16px;
	line-height: 150%;
	text-transform: uppercase;
	color: #8d8d8d;
	margin-right: 18px;
	border-bottom: 1px solid transparent;
}

.tabsButtons button.active {
	color: #1a1a1a;
	border-color: #1a1a1a;
}

.tabsButtons .stateInfo {
	color: #8d8d8d;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 14px;
	line-height: 150%; /* 21px */
}

.tabsButtons button:hover {
	color: #626262;
}

/*===============Tabs end===============*/

/*===============Description start===============*/
.adContentRightInfo {
	/*height: 470px;*/
	min-height: 470px;
}

.adContentRightInfo h2 {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 28px;
	line-height: 38px;
	color: #000000;
}

.descriptionText {
	padding: 0 15px;
}

.adContentRightInfo .descriptionText {
	width: 100%;
	max-width: 693px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.adContentRightInfo .descriptionText span {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 16px;
	line-height: 150%;
	color: #1a1a1a;
	overflow: hidden;
	text-overflow: ellipsis;
	/*margin-top: 19px;*/
	/* margin-bottom: 35px;*/
}

.adContentRightInfo span {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 14px;
	line-height: 17px;
	color: #727272;
}

.adDescriptionDate {
	/*display: inline-block;
       margin-top: 20px;*/
	color: rgba(0, 0, 0, 1);
}

/*===============Description end===============*/

/*===============Characteristics start===============*/

.characteristics h5,
.delivery h5 {
	/*margin-top: 35px;*/
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 28px;
	line-height: 38px;
	color: #000000;
}

.characteristicsTable {
	margin-top: 35px;
}

.characteristicsTable div {
	display: grid;
	grid-template-columns: 1fr 2fr;
	column-gap: 60px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 16px;
	line-height: 150%;
	color: #727272;
}

.characteristicsTable div .characteristicsElement:nth-child(2n) {
	color: #1a1a1a;
}

/*===============Characteristics end===============*/

/*===============Bottom panel start===============*/
.adContentBottomPanel {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	padding-bottom: 50px;
	padding-top: 6px;
	border-top: 1px solid #dcdcdc;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 14px;
	/*line-height: 17px;*/
	color: #727272;
}

.publicationTime {
	font-family: var(--myMainFontRegular) sans-serif;
	color: #727272;
}

.publicationTime span {
	color: #1a1a1a;
}

.allRightInfo {
	display: flex;
	align-items: center;
	gap: 26px;
}

.allRightInfo .rightInfoButton {
	display: flex;
	align-items: center;
	gap: 8px;
	text-decoration: underline;
	cursor: pointer;
}
.allRightInfo .rightInfoButton:hover {
	color: #565656;
	text-decoration: none;
}

.allRightInfo .commentIcon:hover svg path {
	fill: #565656;
}

.allRightInfo svg {
	fill: #e0e0e0;
}

.allRightInfo .warningIcon:hover svg {
	fill: #ff5959;
}

.adsBlock {
	margin: 70px auto 20px auto;
	width: 970px;
	/*height: 250px;*/
}
.rightInfoItem {
	cursor: pointer;
}
/*===============Bottom panel end===============*/
/*========Scroll Bar start ===================*/
.scrollBarContainer {
	margin-top: 22px;
	padding-bottom: 15px;
	background-color: #ffffff;
}
.buttons {
}
/*.swiperSlide,
.hide {
    transition: opacity 0.5s ease;
}*/

/* Стили для .hide */
/*.hide {
    opacity: 0;
    visibility: hidden;
}*/

/* Стили для .swiperSlide */
.swiperSlide {
	opacity: 1;
	visibility: visible;
}

.adDescription {
	/*width:573px;*/
	width: 100%;
	height: 100%;

	/*height: 100%;*/
}

/*Mobile*/
.characteristicsTableMobile {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 17px;
}

.characteristicsTableItemWrapMobile {
	display: flex;
	gap: 0 60px;
}

.characteristicsTableItemNameMobile {
	width: 50%;
}

.characteristicsTableItemValueMobile {
	width: 50%;
}

.commentSectionWrap {
	padding: 20px 15px 0;
}

/*========Scroll Bar end ===================*/
@media (max-width: 1401px) {
	.outputBoardAdContainer {
		max-width: 1280px;
	}

	.adContent {
		gap: 16px;
	}
}

@media (max-width: 1025px) {
	.mobileAdContainer {
		/*margin-bottom: 150px;*/
		margin-bottom: 100px;
	}

	.mobileGalleryContainer {
		position: relative;
	}

	.backArrow {
		position: absolute;
		top: 7px;
		left: 0;
		z-index: 5;
		padding: 10px;
	}

	.backArrow span {
		display: flex;
		align-items: center;
		padding: 4px 9px;
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.2);
	}

	.topRelativeWrapper {
		position: relative;
		display: block;
	}

	.topTitleContainer {
		position: absolute;
		padding: 15px 15px 20px 15px;
		width: 100%;
		height: 93px;
		top: 95%;
		background: #ffffff;
		border-radius: 15px 15px 0 0;
	}

	.characteristics,
	.delivery {
		padding-left: 15px;
	}

	.titlePrice {
		position: relative;
		display: flex;
		align-items: center;
	}

	.titlePrice .price {
		font-family: var(--myMainFontMedium) sans-serif;
		font-size: 18px;
		line-height: 18px;
		color: #464646;
	}

	.titlePrice .orangeHeart {
		position: absolute;
		right: -9px;
		top: -35px;
	}

	.titleName {
		margin-top: 15px;
		display: flex;
		justify-content: space-between;
	}

	.titleName .name {
		font-family: var(--myMainFontRegular) sans-serif;
		font-size: 16px;
		line-height: 16px;
		text-transform: uppercase;
		color: #2b2a38;
	}

	.blackButtonContainer {
		background: #ffffff;
		padding-top: 25px;
	}

	.blackButtonContainer button {
		padding-bottom: 2px;
		width: 100%;
		height: 35px;
		background: #3d3d3d;
		border-radius: 4px;
		font-size: 14px;
		/*line-height: 14px;*/
		color: #ffffff;
	}

	.topTitle_infoPanelBottom {
		padding: 10px 18px 10px 15px;
		display: flex;
		justify-content: space-between;
		margin-top: 80px;
		font-family: var(--myMainFontRegular) sans-serif;
		font-size: 14px;
		line-height: 17px;
		color: #8c8c8c;
		background: #ffffff;
		border-radius: 0 0 15px 15px;
	}

	.scrollBarMobileContainer {
		/*height: 400px;*/
		max-height: 400px;
		/*overflow: visible;*/
		overflow: hidden;
		overflow-y: scroll;
	}

	.tabsButtons {
		display: flex;
		gap: 8px;
		flex-wrap: wrap;
		/*margin-top: 190px;*/
		width: 100%;
		padding: 15px;
		border-bottom: none;
	}

	.tabsButtons button {
		font-family: var(--myMainFontRegular) sans-serif;
		font-size: 12px;
		line-height: 18px;
		text-transform: uppercase;
		margin-right: 0;
		/*color: #000000;*/
	}
	.tabsButtons button.active {
		color: #000000;
		border-color: transparent;
	}

	.scrollBarContainer {
		height: 95%;
	}

	.characteristicsTable {
		display: grid;
		min-height: 100%;
		grid-template-columns: 1fr 2fr;
		column-gap: 60px;
		margin-top: 27px;
		font-family: var(--myMainFontRegular) sans-serif;
		font-size: 16px;
		line-height: 150%;
		color: #727272;
	}

	.adContentRightTabs .tabsButtons {
		padding: 10px 15px;
	}

	.swiperSlide {
		/*height: 100%;*/
		padding-top: 10px;
		/*padding-left: 15px;*/
		background-color: #ffffff;
	}

	/* .scrollBarContainer {
          margin-top: 0;
          overflow: auto;
      } */
	.scrollBarContainer::-webkit-scrollbar {
		width: 0; /* для вертикального скроллбару */
		height: 0; /* для горизонтального скроллбару */
	}

	.scrollBarContainer {
		margin-top: 0;
		overflow: auto;
	}

	.adDescription {
		padding: 20px 15px 0 15px;
		background-color: #fafafa;
	}

	.adDescription p {
		white-space: normal;

		/*width: 100px;*/
	}

	.adDescriptionDate {
		display: inline-block;
		margin-top: 20px;
		color: #727272;
	}

	.warningIcon {
		padding: 0 15px;
		display: flex;
		align-items: center;
		height: 40px;
		background-color: rgba(250, 250, 250, 1);
		/*margin: 15px 0 11px 15px;*/
		font-size: 14px;
		color: #727272;
	}

	.warningIcon svg {
		margin-top: 3px;
		margin-right: 8px;
		fill: #e0e0e0;
	}

	.warningIcon:hover svg {
		fill: #ff5959;
	}

	.advertisingContainer {
		/*margin-top:50px;*/
		margin: 50px auto 0;
		width: 360px;
		height: 300px;
	}

	.withSeeContainer {
		background-color: #fafafa;
		margin-top: 35px;
	}

	.advertisingContainerLittle {
		margin: 0 auto;
		width: 95%;
		height: 115px;
		background: #cdcdcd;
		border-radius: 5px;
	}

	.mainCategoryAds {
		/* padding-top: 10px;*/
		/*margin: 40px auto;*/
		width: 360px;
		/*background-color: #00A9CE;
        height: 300px;*/
	}
}
