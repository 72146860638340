.list {
  font-size: inherit;
  line-height: inherit;
  color: #777777;
}
.list.mobile:not(.card) {
  background: #F6F4F4;
  padding: 21px 39px;
}
.list li {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.list li:not(:last-child) {
  margin-bottom: 20px;
}
.list.card li:not(:last-child) {
  margin-bottom: 12px;
}
.list li span b {
  color: #000000;
  white-space: nowrap;
  font-weight: normal;
}
.list li span b.popular {
	font-family: var(--myMainFontBold);
}
.list li svg {
  min-height: 18px;
  max-height: 18px;
  min-width: 18px;
  max-width: 18px;
  opacity: 0.7;
}
.list.card li svg {
  min-width: 14px;
  max-width: 14px;
}
.list li .cardGrid {
  font-size: 16px;
}
.list li .text {
  overflow: hidden;
  text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
}

.vacancyType {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  font-size: inherit;
  line-height: inherit;
}
.vacancyType.card {
  flex-grow: 0;
}
.vacancyType.mobile {
  flex-grow: 0;
}
.vacancyType.card svg {
  min-width: 14px;
  max-width: 14px;
}