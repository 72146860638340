.containerContacts {
	border-top: 1px solid #dcdcdc;
}

.button {
	margin-left: auto;
	display: flex;
	text-align: center;
	padding: 10px 25px;
	border-radius: 5px;
	border: 1px solid #727272;
	color: #727272;
	text-transform: uppercase;
}

.addPhone {
	background: none;
	border: none;
	color: #00a9ce;
	font-size: 18px;
	font-weight: 400;
}

.button:hover {
	border: 1px solid #c4c4c4;
	color: #444444;
}

.button.touched {
	border-radius: 5px;
	background: #fd6;
	border: inherit;
	color: #3d3d3d;
}

.button.touched:hover {
	background: #ffe898;
}

.block {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	background-color: white;
	padding: 25px 15px;
}

.container {
	display: flex;
	width: 100%;
}

.container .top {
	display: flex;
	align-items: flex-start;
	width: 100%;
}

.container .title {
	font-size: 16px;
	font-weight: 800;
	width: 200px;
}

.container .close {
	cursor: pointer;
	color: #939393;
	font-size: 14px;
	font-weight: 400;
}

.container h3 {
	color: #6f6f6f;
	font-size: 14px;
	font-weight: 400;
	margin-left: 20px;
}

.wrapper {
	width: 100%;
	background-color: #f2f2f2;
}
.error {
	color: red;
	font-size: 14px;
}
.error input {
	border-bottom: 2px solid red !important;
}
.error textarea {
	border-bottom: 2px solid red !important;
}

.priceError {
	margin-top: 5px;
	display: block;
}
.createADContainer {
	max-width: 1280px;
	margin: 70px auto;
}
.createADContainer h1 {
	padding: 24px 10px 17px;
	color: #3d3d3d;
	font-family: var(--myMainFontBold) sans-serif;
	font-size: 21px;
}
.formContainer {
	padding: 31px 32px 48px 31px;
	border-radius: 4px;
	background: #ffffff;
}
.categoryChangeInput {
	width: 514px;
	height: 40px;
	border-radius: 4px;
	border: 1px solid #e7e7e7;
	background: #fff;
	display: flex;
	align-items: center;
	padding-left: 26px;
	color: #727272;
}
.form {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 30px;
}
.formElement {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 31px;
	width: 100%;
	color: #2b2a38;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 18px;
	margin-bottom: 30px;
	scroll-margin-top: 150px;
}
.nameSide {
	width: 250px;
	font-size: 16px;
	font-weight: 700;
	text-align: left;
	color: #2b2a38;
}

.stateFieldEl {
	display: block;

	/*gap: 1px;*/
}
.stateField {
	width: 119px;
}

.fieldSide {
	/* width: 514px; */
	max-width: 100%;
}
.fieldSide input {
	width: 514px;
	max-width: 100%;
	padding-left: 26px;
	padding-right: 26px;
	height: 40px;
	border-radius: 4px;
	border: 1px solid #e7e7e7;
	background: #ffffff;
	color: #727272;
	font-size: 16px;
	text-overflow: ellipsis;
}
.autoSelect {
	/*height: 26px;*/
}
.fieldSide.select input {
	height: initial;
}
.fieldSide.error input {
	border-bottom-color: red;
}
.fieldSide input:focus,
textarea:focus {
	border-bottom: 2px solid #00a9ce;
}

.required {
	color: red;
	vertical-align: center;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 30px;
	margin-top: 50px;
}
.buttons button {
	text-align: center;
	width: 187px;
	height: 40px;
	border-radius: 5px;
	border: 1px solid #727272;
	color: #727272;
	text-transform: uppercase;
}
.buttons button:hover {
	border: 1px solid #c4c4c4;
	color: #444444;
}
.buttons button.touched {
	border-radius: 5px;
	background: #fd6;
	border: inherit;
	color: #3d3d3d;
}
.buttons button.touched:hover {
	background: #ffe898;
}
.descriptionFields {
	padding: 14px 0;
	text-align: right;
}
/*Select style*/
.dropdownArrow {
	/*margin-bottom: 12px;*/
	stroke: #797979;
}
/*Drop Zone*/
.dropZoneContainer {
	width: 100%;
	/*max-width: 1294px;*/
	/*min-height: 215px;*/
	border: 2px solid rgb(236, 248, 251);
	border-radius: 4px;
	background: #f2fcfe;
	/*background: #f2fcfe;*/
	overflow: hidden;
	position: relative;
	/*cursor: pointer;*/
}
.dropZoneTop {
	min-height: 130px;
	padding: 15px 25px 25px 35px;
	border-radius: 4px;
	cursor: pointer;
	/*border: 2px solid rgb(236, 248, 251);
    background: #f2fcfe;*/
}
.checkPhotoSvgContainer {
	position: absolute;
	top: 35px;
	display: flex;
	justify-content: center;
}
.svgDescription {
	width: 60px;
	text-align: center;
	position: absolute;
	top: 80px;
	color: #939393;
	font-size: 14px;
	line-height: normal;
}
.dropZoneContainer input {
	min-width: 100%;
	min-height: 100%;
}
.photoDescription {
	margin: 20px auto;
	width: 100%;
	text-align: center;
	color: #939393;
	font-size: 16px;
	line-height: 150%; /* 27px */
}
.photo .nameSide {
	width: 100%;
}
.adTitle input {
	color: #262424;
}
.photo .photoDescription {
	margin-left: 30px;
}
.imageList {
	padding-left: 22px;
	/* width: 100%;*/
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
}
.imageItem {
	width: 130px;
	height: 160px;
	overflow: hidden;
}
.imageContainer {
	width: 130px;
	height: 130px;
	overflow: hidden;
}
.imageContainer img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.imageItem button {
	width: 100%;
	height: 20px;
	text-align: center;
}
.dropZoneInfo {
	width: 100%;
	height: 155px;
	padding-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #939393;
	font-size: 18px;
	line-height: 150%; /* 27px */
}
.deleteButton {
	margin-top: 5px;
	color: #939393;
	text-align: center;
	font-size: 14px;
	line-height: 150%; /* 21px */
}
/*========================*/

.descriptionField {
	display: block;
}
.descriptionField .fieldSide {
	width: 100%;
	margin-top: 15px;
	max-width: 937px;
}
.descriptionField .fieldSide textarea {
	max-width: 100%;
	width: 100%;
	height: 200px;
	padding: 20px 0 0 20px;
	border-radius: 4px;
	border: 1px solid #dcdcdc;
	background: #ffffff;
	/*font-size: 16px;*/
	font-size: 18px;
	color: #939393;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 27px */
}
.contactNameInput {
	max-width: 300px;
}
.descriptionOptions {
	width: 100%;

	display: flex;
	justify-content: space-between;
}
.descriptionOptions_info {
	color: red;
	font-size: 18px;
}
.descriptionOptions_Size {
	color: #939393;
	font-size: 18px;
}
.stateButtons {
	margin-top: 20px;
	display: flex;
	gap: 32px;
}
.fieldSide button {
	background: none;
	border: none;
	color: #00a9ce;
	font-size: 18px;
	font-weight: 400;
	font-family: var(--myMainFontRegular) sans-serif;
	line-height: normal;
}
.fieldSide button.active {
	background: #00cefc;
	color: #ffffff;
}
.priceField,
.quantityStock {
	display: block;
}
.priceField .fieldSide {
	margin-top: 11px;
}
.priceField .fieldSide input {
	width: 143px;
	height: 40px;
	text-align: right;
	padding-right: 8px;
	border-radius: 4px;
	/* border: 1px solid  #E7E7E7;*/
	background: #ffffff;
}

.priceField .fieldSide select {
	margin-left: 20px;
	color: #727272;
	font-size: 18px;
}
.priceButtons {
	margin-top: 20px;
}
/*.priceButtons button {
    width: 140px;
    height: 30px;
}*/
/*====Checkbox====*/
.createAdCheckboxContainer {
	display: flex;
	align-items: center;
	position: relative;
}
.createAdCheckbox {
	opacity: 0;
	position: absolute;
	z-index: -1;
}
.createAdCheckbox + label {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	column-gap: 10px;
	text-decoration: none;
}
.createAdCheckbox + label::before {
	content: "";
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	width: 20px;
	height: 20px;
	background-color: #ffffff;
	border: 1px solid #727272;
	border-radius: 3px;
	cursor: pointer;
}
.createAdCheckbox:checked + label::before {
	content: url("/src/images/checkBox.jpg");
	border: 1px solid #00a9ce;
	padding-bottom: 3px;
}
/*=========================*/
.numberPhoneMessenger {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 15px;
	/*flex-wrap: wrap;*/
}

.phoneInputWrapper {
	margin-bottom: 20px;
}

/*======Auto Settings===========*/
.autoSettings {
	width: 354px;
	height: 130px;
	padding: 11px 11px 14px 17px;
	border-radius: 5px;
	background: #f8f8f8;
}
.autoSettings:hover {
	background: #f3f3f3;
}
.autoContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.autoContainer_top,
.autoContainer_bottom,
.autoContainer {
	display: flex;
	justify-content: space-between;
}
.autoContainer_bottom {
	align-items: center;
}
.switchContainer {
	display: flex;
	gap: 10px;
}

.switchContainer svg {
	margin-top: 0;
}
.autoContainer_Name {
	color: #000;
	font-size: 16px;
}
.onOffTextHandler {
	color: #939393;
	font-size: 14px;
}
.onOffTextHandler.active {
	color: #33a54a;
}
.autoContainer_Bottom_Name {
	color: #3d3d3d;
	font-size: 14px;
}
.autoContainer_info {
	max-height: 26px;
	margin-top: 20px;
	color: #727272;
	font-size: 14px;
}
.filtersList {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 23px;
}
.filtersList_List {
	display: flex;
	flex-direction: column;
	gap: 23px;
	/*width: 100%;*/ /*Rost це ти додав чи я?*/
}
/*====Select day start  ====*/
.selectContainer {
	height: 26px;
}
.selectContainer_Select {
	width: 100px;
	height: 26px;
	border-radius: 4px;
	border: 1px solid #e7e7e7;
	background: #fff;
	padding-left: 12px;
	color: #727272;
	font-size: 12px;
	line-height: normal;
}
.selectContainer_Select option {
	border: 1px solid #e7e7e7;
}
/*====Select day end  ======*/
.switchIconPlay {
	position: absolute;
	top: -0.5px;
	right: -17px;
	z-index: 10;
}
.switchIconPause {
	position: absolute;
	top: -3px;
	right: 18px;
	z-index: 10;
}
.fieldSide textarea {
	caret-color: initial;
}
.fieldSide textarea:focus {
	border-bottom: 2px solid #00a9ce;
}
.fieldSide textarea:focus + .descriptionOptions .descriptionOptions_Size {
	color: #00a9ce;
}

.switchContainer svg {
	margin-top: 0;
}
.switchIconPlay {
	position: absolute;
	top: -0.5px;
	right: -17px;
	z-index: 10;
}
.switchIconPause {
	position: absolute;
	top: -3px;
	right: 18px;
	z-index: 10;
}

.footer {
	display: flex;
	align-items: center;
}

.phones {
	display: flex;
	flex-direction: column;
}

@media (max-width: 1024px) {
	.autoSettings {
		width: 336px;
	}
	.filtersList_List {
		gap: 15px;
	}
	.descriptionOptions_Size {
		font-size: 0.9em;
		padding: 0 5px;
	}
	.numberPhoneMessenger {
		row-gap: 15px;
	}
	.numberPhoneMessenger {
		flex-wrap: wrap;
	}

	.fieldSide input {
		width: 365px;
	}

	.formElement {
		gap: 15px;
	}
	.formElement:nth-child(8) {
		padding-top: 20px;
		border-top: 1px solid #dcdcdc;
		border-bottom: none;
		padding-bottom: 0;
	}
	.descriptionField .fieldSide textarea {
		font-size: 0.8em;
	}

	.container .top {
		flex-wrap: wrap;
		gap: 15px;
	}

	.container .close {
		display: none;
	}

	.button {
		display: inline-block;
		width: 100%;
		text-align: center;
		font-size: 16px;
	}

	.nameSide {
		font-size: 20px;
	}

	.footer {
		flex-direction: column-reverse;
		gap: 20px;
	}
}
