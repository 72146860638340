.cabinetContent_CheckBoxPanel {
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: space-between;
	padding: 0 23px 0 0;
	align-items: center;
	background-color: #f8f8f8;
}
.cabinetContent_CheckBoxPanel_Column {
	display: flex;
	align-items: center;
	gap: 21px;
}
.cabinetContent_CheckBoxPanel_Column.buttonsRight button svg {
	fill: #cecece;
}
.cabinetContent_CheckBoxPanel_Column.buttonsRight button svg:hover {
	fill: #b1b1b1;
}
.cabinetContent_CheckBoxPanel_Column.buttonsRight .active svg {
	fill: #00a9ce;
}
.clearAllButton {
	display: flex;
	align-items: center;
	gap: 10px;
	color: #727272;
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	line-height: 21px;
	text-decoration-line: underline;
}
/*.searchContainer {
    position: relative;
}*/
/*.searchContainerInput {
    width: 160px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #E7E7E7;
    background: #FFF;
    padding-left: 15px;
}
.searchLupa {
    position: absolute;
    top: 2px;
    right: 10px;
    cursor: pointer;
}*/
.cabinetContent_CardTemplate {
	position: relative;
	min-width: 100%;
	display: flex;
	padding: 0 5px;
	gap: 1px;
	background-color: #fff;
	flex-wrap: wrap;
	align-content: flex-start;
	z-index: 0;
}
/*.cabinetContent_CardTemplateFon {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #E9E9E9;
    z-index: -1;
}*/
.cabinetContent_CardTemplate .cardItem:not(:nth-child(4n)) .border {
	border-right: 1px solid #e9e9e9;
}
.cabinetContent_CardTemplate .cardItem .border,
.borderRow {
	border-bottom: 1px solid #e9e9e9;
}

.paginatorContainer {
	margin-top: 40px;
	padding-bottom: 40px;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 50px;
}
