.wrapper {
	background-color: white;
}

.container {
	max-width: 1140px;
	margin: 0 auto;
	padding: 0 20px;
}

.bannerContainer {
	position: relative;
	border-radius: 2px;
}

.titleContainer {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.titleContainer span {
	font-size: 12px;
	color: #3d3d3d;
}

.banner {
	width: 100%;
	max-width: 100%;
	cursor: pointer;
	object-fit: cover;
	height: 270px;
	max-height: 270px;
	border-radius: 2px;
}

.bannerText {
	position: absolute;
	text-align: center;
	width: 100%;
	top: 55%;
	/* bottom: 80%; */
}

.bannerText .bannerTextDownload,
.bannerText .bannerTextRecommend {
	font-size: 18px;
	color: #3d3d3d;
	font-weight: 400;
}

.needHelp {
	background-color: #e7e7e7;
	padding: 10px 15px;
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 18px;
	font-weight: 400;
}

.mainBanner {
	background-color: #2b2a3866;
	padding: 10px 15px;
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 18px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}

.needHelp a {
	color: #00a9ce;
	text-decoration: solid;
}

.bannerInfoSeller {
	display: flex;
	flex-direction: column;
	gap: 10px;
	position: absolute;
	right: 20px;
	top: 20px;
}

.bannerInfoSeller p {
	display: flex;
	align-items: center;
	gap: 5px;
	border-radius: 4px;
	background-color: #2b2a3866;
	color: white;
	font-size: 14px;
	font-weight: 400;
	padding: 10px;
}

.bannerInfoSeller p a {
	color: white;
}

.uploadingOverlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	color: #333;
	z-index: 10;
	border-radius: 2px;
}

.button {
	display: inline-flex;
	width: max-content;
	text-align: center;
	padding: 10px 25px;
	border-radius: 5px;
	border: 1px solid #727272;
	color: #727272;
	text-transform: uppercase;
}

.sendButton {
	display: inline-flex;
	width: max-content;
	text-align: center;
	padding: 10px 25px;
	border-radius: 5px;
	border: 1px solid #727272;
	color: #727272;
	text-transform: uppercase;
	margin-top: 25px;
}

.inputContainer {
	display: flex;
	align-items: center;
	gap: 15px;
}

.inputContainer h1 {
	font-size: 18px;
}

.inputContainer input {
	width: 322px;
	padding: 10px 20px;
	background: #ffffff;
	border: 1px solid #c8c8c8;
	border-radius: 5px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 16px;
	text-align: left;
	color: #727272;
	padding-left: 10px;
}

.actionsContainer {
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin: 15px 20px;
}

.bannerWrapper {
	position: relative;
	display: block;
}

.banner {
	width: 100%;
	height: auto;
	display: block;
}

.sloganOverlay {
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translateX(-50%);
	width: 450px;
	color: #fff;
	border-radius: 5px;
	text-align: center;
	max-width: 90%;
	font-size: 40px;
	font-weight: 700;
	line-height: 40px;
}

.sloganContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
}

.sloganText {
	cursor: pointer;
	margin: 0;
	font-size: 40px;
	color: #fff;
}

.sloganIcon {
	flex: none;
	width: 20px;
	height: 20px;
	fill: #727272;
	cursor: pointer;
}

.sloganInput {
	width: 100%;
	padding: 5px;
	font-size: 1em;
	border: none;
	border-bottom: 1px solid #ccc;
	background: transparent;
	color: white;
	outline: none;
}

.sloganInput:focus {
	border-bottom: 1px solid #fff;
}

.statusPageText {
	font-size: 16px;
}

.adverts {
	width: auto;
	height: auto;

	@media screen and (max-width: 1280px) {
		.banner {
			max-height: 200px;
		}
	}
	@media screen and (max-width: 1024px) {
		.needHelp {
			display: none;
		}

		.bannerTextDownload {
			display: none;
		}

		.bannerText .bannerTextRecommend {
			font-size: 16px;
		}

		.wrapper {
			background: #f8f8f8;
		}

		.sloganText {
			font-size: 26px;
		}

		.bannerInfoSeller {
			display: none;
		}

		.inputContainer {
			flex-wrap: wrap;
		}

		.banner {
			height: 250px;
		}

		.button {
			width: 100%;
			justify-content: center;
		}

		.inputContainer input {
			width: 100%;
		}

		.isSticky {
			z-index: 99999999;
		}

		.adverts {
			width: auto;
			height: 250px;
		}
	}
}
