.newAdvertsContainer {
	padding-top: 43px;
	width: 100%;
}

.bestsellersContainerTopPanel {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* margin-top:60px; */
	padding-bottom: 25px;
	padding-right: 2px;
	flex-wrap: wrap;
}

.newAdvertsContainer h5 {
	margin-left: 10px;
	padding-bottom: 15px;
	font-family: var(--myMainFontMedium);
	font-size: 22px;
	line-height: 30px;
	color: #2b2a38;
}

.newAdvertsContent {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, minmax(318px, 1fr));
	/*display: flex;
    flex-wrap: wrap;*/
	gap: 1px;
}
@media (max-width: 1280px) {
	.newAdvertsContent {
		grid-template-columns: repeat(4, minmax(248px, 1fr));
	}
}
.newAdvertsContentMobile {
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
}
.newAdvertsBottomPanel {
	display: flex;
	justify-content: flex-end;
	padding-top: 10px;
}
.newAdvertsBottomPanel button {
	color: #1754a6;
	text-transform: capitalize;
}
.newAdvertsBottomPanel svg {
	margin-left: 10px;
}

.сardContainer {
	width: 48.5%;
	display: flex;
	flex-direction: column;
	background: #fff;
	border-radius: 12px;
	margin-bottom: 15px;
	/* height: 238px; */
	max-height: 320px;
}
.infoContainer {
	padding: 10px 10px 2px 21px;
}
.cardImage {
	position: relative;
	width: 100%;
	border-radius: 8px 8px 0 0;
	overflow: hidden;
	/* height: 70%; */
	height: 163px;
}
.cardImage img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 8px 8px 0 0;
}
.cardImage .topMobileFlag {
	position: absolute;
	top: 20px;
}
/* .topHeart {
    position: absolute;
    top: 12px; 
    right: 15px;
} */
.topHeart svg {
	fill: #ffd74a;
}
.cardName {
	font-size: 9px;
}
.cardName h4 {
	line-height: 22px;
	color: #000000;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 11px;
}
.cardPrice {
	line-height: 28px;
	color: #363636;
	font-size: 11px;
	font-weight: 700;
	margin: 2px 0px 10px 0px;
}
.cardLocation {
	display: flex;
	width: 100%;
	justify-content: space-between;
	height: 18px;
	overflow: hidden;
	margin-bottom: 17px;
}
.cardLocation span {
	line-height: 18px;
	color: #898c8f;
	display: block;
	font-size: 11px;
}

.timeAndState {
	display: flex;
	justify-content: space-between;
	color: #c4c4c4;
	font-size: 9px;
	padding-bottom: 5px;
}

/* @media(max-width: 360px) {
    .cardImage {
        height: 163px;
    }
    .сardContainer {
        max-height: 238px;
    }
} */

@media (max-width: 1285px) {
	.newAdvertsContent {
		gap: 0;
	}
}
@media (max-width: 1025px) {
	.newAdvertsContainer {
		padding-top: 0;
	}
	.newAdvertsContentMobile {
		justify-content: center;
		gap: 15px;
	}
	.newAdvertsContent {
		width: 100%;
		/*padding: 0 5px;*/
		padding: 0;
		justify-content: center;
		column-gap: 20px;
		row-gap: 15px;
	}
	.newAdvertsContainer h5 {
		margin-left: 10px;
		padding-bottom: 25px;
		font-family: var(--myMainFontBold);
		font-size: 16px;
		line-height: 16px;
		color: #2b2a38;
	}
	.newAdvertsBottomPanel {
		justify-content: center;
		padding: 35px 0;
	}
}
@media (max-width: 440px) {
	.newAdvertsContentMobile {
		justify-content: center;
		gap: 15px;
	}
}
