.breadCrumbsContainer {
	padding-top: 21px;
	display: flex;
	font-size: 12px;
	font-family: var(--myMainFontRegular) sans-serif;
	gap: 10px; /*16.05.2024 telegram*/
}

.main a {
	color: #383748;
}
.elements span {
	margin-right: 10px; /*16.05.2024 telegram*/
}
.elements span svg {
	padding-top: 2px;
}
.elements a {
	color: #383748;
	cursor: pointer;
}
.elements a.active {
	color: #00a9ce;
}
.elements a.disabled {
	color: rgba(61, 59, 71, 0.44);
	cursor: not-allowed;
}
