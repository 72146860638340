.container {
	display: flex;
	flex-direction: column;
	min-width: 354px;
	max-width: 354px;
	padding: 13px 23px 13px 12px;
	border-radius: 5px;
	background: #f8f8f8;
}
.container input {
	margin-top: 10px;
	height: 40px;
	border-radius: 5px;
	border: 1px solid #e7e7e7;
	background: #fff;
	padding-left: 20px;
	color: #727272;
	font-size: 14px;
	line-height: normal;
}
.greyLineContainer {
	display: flex;
	height: 14px;
	justify-content: center;
	border-left: 1px solid #e7e7e7;
	border-right: 1px solid #e7e7e7;
	background: #fff;
}
.greyLine {
	/*margin: 0 15px 0 6px;*/

	width: 299px;
	height: 1px;
	background: #909090;
}
.dropOptionsContainer {
	padding-left: 6px;
	padding-right: 10px;
	padding-bottom: 20px;
	background-color: white;
	border: 1px solid #e7e7e7;
	border-top: none;
	border-radius: 0 0 5px 5px;
}
.dropOptionsList {
	max-height: 323px;
	overflow: auto;
}
.dropOptionsElement {
	padding-left: 14px;
	margin-right: 6px;
	display: flex;
	align-items: center;
	height: 30px;
	color: #939393;
	font-size: 14px;
	line-height: 130%; /* 18.2px */
	cursor: pointer;
	border-radius: 5px;
}

.dropOptionsElement:hover {
	color: #3d3d3d;
	background-color: rgba(241, 241, 241, 0.57);
}
.dropOptionsContainer .dropOptionsList::-webkit-scrollbar {
	width: 6px;
}
.dropOptionsContainer .dropOptionsList::-webkit-scrollbar-thumb {
	background-color: #00a9ce; /* Цвет ползунка */
	border-radius: 26px; /* Закругленные углы ползунка */
	cursor: pointer;
}
.dropOptionsContainer .dropOptionsList::-webkit-scrollbar-track {
	border-radius: 26px;
	background: #eee;
	margin: 0 0 13px 0;
	cursor: pointer;
}

.inputBorder {
	border-radius: 5px !important;
}

@media (max-width: 430px) {
	.container {
		min-width: 100%;
		max-width: 100%;
	}
}
