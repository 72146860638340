/*Mobile*/

.boardCategoryMobileContainer {
	/* padding: 0px 12px 26px 0px; */
	background-color: #fff;
	position: relative;
}

.boardCategoryMobileTopSwitcherBlock {
	display: flex;
	align-items: center;
	gap: 5px;
	justify-content: center;
	position: absolute;
	right: 11px;
	top: 5px;
	width: 86px;
	height: 40px;
}

.boardCategoryMobileTopSwitcherBlock p {
	color: #1754a6;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.switcher_active svg {
	rotate: 180deg;
}
.boardCategoryOpen {
	display: flex;
	/* width: 20%; */
	flex-wrap: wrap;
	padding: 19px 12px 26px 12px;
	gap: 17px;
	justify-content: flex-start;
}
.boardCategoryOpenitemBlock {
	width: 21%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	max-height: 100px;
}

.boardCategoryOpenitemBlock p {
	text-align: center;
	color: #3d3d3d;
	font-size: 11px;
	max-height: 30px;
	height: 100%;
}

.categorySwiperContainer .swiper-pagination {
	padding: 50px !important;
}

.boardCategoryWrapper {
	width: 100%;

	/*height: 332px;*/
	background-color: #ffffff;
	caret-color: transparent;
}
.boardCategoryContainer {
	padding-top: 21px;
}

.boardCategoryTitle {
	min-height: 24px;
	margin-top: 26px;
	margin-bottom: 35px;
	text-align: center;
}
.boardCategoryTitle h1 {
	font-family: var(--myMainFontBold) sans-serif;
	font-size: 24px;
	line-height: 24px;
	text-transform: uppercase;
	color: #2b2a38;
}
.boardCategory {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-bottom: 40px;
}
.catalogItemRow {
	min-height: 180px;
	display: flex;
	flex-wrap: wrap;
	/*gap: 40px;*/
	gap: 1.2em;
	/*justify-content: space-between;*/
	padding-bottom: 20px;
	overflow: hidden;
}
.imageContainerFon {
	width: 90px;
	height: 90px;
	position: absolute;
	overflow: hidden;
	border-radius: 50px;
}
.imageContainerFon:hover {
	background-color: rgba(0, 0, 0, 0.05);
}
.imageContainerFon.mainCategory {
	border-radius: 10px;
}
.imageContainer {
	width: 90px;
	height: 90px;
	border-radius: 15px;
	overflow: hidden;
}
.imageContainer img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.catalogItem {
	cursor: pointer;
	width: 120px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.catalogItem .textNameRubrics {
	text-align: center;
}
.subCotegoriesContainer {
	margin-top: 32px;
}
.catalogItem .textNameRubrics p {
	/*min-height: 80px;*/

	/*  text-overflow: ellipsis;*/
	/*overflow: hidden;*/
}
.catalogItem p {
	margin-top: 14px;
	/*width: 90px;*/
	font-family:
		var(--myMainFontRegular) "Comfortaa",
		sans-serif;
	font-size: 16px;
	line-height: 18px;
	text-align: center;
	color: #727272;
}
.catalogItem:hover span p {
	color: #3d3d3d;
}

.categoriesTop {
	height: 29px;
	padding-bottom: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #3d3d3d;
	font-family: var(--myMainFontBold) sans-serif;
	font-size: 20px;
	line-height: normal;
	border-bottom: 1px solid #dadada;
}
.categoriesTopTitle {
	/*margin-top:41px;*/
	padding-left: 36px;
}
.categoriesTopClose {
	padding-right: 9px;
	/*padding-top: 40px;*/
}
.categoriesTopClose span {
	cursor: pointer;
}
.categoriesTopClose span svg {
	fill: #727272;
}
.categoriesTopClose span svg:hover {
	fill: #0e0e0e;
}

.categoriesColumn:first-child {
	width: 30%;
	/*height: 400px;*/
	border-right: 1px solid #dadada;
}
.categoriesColumn:last-child {
	width: 50%;
	overflow-y: auto;
}
.categoriesColumn:last-child.inAutoSelection {
	height: 400px;
}
.categoriesRow {
	padding-top: 21px;
	padding-bottom: 23px;
	display: flex;
	/* display: grid;
    grid-template-columns: repeat(2, 1fr);*/
}
.categoriesItem {
	/*min-width: 200px;*/
	height: 36px;
	padding: 5px 24px 5px 36px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	color: #7b7b7b;
	font-size: 16px;
	cursor: pointer;
}

.active,
.categoriesItem:hover {
	color: #383748;
	background: #f1f1f1;
}
.categoriesItem svg {
	fill: #939393;
}
.categoriesItem .name {
	max-width: 98%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

/*Third window*/

.thirdWindowWrap {
	display: flex;
	flex-direction: column;
	padding: 0 12px;
}

.compainListItemWrap {
	display: flex;
	justify-content: space-between;
	/* padding: 10px 0px; */
	height: 52px;
	border-bottom: 1px solid #e7e7e7;
	align-items: center;
}

.compainListItem_text {
	color: #2b2a38;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	/* font-weight: 600; */
	line-height: normal;
	cursor: pointer;
}

.arrowSvg svg {
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.arrowSvgActive svg {
	rotate: -180deg;
}

.openListItemWrap {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #e7e7e7;
	justify-content: space-between;
	cursor: pointer;
	margin-left: 35px;
	padding-left: 10px;
}

.openListItem {
	color: #3d3d3d;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	height: 44px;
	display: flex;
	align-items: center;
}

@media (max-width: 1700px) {
	/*.imageContainer {
        width: 90px;
        height: 90px;
        border-radius: 10px;
    }*/
	/*.catalogItemRow {
        padding-bottom:0;
    }*/
}
@media (max-width: 1025px) {
	.categorySwiperContainer {
		/* padding-top: 25px;
        padding-left: 15px;
        padding-bottom: 15px; */
		padding: 25px 14px 35px 15px;
		background-color: #fdfdfe;
	}
	.swiperSlide {
		height: 120px;
	}
	.catalogItem {
		width: 55px;
	}
	.catalogItem a {
	}
	.imageContainer {
		width: 61px;
		height: 61px;
		border-radius: 8px;
		margin-bottom: 10px;
	}
	.catalogItem p {
		margin-top: 0;
		width: 100%;
		font-family: var(--myMainFontRegular) sans-serif;
		font-size: 11px;
		line-height: 10px;
		color: #3d3d3d;
	}
	/*.categoriesColumn:first-child {
        width: 100%;
        border-right: 1px solid #DADADA;
    }*/
}
