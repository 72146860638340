.choseRubricModalWrap {
	/*position: fixed;*/
	top: 0;
	left: 0;
	background: #ffffff;
	/*width: 100%;
    height: 100vh;*/
	/* display: flex;
    align-items: center;
    justify-content: center; */
	z-index: 60;
	/*overflow-y: scroll;*/
}

.choseRubricModalContainer {
	width: 100%;
	height: 100%;
	/*padding: 0 12px;*/
}

/*First window*/

.choseRubricModalHeader {
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
}

.choseRubricModalContent {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.mainCategoryMobileWrap {
	display: flex;
	flex-wrap: wrap;
	padding-top: 11px;
}

.mainCategoryMobileBlock {
	width: 25%;
}

.mainCategoryMobileItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 0px;
}

.mainCategoryMobileImageBlock {
	width: 52px;
	height: 52px;
}

.mainCategoryMobileImage {
	width: 100%;
	height: 100%;
	border-radius: 68px;
}

.mainCategoryMobileText {
	text-align: center;
	color: #000;
	text-align: center;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 15px;
}

.hoseRubricModalTitle {
	color: #000;
	font-size: 16px;
	font-weight: 600;
	padding-bottom: 11px;
	border-bottom: 1px solid #ededed;
}

/*Second window*/

.choseRubricModalSecondHeader {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 20px;
	min-height: 45px;
	background: #f2f2f2;
	width: 100%;
	padding-left: 12px;
}

.choseRubricModalSecondHeader p {
	color: #2b2a38;
	font-size: 16px;
}

.secondCategoryMobileWrap {
	display: flex;
	flex-wrap: wrap;
	padding: 13px 12px 26px 12px;
	gap: 9px 25px;
	justify-content: flex-start;
}
.secondCategoryMobileItemBlock {
	width: 18%;
	display: flex;
	/* justify-content: center; */
	flex-direction: column;
	align-items: center;
}

.secondCategoryMobileItemBlock p {
	text-align: center;
	color: #3d3d3d;
	font-size: 10px;
}

.secondCategoryMobileImageContainer {
	width: 65px;
	height: 65px;
	border-radius: 15px;
	overflow: hidden;
}
.secondCategoryMobileImageContainer img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/*Third window*/

.thirdWindowWrap {
	display: flex;
	flex-direction: column;
	padding: 0px 12px;
}

.compainListItemWrap {
	display: flex;
	justify-content: space-between;
	/* padding: 10px 0px; */
	height: 52px;
	border-bottom: 1px solid #e7e7e7;
	align-items: center;
}

.compainListItem_text {
	color: #2b2a38;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	/* font-weight: 600; */
	line-height: normal;
	cursor: pointer;
}

.arrowSvg svg {
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.arrowSvgActive svg {
	rotate: -180deg;
}

.openListItemWrap {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #e7e7e7;
	justify-content: space-between;
	cursor: pointer;
	margin-left: 35px;
	padding-left: 10px;
}

.openListItem {
	color: #3d3d3d;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	height: 44px;
	display: flex;
	align-items: center;
}
