.container {
	width: 100%;
	max-width: 240px;
	margin-top: 20px;
}

.deliveryTable {
	margin-top: 26px;
	display: flex;
	flex-direction: column;
	gap: 18px;
}

.deliveryButton {
	display: flex;
	align-items: center;
	padding-left: 17px;
	padding-right: 14px;
	width: 238px;
	height: 70px;
	background: #ffffff;
	border: 1px solid #bdbdbd;
	border-radius: 4px;
	text-wrap: nowrap;
	cursor: pointer;
}

.deliveryButton:hover {
	border: 1px solid #dfdfdf;
}

.deliveryButton.selected {
	border: 2px solid #00cefc;
}

.deliveryLogo {
	margin-right: 15px;
	flex-shrink: 0;
}

.deliveryLogo img {
	width: 43px;
	height: 38px;
}

.deliveryInfo {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.deliveryName {
	font-weight: bold;
	font-size: 1.1em;
}

.deliveryTime {
	font-size: 0.9em;
	color: #666;
}

.selectedOptions {
	margin-top: 25px;
}

.selectedOptions h3 {
	margin-bottom: 10px;
}

.selectedOption {
	padding: 5px 0;
	font-size: 1em;
}

.btn {
	display: flex;
	justify-content: flex-end;
	margin-top: 10px !important;
}

.deliveryDetails button {
	min-width: 187px;
	height: 40px;
	border-radius: 5px;
	border: 1px solid #c4c4c4;
	color: #c4c4c4;
	background: #fff;
	font-size: 14px;
	line-height: 130%;
	text-transform: uppercase;
}

@media (max-width: 1024px) {
	.btn {
		justify-content: center;
	}

	.btn button {
		width: 100%;
		justify-content: center;
	}
}
