@import "~react-image-gallery/styles/css/image-gallery.css";
@font-face {
	font-family: "OpenSansLight";
	src:
		local("OpenSansLight"),
		url("./fonts/OpenSans-Light.ttf") format("truetype");
	font-weight: 300;
}
@font-face {
	font-family: "OpenSansRegular";
	src:
		local("OpenSansRegular"),
		url("./fonts/OpenSans-Regular.ttf") format("truetype");
	font-weight: 400;
}
@font-face {
	font-family: "OpenSansMedium";
	src:
		local("OpenSansMedium"),
		url("./fonts/OpenSans-Medium.ttf") format("truetype");
	font-weight: 500;
}
@font-face {
	font-family: "OpenSansBold";
	src:
		local("OpenSansBold"),
		url("./fonts/OpenSans-Bold.ttf") format("truetype");
	font-weight: 700;
}
@font-face {
	font-family: "Comfortaa";
	src:
		local("Comfortaa"),
		url("./fonts/Comfortaa-Regular.ttf") format("truetype");
	font-weight: 400;
}

:root {
	--myMainFontLight: OpenSansLight, open-sans, serif;
	--myMainFontRegular: OpenSansRegular, open-sans, serif;
	--myMainFontMedium: OpenSansMedium, open-sans, serif;
	--myMainFontBold: OpenSansBold, open-sans, serif;
	--MyFunnyFont: Comfortaa, open-sans, serif;
}
* {
	padding: 0;
	margin: 0;
	border: 0;
}

*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

:focus,
:active {
	outline: none;
}

a:focus,
a:active {
	outline: none;
}

nav,
footer,
header,
aside {
	display: block;
}

html,
body {
	height: 100%;
	/*min-height: 830px;*/
	/*https://app.clickup.com/t/86935gk3d Задача:
Встановити мінімальну висоту елемента <body> на рівні 830 пікселів.
Переконатися, що ця зміна не впливає негативно на відображення контенту на сторінках з меншою кількістю контенту.
Забезпечити, що футер залишається в нижній частині сторінки, не залежно від кількості контенту.*/
	width: 100%;
	font-size: 100%;
	/*line-height: 1;*/
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
	font-family: inherit;
}
input {
	caret-color: initial;
}
input::-ms-clear {
	display: none;
}

button {
	cursor: pointer;
	background: inherit;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

a,
a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: 400;
}

body {
	max-width: 100%;
	font-family: var(--myMainFontRegular);
	caret-color: transparent;
}

.wrapper {
	min-height: 100vh; /*all 100%*/
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

[class*="__container"] {
	position: relative;
	max-width: 1523px;
	/*контейнер больше на размер отступов безопасности */
	margin: 0 auto;
	padding: 0 12px;

	/* отступы безопасности */
}
main {
	flex: 1 1 auto;
	margin-top: 57px;
	/* background:#F2F2F2; */
	background: #fafafa;
	/*min-height: 825px !important;*/
	min-height: 85vh !important;
	/*background: #fdfdfe;*/
}
.mainSearchFonContainer {
	height: 100%;
	width: 100%;
	/*background: rgb(43, 43, 44,0.5);*/ /*https://app.clickup.com/t/8693y8ayj*/
	position: fixed;
	top: 0;
	z-index: 50;
}
.rc-slider-handle-dragging {
	box-shadow: none !important;
}

.image-gallery-thumbnails-wrapper {
	padding-top: 10px;
}

@media (max-width: 2200px) {
	/*первый поинт делаем на ширине контейнера */
	[class*="__container"] {
		max-width: 1280px;
		width: 100%;
	}
}

.sticky-inner-wrapper {
	z-index: 1;
}
