.container {
	width: 100%;
	position: relative;
}
.container input {
	width: 100%;
	caret-color: currentColor;
}
/* Для Microsoft Edge */
.container input::-ms-reveal,
.container input::-ms-clear {
	display: none;
}

/*!* Для других браузеров, если они поддерживают псевдоэлементы *!
.container input::password::-webkit-visible-password {
    display: none !important;
}*/
.generationPass {
	max-width: 130px;
	display: block;
	color: #c15519;
	cursor: pointer;
}
.seePassButtonEye {
	position: absolute;
	top: 12px;
	right: 10px;
	cursor: pointer;
	caret-color: transparent;
}
.seePassButton svg {
	position: absolute;
	caret-color: transparent;
	top: 10px;
	right: 40px;
}
.capsInfoContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	/*width: 103px;*/
	position: absolute;
	top: 60px;
	left: 0;
	border-radius: 5px;
	background: #8d8d8d;
	color: white;
	font-size: 12px;
	padding: 5px 15px;
}
.attentionCurilic {
	top: 100px;
	left: 0;
}
.cadsText {
	display: flex;
}
.cadsIcon {
	margin-right: 8px;
}
.rememberPassContainer {
	margin-top: 11px;
	display: flex;
	width: 100%;
	justify-content: flex-end;
	gap: 5px;
}
.rememberPassContainer span {
	color: #00a9ce;
	cursor: pointer;
	text-decoration-line: underline;
}
.error {
	color: red;
}
