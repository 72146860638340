.modalContainer {
	padding: 25px 40px;
	background: #ffffff;
	border-radius: 5px;
	width: 488px;
	height: 415px;
	overflow: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.mainText {
	color: #262424;
	text-align: center;
	/* font-family: Open Sans; */
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.secondTetx {
	color: #3d3d3d;
	text-align: center;
	/* font-family: Open Sans; */
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
