.footer {
	position: relative;
	width: 100%;
	background-color: #2b2a39;
	caret-color: transparent;
	font-size: 14px;
	color: #ffffff;
}

.footerWrapper {
	background-color: #383748;
}
.footerContent {
	display: flex;
	gap: 5px;
}

.contentItems:first-child {
	padding-top: 50px;
}

.contentItems {
	flex: 1 1 20%;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 18px;
	color: #ffffff;
}

.contentItems ul li:first-child {
	padding-top: 30px;
}

.contentItems ul li {
	padding-bottom: 20px;
}

.contentItems ul li a {
	font-family: var(--myMainFontRegular) sans-serif;
	color: #ffffff;
}

.contentItems:not(:first-child) {
	padding-top: 37px;
}

.navBottomContainer {
	display: flex;
	justify-content: flex-end;
	height: 43px;
}
.border {
	width: 100%;
	height: 2px;
	background-color: #9a6065;
}
.navBottomLeftElements,
.navBottomRightElements {
	display: flex;
	align-items: center;
}

.navBottomLeftElements h5 {
	padding-top: 2px;
}

.navBottomRightElements a {
	font-family: var(--myMainFontLight) sans-serif;
	padding: 0 22px;
	/* text-decoration-line: underline; */
	color: #e5e5e5;
}
.LogoSocial {
	margin-top: 18px;
	display: flex;
	padding-right: 48px;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	/* align-items: center; */
}

.LogoSocial p {
	font-style: italic;
}

.socialIcons {
	display: flex;
	width: 100%;
	gap: 20px;
	padding-bottom: 58px;
}
.social .facebook {
	width: 40px;
	height: 40px;
	padding-top: 7px;
	text-align: center;
	background-color: #3d5b96;
	border-radius: 20px;
}
.social .instagram {
	width: 40px;
	height: 40px;
	padding-top: 6px;
	text-align: center;
	border-radius: 20px;
	background: linear-gradient(#ffdd55, #ff543e, #c837ab);
}
.socialAPP {
	display: flex;
	margin: 15px 0;
}
.socialAPP .playMarket,
.socialAPP .appStore {
	width: 160px;
	height: 40px;
	border-radius: 15px;
	margin-right: 32px;
	background-color: grey;
}
.about .customersItem {
	display: flex;
	gap: 20px;
}
.about .customersItem p {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 18px;
}
/*=========Mobile ==================*/
.mobileFooterContainer {
	position: fixed;
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 10px 15px;
	background-color: #353535;
	z-index: 5;
	user-select: none;
	height: 64px;
}

.mobileFooterContainer .menuNav {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 12px 0;
}

.menuNavElement {
	display: flex;
	align-items: center;
}

.menuNavElementName {
	text-align: center;
	font-size: 8px;
}

.menuNavElementSvg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
}

.mobileFooterContainer .menuNavElement a {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 8px;
	text-transform: uppercase;
	color: #e5e5e5;
	min-height: 40px;
}

.mobileFooterContainer .menuNavElement a svg {
	width: 32px;
	height: 32px;
}

.menuNavElementDisabled {
	display: flex;
	min-width: 60px;
	flex-direction: column;
	align-items: center;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 8px;
	text-transform: uppercase;
	color: grey;
	height: auto;
}
.favorite span {
	margin-bottom: 2px;
}

/*New style*/

.footerIconsBlock {
	margin-top: 18px;
	display: flex;
	gap: 10px;
}

.footerIconsBlock svg {
	width: 35px;
	height: 35px;
	fill:grey
}
.footerIconsBlock a:nth-child(1) svg:hover  {
	fill:#3b5998
}
.footerIconsBlock a:nth-child(2) svg:hover rect {
	fill:url(#paint0_linear)
}

.socialLink {
	cursor: pointer;
}
.footerContentBlock {
	display: flex;
	/* flex-direction: column; */
	flex-wrap: wrap;
	padding: 20px 0;
	gap: 0 20px;
	/* gap: 50px; */
	justify-content: space-between;
	width: 100%;
}
.footerPage {
	/* width: 30%; */
	padding: 7px 0;
	text-align: start;
	min-width: 163px;
	color: white;
}
.footerContentColumn {
	min-width: 100px;
	max-width: 250px;
	/* overflow: hidden; */
	display: flex;
	flex-direction: column;
	/*justify-content: end;*/
}
.columnThird {
	position: relative;
}

.rule {
	position: absolute;
	bottom: -35px;
	z-index: 20;
	font-family: var(--myMainFontLight) sans-serif;
	color: #e5e5e5;
	width: 100%;
	left: 600px;
}

@media (max-width: 1150px) {
	.rule {
		left: 500px;
	}
}

@media (max-width: 1084px) {
	.about .customersItem {
		gap: 10px;
	}
	.contentItems {
		font-size: 14px;
	}
	.about .customersItem p {
		font-size: 14px;
	}
}
