.mobileHeaderWrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	transition: transform 0.64s ease-in-out;
	z-index: 1000;
	opacity: 1;
}

.visible {
	transform: translateY(0);
}

.hidden {
	transform: translateY(-100%);
}

.mobileHeaderContainer {
	width: 100%;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 9px 10px 10px 20px;
	background: #2b2a38;
	z-index: 50;
	gap: 7px;
}

.mobileHeaderMenuBlock {
	display: flex;
	justify-content: center;
}

.mobileHeaderSearchBlock {
	/* min-width: 262px; */
	width: 100%;
	max-height: 26px;
	background-color: #fff;
	height: 100%;
	border-radius: 20px;
	display: flex;
	align-items: center;
}

.mobileHeaderSearchLogoBlcok {
	/* margin: 40px 0px; */
	padding: 13.5px 0px;
}

.mobileHeaderSearchInput {
	width: 100%;
	border: none;
	background: #2b2a38;
	outline: none;
	padding: 14px 0;
	font-size: 16px;
}

.mobileHeaderSearchBlock svg {
	margin: 0px 10px;
}

.mobileHeaderIconblock {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 15%;
	max-width: 70px;
	height: 46px;
}

.mobileHeaderAvatarBlock {
	/* max-width: 36px;
    max-height: 38px;
    border-radius: 50px; */
	width: 30px;
	height: 30px;
	border-radius: 16px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mobileHeaderAvatar {
	width: 100%;
	height: 100%;
}

@media (max-width: 495px) {
	.mobileHeaderIconblock {
		width: 30%;
	}
}

.uiInputContainer {
	position: relative;
	width: 300px;
}

.uiInput {
	width: 100%;
	padding: 10px 10px 10px 40px;
	font-size: 1em;
	border: none;
	border-bottom: 2px solid #ccc;
	outline: none;
	background-color: transparent;
	transition: border-color 0.3s;
}

.uiInput:focus {
	border-color: #6c63ff;
}

.uiInputUnderline {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 2px;
	width: 100%;
	background-color: #6c63ff;
	transform: scaleX(0);
	transition: transform 0.3s;
}

.uiInput:focus + .uiInputUnderline {
	transform: scaleX(1);
}

.uiInputHighlight {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: rgba(108, 99, 255, 0.1);
	transition: width 0.3s;
}

.uiInput:focus ~ .uiInputHighlight {
	width: 100%;
}

.uiInputIcon {
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
	transition: color 0.3s;
}

.uiInput:focus ~ .uiInputIcon {
	color: #6c63ff;
}

.uiInputIcon svg {
	width: 20px;
	height: 20px;
}
