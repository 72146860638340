.logoContainer {
	width: 116px;
	position: relative;
}
.logoLeaf {
	position: absolute;
	bottom: -3px;
	right: 6px;
}
.logoMobileContainer {
	display: flex;
	align-items: center;
	animation: fadeIn ease 2s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
