.modalWrapper {
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 60;
	overflow-y: auto;
}

.dialogContainer {
}

.authFormContainer {
	padding: 25px 31px;
	background: #ffffff;
	border-radius: 5px;
}

/*==================Form title start================*/
.formTitlePanel {
	display: flex;
	justify-content: space-between;
}
.approvedInfo {
	max-width: 317px;
	min-height: 100px;
	color: #6d6d6d;
	text-align: center;
	padding-top: 15px;
}
.formTitlePanel span svg {
	fill: #727272;
	cursor: pointer;
}

.formTitlePanel span svg:hover {
	fill: #ffd74a;
}

.titlePanelButton {
	display: flex;
	cursor: pointer;
	caret-color: transparent;
}

.titlePanelButton h5 {
	padding-bottom: 12px;
	font-family: var(--myMainFontBold) sans-serif;
	font-size: 21px;
	line-height: 21px;
	text-transform: uppercase;
	color: #383748;
}

.titlePanelButton h5.active {
	border-bottom: 2px solid #ffd74a;
}

.titlePanelButton h5:first-child {
	margin-right: 36px;
}
.titlePanelButtonCloseCross svg {
	fill: #6d6d6d;
}
.politics {
	width: 400px;
	padding-top: 18px;
	padding-left: 2px;
}

.politics a {
	color: #00a9ce;
}

/*==================Form title end================*/
/*==================Form content start============*/
.formContent {
	display: flex;
}

.borderElement {
	margin-top: 25px;
	margin-left: 38px;
	margin-right: 44px;
	padding-top: 15%;
	width: 1px;
	background-color: #dadada;
	height: 220px;
}

.borderElement span {
	padding-top: 5px;
	background-color: white;
	margin-left: -10px;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
	color: #dadada;
}

/*===================Formik start ================*/
.mainFormContainer {
	display: flex;
	flex-direction: column;
	width: 322px;
}

.mainFormElement input {
	display: flex;
	/*align-items: center;
    justify-content: center;*/
	width: 100%;
	height: 45px;
	border: 1px solid #d3d3d3;
	border-radius: 5px;
	padding-bottom: 2px;
	padding-left: 15px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 14px;
	line-height: 16px;
	caret-color: currentColor;
}
.mainFormElement input:focus {
	border-bottom: 2px solid #00a9ce;
	padding-bottom: 0;
}
.errorApple {
	display: inline-block;
	width: 100%;
	text-align: right;
	color: red;
}
.mainFormContainer form .error input {
	border-bottom: 2px solid red;
}

.mainFormElement .phone input {
	width: 100%;
	height: 46px;
	/*padding-top: 6px;*/
	padding-bottom: 2px;
	border: 1px solid #d3d3d3;
	border-radius: 5px;
	/*padding: 15px;*/
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 16px;
	line-height: 16px;
}
.mainFormElement .phone input:focus {
	padding-bottom: 1px;
	border-bottom: 2px solid #00a9ce;
}
.mainFormElement .prompt {
	padding-left: 9%;
	height: 100%;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 12px;
	line-height: 15px;
	color: #515151;
}

.emailInput {
	margin-bottom: 5px;
}

.mainFormElement:first-child {
	margin-top: 23px;
}

.mainFormElement:not(:last-child) {
	margin-bottom: 15px;
}

.mainFormContainer .mainFormElement p {
	margin-left: 25px;
	font-family: var(--myMainFontRegular);
	font-size: 12px;
	line-height: 15px;
	/*color: #515151;*/
}

.mainFormElement .phone input {
	padding-left: 50px;
	caret-color: currentColor;
}

/*.mainFormElement .passInput {
    position: relative;
}*/

/*.mainFormElement .passInput .seePassButton {
    position: absolute;
    top: 12px;
    right: 10px;
    cursor: pointer;
    caret-color: transparent;
}
.mainFormElement .passInput .seePassButton svg {
    position: absolute;
    cursor: pointer;
    caret-color: transparent;
    top: -2px;
    right: 70px;
}*/

/*.mainFormElement .seePassButton svg {
    margin-left: 15px;
}*/

.formBottom {
	/* margin-top: 66px;*/
	margin-top: 26px;
}

.formBottom button {
	width: 319px;
	padding: 15px 0;
	font-family: var(--myMainFontRegular);
	font-size: 16px;
	line-height: 16px;
	color: #2b2a38;
	/*transform: rotate(-0.44deg);*/
	background-color: #ffd74a;
	border-radius: 5px;
	text-transform: uppercase;
}

.formBottom button:hover {
	background: #ffe380;
}

/*===================Formik end ==================*/
/*===================Fast enter start ==================*/
.fastEnter {
	width: 210px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	align-items: center;
}

.fastEnter p {
	margin-bottom: 20px;
}
.buttonsContainer {
	display: flex;
	flex-direction: column;
	gap: 15px;
}
.fastEnter button {
	display: flex;
	align-items: center;
	margin-right: 15px;
	width: 200px;
	height: 45px;
	background: #f0f0f0;
	border: 1px solid #d1d1d1;
	border-radius: 5px;
	/*text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);*/
}

.fastEnter button:hover {
	background: #f3f3f3;
	border: 1px solid #dfdfdf;
}

.fastEnter .icon {
	margin-left: 40px;
	margin-right: 15px;
}

/*===================Fast enter end ==================*/
/*====================Mobile =========================*/

@media (max-width: 1025px) {
	.mobileContainer {
		margin-bottom: 57px;
		padding: 22px 15px 41px 15px;
		margin-top: -70px;
	}
	.titlePanelButton {
		margin-top: 23px;
	}

	.titlePanelButton h5 {
		font-size: 18px;
		color: #a5a5a5;
	}

	.titlePanelButton h5.active {
		border-bottom: 4px solid #ffd74a;
		color: #2b2a38;
	}

	.titlePanelButton h5:first-child {
		width: 127px;
		height: 40px;
		text-align: center;
	}

	.titlePanelButton h5:last-child {
		width: 174px;
		text-align: center;
	}

	/*======== Fast Enter Start =========*/
	.fastEnter {
		width: 100%;
		align-items: flex-start;
		margin-top: 28px;
	}

	.fastEnter p {
		font-family: var(--myMainFontRegular);
		font-size: 16px;
		color: #2b2a38;
	}

	.buttonsContainer {
		flex-direction: row;
		width: 100%;
		gap: 50px;
		justify-content: space-between;
		padding: 0 35px;
	}

	.buttonsNamesContainer {
		font-size: 16px;
		text-align: center;
		color: #000000;
		min-width: 100%;
		display: flex;
		gap: 50px;
		justify-content: space-between;
		padding: 0 35px;
	}

	.buttonsNamesContainer span {
		width: 70px;
	}

	.fastEnter button {
		justify-content: center;
		width: 80px;
		height: 80px;
		margin-right: 0;
	}

	.fastEnter .icon {
		margin: 0;
	}

	.borderSimulation {
		margin-top: 40px;
		width: 100%;
		display: flex;
	}

	.orElement {
		width: 20%;
		text-align: center;
		font-family: var(--myMainFontRegular);
		font-size: 16px;
		line-height: 16px;
		color: #dadada;
	}

	.leftLine,
	.rightLine {
		display: block;
		margin-top: 2.5%;
		width: 40%;
		height: 1px;
		background-color: #dadada;
	}

	/*=========Form start =================*/
	.mainFormContainer {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.politics {
		width: 312px;
		padding-top: 18px;
		padding-left: 2px;
	}

	.politics p {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.politics a {
		color: #00a9ce;
	}

	.formBottom button {
		width: 100%;
		padding: 15px 0;
		color: #000000;
	}

	.prewArrow span {
		display: flex;
		width: 40px;
		height: 40px;
		justify-content: center;
		align-items: center;
	}

	.prewArrow span svg {
		margin-right: 15px;
		cursor: pointer;
	}

	.authInfoBlock {
		font-size: 14px;
		margin-left: 3px;
	}

	.authInfoBlockTitle {
		color: #3d3d3d;
	}

	.authInfoBlockLinkGroup {
		display: flex;
		flex-direction: column;
	}

	.authInfoBlockLink {
		color: #6cc1d3;
		height: 30px;
		display: flex;
		align-items: center;
	}
}

/*====================Mobile end =====================*/

/*===========Error Popup Start========================*/
.socialNetworkErrorPopupWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
}
.socialNetworkErrorPopupContainer {
	border-radius: 5px;
	padding: 15px;
	background-color: #ffffff;
	width: 100%;
	max-width: 350px;
	height: 100%;
	max-height: 250px;
	color: rgba(61, 61, 61, 1);
	font-size: 14px;
	z-index: 5;
	overflow: hidden;
}
.socialNetworkErrorPopupContainer div:first-child {
	text-align: right;

}
.socialNetworkErrorPopupContainer div:last-child {
	margin-top: 50px;
	text-align: center;
}
/*===========Error Popup End========================*/
