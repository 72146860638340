.myBalanceInfoCardContainer {
	padding: 26px 28px 30px 23px;
	background: #ffffff;
	border-radius: 10px 10px 0 0;
}

.cardTitle {
	font-size: 18px;
	text-transform: uppercase;
	color: #000000;
}

.infoPanel {
	/*margin-top: 18px;*/
	/*margin-top: 25px;*/
	margin-top: 9px; /*08.05.2024 https://www.figma.com/file/YQAlSedTMB2mDP2kxax4zV/bago---Mockups?type=design&node-id=10841-29764&mode=design&t=C7orUAdLWJBSLR9w-4*/
	display: flex;
	justify-content: space-between;
}

.infoPanelLeft {
	font-size: 16px;
	color: #727272;
}

.infoPanelRight {
	display: flex;
	gap: 25px;
	margin-right: 26px;
	padding-bottom: 12px;
	border-bottom: 1px solid #e7e7e7;
}

.infoPanelRightSecond {
	width: 100%;
	margin-top: 12px;
	padding-bottom: 0;
	display: flex;
	justify-content: flex-end;
	border-bottom: none;
}

.totalPaymentInfoNumbers {
	display: flex;
	font-family: var(--myMainFontMedium) sans-serif;
}

.infoQuestionMark {
	margin-top: 2px;
	margin-left: 9px;

	& svg {
	}
}

.totalPaymentInfoText {
	font-size: 16px;
	color: #727272;
}

.optionNames {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: self-end;
	font-size: 16px;
}

.optionNames_name {
	color: #727272;
}

.optionNames_insurance a {
	text-decoration-line: underline;
	color: #00a9ce;
	cursor: pointer;
}

.optionNames_insurance a:hover {
	text-decoration-line: inherit;
}

.optionsData {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 9px;
	font-size: 16px;
}

.optionsData svg {
	cursor: pointer;
}

.optionsData_sum,
.optionsData_bonuses,
.optionsData_InsuranceAmount {
	display: flex;
	align-items: center;
	gap: 10px;
	color: #000000;
}

.blockGreyWrapper {
	background-color: #f8f8f8;
}

.topUpAccount {
	/*margin-top: 30px;*/
	padding-top: 30px;
	display: flex;
	align-items: center;
	height: 60px;
}

.topUpAccount span {
	/*margin-left:30px;*/
	padding-left: 22px;
	margin-right: 30px;
	font-family: var(--myMainFontBold) sans-serif;
	font-size: 21px;
	color: #3d3d3d;
}

.topUpAccount input {
	width: 112px;
	height: 35px;
	background: #ffffff;
	border: 1px solid #c8c8c8;
	border-radius: 5px;
	margin-right: 16px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 16px;
	text-align: right;
	padding-right: 13px;
	text-transform: uppercase;
	color: #727272;
	line-height: 130%;
}

.inputContainer {
	display: flex;
	flex-direction: column;
}

.inputContainer.errorInputSum {
	margin-top: 18px;
	text-align: center;
}

.inputContainer .errorInputSum {
	color: red;
	font-size: 12px;
}

.monthTableContainer {
	margin: 15px 23px 31px 23px;
	max-width: 100%;
	padding: 12px;
	border-bottom: 1px solid #dcdcdc;
	border-top: 1px solid #dcdcdc;
	font-family: var(--myMainFontMedium), sans-serif;
	font-size: 14px;
	text-align: center;
	color: #3d5b96;
}

.topUpAccount input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.topUpAccount input[type="number"] {
	-moz-appearance: textfield; /* Firefox */
}

.topUpInsuranceAmountContainer input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.topUpInsuranceAmountContainer input[type="number"] {
	-moz-appearance: textfield; /* Firefox */
}

.payMethodeContainer {
	display: flex;
	gap: 22px;
	margin-top: 41px;
	/*margin-top: 55px;*/
	padding-left: 23px;
	/*margin-left: 5px;*/
}

.payMethodeContainer button:hover {
	background: rgba(255, 255, 255, 0.5);
	border: 1px solid #c4c4c4;
}

.payMethodeContainer .payButton {
	display: flex;
	/*justify-content: space-between;*/
	column-gap: 15px;
	padding: 13px 10px 7px 14px;
	width: 220px;
	height: 70px;
	background: #ffffff;
	border: 1px solid #ebebeb;
	border-radius: 4px;
}

.payMethodeContainer .payButton.payButtonOne {
	width: 246px;
	height: 60px;
	padding: 10px;
}

.payButton_info {
	height: 100%;
	display: flex;
	flex-direction: column;
	/* justify-content: space-between;*/
}

.payButton_TopUp {
	display: flex;
	align-items: center;
	height: 100%;
	padding-left: 36px;
	border-left: 1px solid #d9d9d9;
	font-size: 18px;
	color: #585858;
}

.payButton_infoTitle {
	text-align: left;
	font-size: 16px;
	color: #2b2a38;
}

.payButton_infoSum {
	max-height: 100%;
	font-size: 12px;
	color: #727272;
}

.payButton_infoSumNumber {
	color: #2b2a38;
}

.borderLineElement {
	margin: 40px auto;
	width: 95%;
	height: 1px;
	background-color: #dcdcdc;
}

.accordionSummary {
	height: 71px;
}

.seeReportContainer {
	margin-bottom: 83px;
}

.reportTableTop {
	/*margin-left: 9px;*/
	margin-left: 2px;
	display: flex;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
}

.reportTableTopSelects {
	display: flex;
	gap: 37px;
	margin-right: 126px;
}

.reportTableTop h4 {
	font-family: var(--myMainFontBold) sans-serif;
	font-weight: 700;
	font-size: 21px;
	color: #3d3d3d;
}

.tableTopSelect {
	width: 154px;
	height: 35px;
	background: #ffffff;
	border: 1px solid #e7e7e7;
	border-radius: 4px;
}

.accordionDetails {
	max-width: 100%;
	padding: 0 !important;
	flex-direction: column;
	overflow: hidden;
}

/*=============================================*/
.reportTableTitle,
.reportTableContent {
	display: flex;
	max-width: 100%;
}

.reportTableTitle {
	padding: 15px;
	font-size: 16px;
	color: #7a7a7a;
	background-color: #f8f8f8;
}

.reportTableContent {
	padding-bottom: 15px;
}

.tableTitleData,
.tableInfoDataContainer {
	flex: 1;
	flex: 0 0 18%;
	margin-left: 23px;
	display: flex;
	justify-content: center;
}

.tableTitleTransaction,
.tableInfoTransaction {
	flex: 0 0 12.3%;
	display: flex;
	justify-content: flex-end;
}

.tableInfoTransaction,
.tableInfoTime {
	color: #727272;
	font-family: var(--myMainFontRegular) "sans-serif";
}

.tableInfoData,
.tableInfoName {
	color: #3d3d3d;
}

.tableTitleSum,
.tableInfoSum {
	flex: 0 0 14.5%;
	display: flex;
	justify-content: flex-end;
}

.tableInfoSum {
	font-size: 16px;
	color: #444444;
}

.tableTitleName,
.tableInfoName {
	margin-left: 101px;
	flex-grow: 1;
	overflow: hidden;
}

.tableInfoName {
	margin-right: 23px;
}

.plus {
	font-family: var(--myMainFontBold) "sans-serif";
	font-weight: 600;
}

.tableInfoSum.bonus.plus {
	color: #00a9ce;
}

.tableInfoSum.funds.plus {
	color: #32933c;
}

.additionalNoticeName {
	width: 100%;
	max-width: 480px;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: #00a9ce;
	overflow: hidden;

	& a {
		color: #00a9ce;
	}
}

.tableInfoDataContainer {
	display: flex;
	justify-content: space-between;
	gap: 5px;
}

.reportTableSavePanel svg {
	cursor: pointer;
}

.buttonContainer {
	width: 100%;
	padding: 15px 20px 35px 0;
	text-align: right;

	& button {
		color: #00a9ce;
	}
}

/*===========================================*/
.topUpInsuranceAmountContainer {
	padding-top: 50px;
	height: 439px;
	background: #f9f9f9;
	border-radius: 0 0 10px 10px;
}

.topUpInsuranceAmountContainer h4 {
	display: inline-block;
}

.topUpInsuranceAmountContainer input {
	width: 112px;
	height: 35px;
	background: #ffffff;
	border: 1px solid #c4c4c4;
	border-radius: 5px;
	font-size: 12px;
	text-align: right;
	/*padding-right: 13px;*/
	text-transform: uppercase;
	color: #727272;
}

.topUpInsuranceAmountContainer .transferIn button,
.topUpInsuranceAmountContainer .transferOut button {
	width: 112px;
	height: 35px;
	background: #53e0ff;
	border-radius: 5px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 12px;
	text-align: center;
	text-transform: uppercase;
	color: #2b2a38;
}

.topUpInsuranceAmountContainer .transferIn button:hover,
.topUpInsuranceAmountContainer .transferOut button:hover {
	background-color: #97eafe;
}

.transferIn,
.transferOut {
	display: flex;
	align-items: center;
	font-family: var(--myMainFontBold) sans-serif;
	font-size: 21px;
	color: #3d3d3d;
	padding: 40px 0 40px 31px;
	border-bottom: 1px solid #dcdcdc;
}

.transferIn h4,
.transferOut h4 {
	flex: 0 0 50%;
}

.transferIn input,
.transferOut input {
	padding-right: 13px;
	margin-right: 16px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 12px;
	line-height: 130%;
	color: #727272;
	caret-color: #dadada;
}

.transferIn span,
.transferOut span {
	margin-right: 32px;
	font-size: 12px;
	color: #3d3d3d;
}

.transferIn {
	border-top: 1px solid #dcdcdc;
}

.transferIn input {
	/*margin-left: 35px;*/
}

.transferOut input {
	/*margin-left: 45px;*/
}

.exitButtonContainer {
	padding: 50px 30px 0 0;
	text-align: right;
}

.exitButtonContainer button {
	padding: 13px 15px;
	background: #ffffff;
	border: 1px solid #939393;
	border-radius: 4px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 14px;
	text-transform: uppercase;
	color: #939393;
}

.exitButtonContainer button:hover {
	background: rgba(255, 255, 255, 0.03);
}

@media (max-width: 799px) {
	.reportTableTitle {
		padding-right: 23px;
		justify-content: space-between;
	}

	.reportTableContent {
		padding: 15px 23px 20px 0;
		justify-content: space-between;
	}

	.tableInfoDataContainer {
		flex: 0 0 50%;
	}

	.tableInfoSum {
		padding-right: 23px;
		flex: 0 0 50%;
	}

	.tableInfoName {
		margin-left: 23px;
		/*padding-bottom: 10px;*/
		flex-grow: 1;
		overflow: hidden;
	}

	.tableTitleTransaction,
	.tableInfoTransaction {
		color: #727272;
		padding: 0 23px 15px;
		justify-content: space-between;
		border-bottom: 1px solid #dcdcdc;
	}

	.topUpAccount {
		padding-right: 13px;
	}

	.payMethodeContainer {
	}

	.monthTableContainer {
		margin-bottom: 0;
	}

	.additionalNoticeName {
		padding: 4px 0 20px 0;
	}
}

@media (max-width: 500px) {
	.myBalanceInfoCardContainer {
		/*padding: 26px 28px 30px 23px;
            background: #FFFFFF;
            border-radius: 10px 10px 0 0;*/
	}

	.infoPanel {
		/*margin-top: 18px;*/
		/*margin-top: 25px;*/
		margin-top: 9px; /*08.05.2024 https://www.figma.com/file/YQAlSedTMB2mDP2kxax4zV/bago---Mockups?type=design&node-id=10841-29764&mode=design&t=C7orUAdLWJBSLR9w-4*/
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

	.infoPanelRight {
		margin-top: 10px;
		margin-right: 0;
		justify-content: space-between;
	}

	.optionNames {
		flex-direction: column;
		justify-content: space-between;
		align-items: self-start;
		font-size: 16px;
	}

	.infoPanelLeft {
		display: flex;
		justify-content: space-between;
		font-size: 16px;
		color: #727272;
	}

	.userId {
		color: #000000;
	}

	.payMethodeContainer .payButton.payButtonOne {
		width: 95%;
		height: 60px;
		padding: 10px;
	}

	.payButton_TopUp {
		padding-left: 26%;
	}
}
