.catCatContainerMobile {
	margin-bottom: 63px;
}
.filterSortPanelContainer {
	margin: 14px 0 21px 0;
}
.cardsContainerRow {
	padding: 0 19px 0 11px;
}
.cardsContainerVertical {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0 5px;
	/*justify-content: space-between;*/
	column-gap: 20px;
	row-gap: 15px;
}
.advertisingContainer {
	width: 330px;
	height: 230px;
	background: #cdcdcd;
	border-radius: 5px;
	margin: 25px auto;
}
.smallAdvertisingContainer {
	width: 330px;
	height: 115px;
	background: #cdcdcd;
	border-radius: 5px;
}
.expandButtonContainer button {
	font-family: var(--myMainFontRegular);
	font-size: 12px;
	line-height: 12px;
	color: #1754a6;
}
