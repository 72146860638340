.modalContainer {
	width: 634px;
	height: 254px;
	background: #ffffff;
	border-radius: 4px;
	caret-color: transparent;
}

.topTitle {
	padding-right: 18px;
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 30px;
	background: #fff3c9;
	border-radius: 5px 5px 0 0;
}

.topTitle h4 {
	display: flex;
	align-items: center;
	font-family: var(--myMainFontRegular);
	font-size: 18px;
	text-transform: uppercase;
	color: #3d3d3d;
}

.topTitle span {
	margin-right: 12px;
	cursor: pointer;
}

.modalContent {
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.inputWrapper {
	width: 100%;
	max-width: 400px;
	margin-bottom: 20px;
}

.inputWrapper label {
	display: block;
	margin-bottom: 5px;
	font-size: 14px;
	color: #727272;
}

.phoneInputContainer {
	width: 100%;
}

.phoneInput {
	width: 100% !important;
	height: 40px !important;
	font-size: 16px !important;
}

.smsInstruction {
	font-size: 16px;
	margin-bottom: 20px;
	text-align: center;
}

.smsCodeInput {
	width: 514px;
	max-width: 100%;
	padding-left: 26px;
	padding-right: 26px;
	height: 40px;
	border-radius: 4px;
	border: 1px solid #e7e7e7;
	background: #ffffff;
	color: #727272;
	font-size: 16px;
	text-overflow: ellipsis;
	/* width: 100%;
	max-width: 200px;
	height: 40px;
	font-size: 16px;
	padding: 0 10px;
	margin-bottom: 20px; */
}

.button {
	display: inline-flex;
	justify-content: center;
	width: max-content;
	text-align: center;
	padding: 10px 25px;
	border-radius: 5px;
	border: 1px solid #727272;
	color: #727272;
	text-transform: uppercase;
	background-color: #ffffff;
	cursor: pointer;
	margin-top: 20px;
}

.button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.errorMessage {
	color: red;
	margin-top: 10px;
}

.smsVerificationContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.smsInstruction {
	margin-bottom: 20px;
	text-align: center;
}

.inputGroup {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

@media (min-width: 600px) {
	.inputGroup {
		flex-direction: row;
		justify-content: center;
	}

	.inputWrapper {
		margin-right: 10px;
	}
}

.inputWrapper {
	display: flex;
	flex-direction: column;
}

.smsCodeInput {
	width: 200px;
	height: 40px;
	font-size: 16px;
	padding: 0 10px;
	margin-top: 5px;
}

.button {
	margin-top: 20px;
}

@media (min-width: 600px) {
	.button {
		margin-top: 0;
	}
}
