/*===============Delivery start===============*/
.deliveryTable {
	margin-left: 15px;
	margin-top: 26px;
	display: flex;
	flex-direction: column;
	gap: 18px;
}

.deliveryButton {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 17px;
	padding-right: 14px;
	width: 198px;
	height: 50px;
	background: #ffffff;
	border: 1px solid #bdbdbd;
	transition: border 0.3s ease;
	border-radius: 4px;
}

.deliveryButton:hover {
	border: 1px solid #dfdfdf;
}

.deliveryInfo {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.deliveryInfo .deliveryName {
	color: #373737;
}

.deliveryInfo .deliveryTime {
	color: #9b9b9b;
}

.deliveryLogo img {
	width: 32px;
	height: 32px;
}

/*===============Delivery end===============*/
