.createAdCheckboxContainer {
	display: flex;
	align-items: center;
	position: relative;
}
.createAdCheckbox {
	opacity: 0;
	position: absolute;
	z-index: -1;
}
.createAdCheckbox + label {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	column-gap: 10px;
	text-decoration: none;
	overflow: hidden;
}
.createAdCheckbox + label::before {
	content: "";
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	background-color: #ffffff;
	border: 1px solid #727272;
	border-radius: 3px;
	cursor: pointer;
}
.createAdCheckbox:checked + label::before {
	content: url("/src/images/checkBox.jpg");
	border: 1px solid #00a9ce;
}
