/* src/components/Collapse.module.css */

.collapseContainer {
	width: 100%;
	border-bottom: 1px solid gainsboro;
}

.collapseHeader {
	width: 100%;
	/* background-color: #007bff; */
	color: black;
	padding: 35px 20px;
	text-align: left;
	font-size: 1.1rem;
	border: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 10px;
}

.collapseHeaderRight {
	display: flex;
	align-items: center;
	gap: 30px;
}

.title {
	font-size: 24px;
	font-weight: 400;
	text-align: left;
	color: black;
	cursor: unset;
}

.collapseText {
	font-size: 14px;
	font-weight: 400;
	text-align: left;
	color: #727272;
}

.arrow {
	transition: transform 0.3s ease;
}

.open {
	transform: rotate(180deg);
}

.collapseContent {
	transition: height 0.3s ease;
}

.contentInner {
	padding: 15px 20px;
}

.switchContainer {
	display: flex;
	align-items: center;
	gap: 30px;
}

.switchContainer a {
	font-size: 18px;
	color: #00a9ce;
	text-decoration: underline;
}

.switchContainer h5 {
	color: #3d3d3d;
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 0;
}

.switchContainer svg {
	margin-top: 0;
}
.switchIconPlay {
	position: absolute;
	top: -0.5px;
	right: -17px;
	z-index: 10;
}
.switchIconPause {
	position: absolute;
	top: -3px;
	right: 18px;
	z-index: 10;
}
