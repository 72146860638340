.wrap {
  padding-bottom: 50px;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}
.form:not(:last-child) {
  margin-bottom: 80px;
}
.title {
  text-transform: uppercase;
	font-family: var(--myMainFontMedium);
  font-weight: 500;
  margin-bottom: 30px;
  font-size: 18px;
  color: #1a1a1a;
}
.fieldWrap {
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
}

.comment {
  font-family: var(--myMainFontRegular);
  font-size: 16px;
  line-height: 18px;
  padding: 20px;
  border: 1px solid #DCDCDC;
  border-radius: 4px;
  height: 140px;
  resize: none;
}
.comment:focus {
  padding-bottom: 19px;
  border-bottom: 2px solid #00a9ce;
}
.error .comment {
  padding-bottom: 19px;
  border-bottom: 2px solid #f60909;
}

.field {
  font-family: var(--myMainFontRegular);
  font-size: 16px;
  line-height: 20px;
  padding: 14px 16px;
  border: 1px solid #DCDCDC;
  border-radius: 4px;
  width: 100%;
  max-width: 336px;
}
.field:focus {
  padding-bottom: 13px;
  border-bottom: 2px solid #00a9ce;
}
.error .field {
  padding-bottom: 13px;
  border-bottom: 2px solid #f60909;
}
.help {
  font-family: var(--myMainFontRegular);
  font-size: 18px;
  line-height: 24px;
  color: #00A9CE;
}
.comment {
  caret-color: #1a1a1a;
}
.comment:focus + .help {
  color: rgba(0, 169, 206, 1)
}
.error .help {
  color: #f60909;
}
.commentHelpWrapper {
  display: flex;
  justify-content: space-between;
  gap: 14px;
}
.sizeCounter {
  font-family: var(--myMainFontRegular);
  font-size: 18px;
  line-height: 24px;
  color: #00A9CE;
}
.submitButton {
  width: 100%;
  max-width: 336px;
  border: 1px solid #A3A1A1;
  border-radius: 4px;
  padding: 14px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--myMainFontRegular);
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #3D3D3D;
  cursor: pointer;
}
.submitButton:hover {
  border-color: #3d3d3d;
}
.submitButton:disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.authorIcon {
  position: absolute;
  left: 10px;
  top: 13px;
  pointer-events: none;
}
.withIcon {
  padding-left: 36px;
}

.commentsList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.commentTop {
  display: flex;
  align-items: center;
  gap: 15px;
  font-family: var(--myMainFontRegular);
  font-size: 16px;
  line-height: 20px;
  color: rgba(141, 141, 141, 1);
  margin-bottom: 7px;
}
.author {
  color: #1a1a1a;
}
.text {
  font-family: var(--myMainFontRegular);
  font-size: 16px;
  line-height: 20px;
  color: #1a1a1a;
}
.showMore {
  margin-left: 8px;
  color: rgba(114, 114, 114, 1);
  cursor: pointer;
  text-decoration: underline;
}
.showMore:hover {
  text-decoration: none;
}

.expandButton {
  padding-top: 30px;
}
.expandButton button {
  color: #1754a6;
  text-transform: capitalize;
}

@media screen and (width <= 1024px) {
  .field {
    max-width: 100%;
  }
  .submitButton {
    max-width: 100%;
  }
  .form:not(:last-child) {
    margin-bottom: 40px;
  }
  .wrap {
    padding-bottom: 20px;
  }
}