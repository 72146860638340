.wrapper {
	width: 100%;
	background-color: #ffffff;
	min-height: 100vh !important;
}

.searchResultContainer {
	width: 100%;
	padding-top: 24px;
	background-color: #ffffff;
}

.contentContainer {
	display: flex;
	gap: 28px;
}
.sideBarSelect {
	width: 100%;
	margin: 25px 0 30px 0;
}
.dropdownArrow svg {
	stroke: #606669;
}
.dropdownArrow:hover svg {
	stroke: #3d3d3d;
}
.sideBar {
	flex: 0 0 355px;
}
.result {
	flex: 1 1 100%;
}
.expandButtonWrapper {
	margin-top: 50px;
	display: flex;
	justify-content: center;
}
.interlinkContainer {
	margin-bottom: 40px;
}
.mainTitle {
	font-size: 24px;
	margin-top: 10px;
}

/*=========Side bar elements start=================*/
.sideBarTitle {
	padding-left: 22px;
	margin-bottom: 18px;
	margin-top: 26px;
}

.sideBarTitle h4 {
	font-family: var(--myMainFontMedium) sans-serif;
	font-size: 18px;
	line-height: 209.5%;
	color: #000000;
}
.saveSearch {
	font-family: var(--myMainFontLight) sans-serif;
	font-size: 14px;
	line-height: 15px;
	margin-left: 12px;
	color: #9d9d9d;
	cursor: pointer;
}
.saveSearch:hover {
	color: #3381bd;
}
.sideBarMenu {
	background: #fdfdfe;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.12);
	border-radius: 10px;
}

.sideBarMenuContent {
	padding: 15px 45px 25px 22px;
	max-height: 850px;
	overflow-y: auto;
}

.sideBarMenuContent .contentItem {
	max-width: 300px;
	display: flex;
	justify-content: space-between;
	font-family: var(--myMainFontMedium) sans-serif;
	font-size: 18px;
	line-height: 209.5%;
	color: #939393;
	cursor: pointer;
}
.sideBarMenuContent .contentItem:hover {
	color: #646464;
}
.sideBarMenuContent .contentItem .categoryName {
	max-width: 90%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
/*=========Side bar elements end=================*/
/*=========Commercial start=================*/
.sideBarCommercial {
	width: auto;
	max-width: 355px;
	/*width: 100%;*/
	margin-top: 50px;
	margin-bottom: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 45px;
	overflow: hidden;
}

.commercialBox {
	height: 250px;
	width: 300px;
}

/*=========Commercial end=================*/
.result {
	/*flex: 0 0 923px;*/
	/*width: 100%;*/
	margin-top: 20px;
	margin-bottom: 20px;
}

/*============Result panel start =========*/
.resultFilterPanel {
	display: flex;
	max-width: 923px;
	justify-content: space-between;
	margin-bottom: 20px;
}
.resultFilterPanelSelect {
	display: flex;
	gap: 20px;
}
.panelSelectFilterAll {
	width: 180px;
}
.panelSelectFilterState {
	width: 100px;
}
.resultFilterPanelSelect span::after {
	content: "";
	width: 20px;
	height: 20px;
	top: 0;
	background-color: #ffd74a;
	position: relative;
}
.resultFilterPanelViewButton {
	display: flex;
	gap: 15px;
}
.menuSVGButton {
	position: relative;
}
.menuSVGButton .hidden {
	display: none;
}
.menuSVGButton .hidden::after {
	content: "";
	position: absolute;
	top: -5px;
	right: 100px;
	width: 43px;
	height: 17px;
	background: url("/src/images/ArrowForCategoryPopup.svg");
}
.menuSVGButton:hover .hidden {
	position: absolute;
	padding: 28px 0 28px 21px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 217px;
	height: 119px;
	top: 40px;
	right: -115px;
	background: #ffffff;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.35);
	border-radius: 5px;
	z-index: 50;
}
.topRow,
.bottomRow {
	display: flex;
	align-items: center;
	gap: 9px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 14px;
	color: #727272;
}
.topRow {
	font-size: 12px;
}
.bottomRow {
	margin-left: 5px;
}
.bottomRow svg {
	margin-right: 10px;
	stroke: #1754a6;
}
.bottomRow svg:hover {
	stroke: #2b7eed;
}

.resultFilterPanelViewButton button {
	/*background-color: #f8f8f9;*/
}
.resultFilterPanelViewButton button svg {
	fill: #cecece;
}
.resultFilterPanelViewButton button svg:hover {
	fill: #b1b1b1;
}
.resultFilterPanelViewButton .active svg {
	fill: #00a9ce;
}
/*============Result panel end =========*/

.resultCardsContainer {
	max-width: 923px;
	width: 932px;
	/*display: flex;*/
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1px;
	background-color: #dcdcdc;
	caret-color: transparent;

	/*overflow: hidden;*/
}

.resultCardsContainer.grid4 {
	grid-template-columns: repeat(4, 1fr);
}
.resultCardsContainer.row {
	grid-template-columns: 1fr;
}
/*.resultCardsContainer .border {
    background-color:#DCDCDC ;
}*/
.whiteFon {
	background-color: white;
	position: absolute;
	left: 500px;
	top: -1px;
	/*margin-left:200px;*/
	width: 800px;
	height: 12px;
	z-index: 1;
	caret-color: transparent;
}
/*========Filters menu ====================*/
.filtersMenuContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
.seeButtonContainer {
	margin: 0 auto;
}
.seeButton {
	min-width: 338px;
	width: 100%;
	height: 50px;
	background-color: #1dadeb;
	border-radius: 5px;
	color: #ffffff;
	font-size: 16px;
}
/*========Filters menu end=========================*/
/*========Result Over Region start=====================*/
.resultOverRegionContainer {
	margin: 60px auto;
	display: flex;
	padding-left: 30px;
	gap: 64px;
	max-width: 100%;
	height: 96px;
	align-items: center;
	background: #ceddff;
	border-radius: 8px;
}
.resultOverRegionText {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 18px;
	line-height: 18px;
	color: #3d3d3d;
}
/*========Result Over Region end      ==============*/

/*=========Pagination start ==============================*/
.paginatorContainer {
	margin-top: 35px;
	margin-bottom: 48px;
	display: flex;
	justify-content: center;
	width: 100%;
}
/*=========Pagination end ==============================*/
.filtersElement.filterPrice {
	margin-bottom: 8px;
}
.seoContainer {
	margin-bottom: 18px;
}
.seoContainer .seoTitle {
	padding: 20px;
	font-size: 18px;
	color: #444444;
	width: 100%;
	text-align: center;
	font-family: var(--myMainFontLight) sans-serif;
}
@media (max-width: 1700px) {
	.sideBar {
		flex: 0 0 330px;
	}
	.contentContainer {
		gap: 24px;
	}
}
@media (max-width: 1280px) {
	.resultFilterPanelViewButton {
		margin-right: 20px;
	}
	.resultOverRegionContainer {
		padding: 0 30px;
		gap: 64px;
		max-width: 90%;
	}
}
@media (max-width: 1235px) {
	.resultFilterPanelViewButton {
		margin-right: 40px;
	}
	.resultOverRegionContainer {
		padding: 0 30px;
		gap: 64px;
		max-width: 75%;
	}
}
@media (max-width: 1160px) {
	.resultFilterPanelViewButton {
		margin-right: 60px;
	}
}
@media (max-width: 1080px) {
	.resultFilterPanelViewButton {
		margin-right: 80px;
	}
	.filtersMenuContainer {
		padding-bottom: 100px;
		align-items: center;
	}
}
@media (max-width: 700px) {
	.seoContainer {
		margin-bottom: 38px;
	}
}
