.productCardWrapper {
	width: 241px;
	height: 403px;
}

.productCardContainer {
	width: 200px;
	border-radius: 12px;
	padding: 10px 5px;
	background: #ffffff;
	/*background-color: #00D1FF;*/
	position: relative;
	height: 100%;
}

.productCardContainer:hover {
	transition: 0.1s;
	transform: scale(1.1);
	box-shadow:
		-14px 4px 33px rgba(180, 186, 193, 0.17),
		16px 17px 30px rgba(63, 75, 95, 0.07);
	border-radius: 12px;
	position: relative;
	z-index: 5;
}

.productCardContainer.inSwiper:hover {
	transform: none;
	cursor: pointer;
}
.productCardContainer.work {
	padding: 10px;
	display: flex;
	flex-direction: column;
}
.workCardImage {
	min-height: 120px;
	width: 100%;
}
.workCardImage > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 8px;
}

.topFlag {
	position: absolute;
	top: 4px;
	left: 8px;
}

.cardImage {
	width: 190px;
	height: 190px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}

.cardImage img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	/*max-width: 100%;
    height: auto;*/
}


.cardTitle {
	display: flex;
	justify-content: space-between;
	height: 65px;
	max-width: 100%;
	overflow: hidden;
	line-height: 22px;
	text-overflow: ellipsis;
	line-clamp: 2;

	/*margin-top: 21px;*/
	margin-top: 8px;
	/*padding-left: 4px;*/
}
.cardTitle.work {
	padding-bottom: 5px;
	margin-top: 10px;
	margin-bottom: 10px;
	height: auto;
}

.cardTitle p {
	flex: 1 1 90%;
	padding-right: 10px;
	font-family: var(--myMainFontRegular);
	/*line-height: 22px;*/
	color: #565656;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}

.cardTitle svg {
	flex: 0 0 10%;
	margin-top: 10px;
	fill: #c8c8c8;
	transition: 0.4s;
}
.cardTitle.work svg {
	margin-top: 0;
}

/*.cardTitle svg:hover {
    fill: #FFD74A;
}*/

.cardWorkContent {
	margin-top: auto;
	/* margin-bottom: 10px; */
	font-size: 14px;
	line-height: 18px;
	color: #777777;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.productCardContainer:hover .cardTitle svg {
	fill: #ffd74a;
}

.productCardContainer:hover .date {
	color: #8d8d8d;
}

.cardPrice {
	display: flex;
	justify-content: space-between;
	height: 28px;
	margin-top: 43px;
	/*margin-left: 4px;*/
	font-family: var(--myMainFontBold);
	font-size: 14px;
	line-height: 28px;
	color: #262424;
}

.cardPrice svg {
	margin-right: 5px;
}

.date {
	/* font-family: 'Open Sans',sans-serif;
     font-style: normal;
     font-weight: 400;*/
	max-width: 90%;
	font-size: 10px;
	line-height: 130%;
	color: #c4c4c4;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	/*margin-left: 4px;*/
}
.date span {
	text-overflow: ellipsis;
}
/*===========Horizontal start======================*/
.horizontal {
	position: relative;
	padding: 8px;
	display: flex;
	justify-content: space-between;
	/*height: 112px;*/
	width: 240px;
	background: #ffffff;
	box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	margin-top: 10px;
	cursor: pointer;
	caret-color: transparent;
	overflow: hidden;
}

.horizontal:hover {
	filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
}

.horizontal:not(:last-child) {
	/* margin-right: 10px; */
}

.horizontal:hover .rightHorizontalBlockBottom svg {
	fill: #ffd74a;
}

.horizontal:hover {
	transition: 0.1s;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.horizontal .cardImage {
	width: 94px;
	height: 92px;
}
.rightHorizontalBlock {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-weight: 700;
	font-size: 16px;
	line-height: 28px;
	color: #262424;
}

.rightHorizontalBlock p {
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	line-height: 19px;
	color: #3d3d3d;
}

.rightHorizontalBlockTop {
	padding: 5px 13px 0 13px;
	max-width: 130px;
	max-height: 40px;
	overflow: hidden;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	display: inline-block;
	display: -webkit-box;
	font-weight: 400;
}

.rightHorizontalBlockBottom {
	padding: 27px 0 5px 14px;
	display: flex;
	flex-direction: column;
}

.rightHorizontalBlockBottom p {
	display: flex;
	justify-content: space-between;
}

.rightHorizontalBlockBottom svg {
	position: relative;
	z-index: 3;
	cursor: pointer;
	fill: #c8c8c8;
	transition: 0.4s;
}

/*============Result card start=========*/

.resultSearchCardContainer {
	max-height: 405px;
	background: #f5f5f5;
	/*border-right:1px solid #DCDCDC;*/
}

.resultSearchCardContainer:hover {
	background: #ffffff;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.resultSearchCardContainer a {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 15px 11px;
}

.resultCardImage {
	width: 255px;
	position: relative;
}

.resultCardImage img {
	width: 100%;
}

.resultTopFlag {
	position: absolute;
	top: 15px;
}

.resultCardTitle,
.resultCardPrice {
	justify-content: start;
	font-family: var(--myMainFontRegular);
	font-size: 16px;
	line-height: 130%;
	color: #3d3d3d;
}

.resultCardPrice {
	display: flex;
	justify-content: space-between;
}

.resultCardLocation,
.resultCardBottomFunction {
	font-family: var(--myMainFontRegular);
	font-size: 16px;
	line-height: 130%;
	color: #939393;
}

.resultCardBottomFunction {
	display: flex;
	justify-content: space-between;
}

/*============Result card end=========*/
@media (max-width: 2200px) {
	.cardImage img {
		max-width: 190px;
		/*height: 190px;*/
	}

	.productCardWrapper {
		width: 201px;
		height: 365px;
	}

	.cardTitle {
		height: 43px;
	}

	.leftHorizontalBlock .cardImage {
		width: 94px;
		height: 94px;
	}
	.leftHorizontalBlock .cardImage img {
		width: 100%;
		height: 100%;
	}
	/*.horizontal {
        width: 240px;
        height: 114px;
    }*/

	.rightHorizontalBlock p {
		font-family: var(--myMainFontRegular);
		font-size: 11px;
		line-height: 14px;
		color: #000000;
	}
}

@media (max-width: 1025px) {
	.horizontal {
		padding: 9px 7px 11px 9px;
		min-width: 100%;
	}

	.horizontal:not(:last-child) {
		margin-right: 0;
	}

	.leftHorizontalBlock {
		margin-right: 10px;
	}
	.rightHorizontalBlockTop {
		padding: 5px 13px 0 0;

		/*max-height: 40px;*/

		/*white-space: nowrap;*/
	}
	.rightHorizontalBlockTop p {
		font-size: 10px;
		line-height: 14px;
		color: #000000;
		width: 84px;
		height: 28px;
		text-overflow: ellipsis;
	}
	.savedFiltersTitle {
		font-family: var(--myMainFontRegular);
		font-size: 8px;
		line-height: 8px;
		color: #9d9d9d;
	}
	.rightHorizontalBlockBottom {
		padding: 0;
		display: flex;
		flex-direction: row;
		min-width: 100%;
		justify-content: space-between;
	}

	.price span:first-child {
		font-family: var(--myMainFontBold);
		font-size: 10px;
		color: #262424;
	}
	.price span:last-child {
		margin-left: 13px;
		font-family: var(--myMainFontRegular);
		font-size: 10px;
		text-decoration-line: line-through;
		color: #262424;
	}

	.rightHorizontalBlockBottom .location {
		font-family: var(--myMainFontRegular);
		font-size: 10px;
		line-height: 18px;
		color: #898c8f;
		width: 50%;
		/*height: 15px;*/
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
