.mainSearchLocationTop {
	display: flex;
	/*flex: 1 1 307px;*/
	flex: 0 0 25%;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	padding-left: 20px;
	padding-right: 16px;
	/* gap: 13px;*/
	border-left: 1px solid #dadada;
	cursor: pointer;
}
.mainSearchLocationLeft {
	display: flex;
	gap: 20px;
}
.mainSearchLocationLeft .mainSearchLocationInput {
	width: 250px;
	padding-top: 2px;
	color: #606669;
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.mainSearchLocationLeft input::placeholder {
	color: #a5a5a5;
}
.mainSearchLocationLeft svg {
	fill: #d2d2d2;
}
.mainSearchLocationLeft:hover svg {
	fill: #9d9d9d;
}
.mainSearchLocationArrow {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}
/*===========Menu start =============*/
.mainSearchLocationMenu {
	position: relative;
	width: 360px;

}
.locationSelectContainer {
	position: absolute;
	top: 6px;
	width: 100%;
	min-height: 100px;
	background-color: #ffffff;
	box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.1);

}
.locationHistoryContainer {
	padding: 15px;
}
.locationHistoryTop {
	padding-left: 30px;
	padding-bottom: 20px;
	font-family: var(--myMainFontRegular);
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid rgba(231, 231, 231, 1);
}
.recentlyLoc {
	color: #3d3d3d;
}
.recentlyClear .clearButton {
	color: #487dc3;
}
.locationHistoryList {
	margin-top: 17px;
	padding-left: 7px;
}
.locationHistoryMenu {
}
.locationHistoryMenuElement {
	padding-bottom: 25px;
	display: flex;
	gap: 10px;
	cursor: pointer;
	color: #727272;
	font-size: 16px;
}
.locationHistoryMenuElement:hover {
	color: #3d3d3d;
}
.menuElIcon {
}
.menuElLocationName {
}
.locationSearchingContainer {
	padding: 1px 6px 38px 15px;
}
.searchingVariantsList {
	max-height: 475px;
	overflow: auto;
}
.searchingVariantsEl {
	padding: 10px 0 10px 30px;
	border-bottom: 1px solid #e7e7e7;
	cursor: pointer;
}
.cityCityArea {
	font-size: 16px;
	color: #727272;
}
.district {
	min-height: 10px;
	font-size: 12px;
	color: #a4a4a4;
}
.searchingVariantsEl:hover .cityCityArea {
	color: #1c1c1c;
}

.searchingVariantsList::-webkit-scrollbar {
	width: 6px; /* Ширина скроллбара */
}
/* Стили для "бегунка" (ползунка) */
.searchingVariantsList::-webkit-scrollbar-thumb {
	background-color: #00a9ce;
	/* Цвет бегунка */
	border-radius: 6px; /* Закругленные углы бегунка */
}
/* Стили для трека (фона) */
.searchingVariantsList::-webkit-scrollbar-track {
	background-color: #f1f1f1; /* Цвет фона трека */
	border-radius: 26px;
}
.rotate svg {
	transform: rotate(180deg);
	transition: 300ms;
}
