.mobileCabinetHeaderContainer {
	width: 100%;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 9px 10px 9px 10px;
	background: #2b2a38;
	position: fixed;
	z-index: 50;
	gap: 7px;
}
.leftSide {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 49px;
	height: 46px;
}

.rightSide {
	display: flex;
	width: 100%;
	height: 46px;
	align-items: center;
	justify-content: space-between;
}

.avatarAndInfoBlock {
	display: flex;
}

.arrow {
	cursor: pointer;
	/* margin-right: 20px; */
}
.avatar {
	width: 40px;
	height: 42px;
	overflow: hidden;
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.avatar img {
	width: 100%;
	height: 100%;
	border-radius: 20px;
}
.info {
	font-family: var(--myMainFontRegular);
	font-size: 16px;
	color: #ffffff;
}
.info .infoPhone {
	font-size: 10px;
	color: #dfdfdf;
}
.settings svg {
}
