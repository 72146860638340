.controlPanelButtons {
	display: flex;
	flex-wrap: wrap;
	gap: 9px;
	background: #ffffff;
	border-top: 1px solid #dcdcdc;
	padding: 20px 0 0 15px;
}
.controlPanelButton a {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.controlPanelButton a button {
	width: 76px;
	height: 32px;
	background: #353535;
	border-radius: 3px;
}
.controlPanelButton a span {
	font-size: 9px;
	color: #3d3d3d;
}
