.container {
	width: 100%;
	min-height: 100%;
	padding: 15px 19px;
	color: #9D9D9D;
	overflow: hidden;
	text-overflow: ellipsis;
	border: 1px solid;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
.container.round {
	border-radius: 8px;
}
.containerMobile {
	width: 100%;
	min-height: 100%;
	padding: 10px;
	color: #9D9D9D;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
.type {
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 25px;
	position: absolute;
	top: 15px;
	left: 19px;
}
.typeMobile {
	font-size: 11px;
	line-height: 14px;
	margin-bottom: 16px;
	position: absolute;
	top: 10px;
	left: 10px;
}
.category {
	font-size: 18px;
	line-height: 22px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: break-word;
	text-align: center;
}
.categoryMobile {
	font-size: 13px;
	line-height: 15px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	word-break: break-word;
	text-align: center;
}
.logo {
	object-fit: cover;
	max-width: 100%;
	max-height: 40px;
	display: block;
	margin-top: 5px;
}
.logo.center {
	margin: 5px auto 0;
}
