.cardWrap {
	/* display: flex; */
	width: 100%;
	/* justify-content: center; */
	margin-bottom: 15px;
	margin-top: 5px;
}

.cardContainer {
	padding: 24px 17px 29px 17px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	/* width: 100%;
    max-width: 360px; */
	height: 300px;
	/* background: #E2ECFF; */
	border: 1px solid #e7e7e7;
}

.content {
	color: #000;
	text-align: center;
	font-family: "Comfortaa";
	/* font-family: 'Comfortaa', sans-serif; */
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.content p {
	/* padding: 10px 40px; */
	text-align: center;
}

.title {
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	/*text-transform: uppercase;*/
	color: #000000;
}
.buttons {
	display: flex;
	gap: 37px;
}
.buttons .buttonEnter {
	width: 129px;
	height: 36px;
	background: #ffffff;
	color: #3d3d3d;
	border: 0.5px solid #3d3d3d;
	border-radius: 8px;
	text-transform: uppercase;
}
.buttons .registration {
	width: 160px;
	height: 36px;
	background: #3d3d3d;
	color: #ffffff;
	border: 0.5px solid #898c8f;
	border-radius: 8px;
	text-transform: uppercase;
}

/*From mobiel*/

.contentRegister {
	display: flex;
	margin-left: 10px;
	/* width: 70%; */
	flex-direction: column;
	justify-content: space-around;
}

.contentDescriptionWrap_register {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: flex-start;
	min-height: 150px;
	/* padding: 30px 150px 0px 150px; */
}
.contentDescriptionWrap_noRegister {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

@font-face {
	font-family: "Comfortaa";
	src: url("../../fonts/Comfortaa-Medium.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

.contentDescription {
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	font-family: "Comfortaa", sans-serif;
}

.contentAuthorWrap {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	min-height: 50px;
	/* height: 30%; */
}

.contentDescriptionAuthor {
	/* font-size: 18px;
    line-height: 20px;
    color: #000000;
    padding-right: 20px; */
	text-align: center;
	/* font-family: Open Sans; */
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* padding-bottom: 5px; */
}

.boldText {
	font-weight: bold;
}

@media (max-width: 350px) {
	.buttons {
		flex-direction: column;
		align-items: center;
	}
}
