.fullscreenGallery .image-gallery-index,
.meGallery .image-gallery-index {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 4px;
	color: #fff;
	/*!*line-height: 1;*!*/
	padding: 10px 10px;
	position: absolute;
	left: 20px;
	/*!*top: 485px;*!*/
	top: 90%;
	width: 70px;
	height: 30px;
	z-index: 4;
}
.image-gallery-thumbnail {
	transition: border 0s ease-out;
}
.image-gallery-svg:hover {
	stroke: #00a9ce;
}
.image-gallery-thumbnail:hover {
	border-color: #00a9ce;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
	border-color: #00a9ce;
}
.fullscreenGallery .image-gallery-image {
	/*width: 1176px;*/
	height: 773px;
}
.meGallery .image-gallery-image {
	width: 738px;
	height: 585px;
	overflow: hidden;
	object-fit: contain;
	cursor: pointer;
}

.meGallery .image-gallery-thumbnails .image-gallery-thumbnails-container img {
	height: 68px;
	object-fit: cover;
}
.fullscreenGallery
	.image-gallery-thumbnails
	.image-gallery-thumbnails-container
	img {
	height: 68px;
	object-fit: cover !important;
}

.meMobileGallery .image-gallery-slide {
	background: transparent;
}

.meMobileGallery .image-gallery-slide img {
	width: 100%;
	height: 260px;
	max-height: 260px !important;
	object-position: top;
}

.meMobileGallery .image-gallery-thumbnail {
	width: 120px;
}

.meMobileGallery .image-gallery-thumbnail img {
	width: 100%;
	height: 75px;
}

.meMobileGallery .image-gallery-index {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 4px;
	color: #fff;
	padding: 10px 10px;
	position: absolute;
	left: 20px;
	top: 80%;
	width: 70px;
	height: 30px;
	z-index: 4;
}

/*.meGallery .gallery-container {
    width: 648px;
    height: 480px;
    overflow: hidden;
}*/
/*
.meMobileGallery .image-gallery-fullscreen-button .image-gallery-svg {
    position: absolute;
    width: 24px;
    height: 24px;
    left:0;
    top: 0;
}
.meMobileGallery .image-gallery-fullscreen-button {
    position: absolute;
    width: 24px;
    height: 24px;
    right:15px;
    top: 10px;
}


.meMobileGallery .image-gallery-index {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    color: #fff;
    line-height: 1;
    padding: 10px 10px;
    position: absolute;
    left: 20px;
    top: 85%;
    width: 70px;
    height: 30px;
    z-index: 4
}

.fullscreenGallery .image-gallery-index {
    top: 100%;
}
.image-gallery-svg {
    stroke: #ffffff;

}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
    !*max-width: 738px;*!
    max-width: 100px;
}

.fullscreenGallery .image-gallery-thumbnails .image-gallery-thumbnails-container {
    width: 150px;
    height:90px;
}




.fullscreenGallery .image-gallery-thumbnails {
    margin-top: 16px;
    height: 20px;
}

@media (max-width:1500px ) {
    .meGallery .image-gallery-image {
        width: 648px;
        height: 480px;
    }
    .image-gallery-thumbnails .image-gallery-thumbnails-container {
        max-width: 648px;
    }
    .meGallery .image-gallery-thumbnails .image-gallery-thumbnails-container img {
        height: 64px;
    }
}
@media (max-width:500px ) {
    .meMobileGallery .image-gallery-index {
        top: 80%;
    }
}
*/
