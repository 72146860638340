/*.mainSearchContainer {
    display: flex;
    padding: 0 0 0 15px;
    position: absolute;
    top: 74px;
    width: 100%;
    !*background: #FFFFFF;*! !*https://app.clickup.com/t/8693y8ayj*!
    left:50vmax;
    transform:translate(-50%, -50%);
    border-radius: 9px;
}*/
/*.inputBlock {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px;
    !*margin: 30px 47px;*!
    !*margin: 30px 20px;*!
    margin: 30px 30px 30px 0;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    border: 1px solid #CECECE;
    z-index: 1;
    flex-wrap:wrap;
    caret-color: transparent;
}*/

/*.inputBlock .inputBlockInput {
    !*flex: 1 1 737px;*!
    !*flex: 1 1 60%;*!
    position: relative;
    height: 100%;
    background: #FFFFFF;
    padding-left: 23px;
    border-radius: 8px 0 0 0;
    caret-color: initial;
}*/
/*.inputBlock .inputBlockInput::placeholder {
    color: #353434;
    opacity: 0.5;
}*/

/*.inputBlock button {
    !*flex: 1 1 177px;*!
    !*flex: 1 1 16.62%;*!
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    background-color: #FFD74A;;
    border-radius: 0 8px 8px 0;
}*/
/*.inputBlock button:hover {
    !*transition: 1s;*!
    background-color:#FFE27C;
}*/
/*
.inputBlock button p {
    font-family:var(--myMainFontBold);
    font-size: 16px;
}*/
/*==============Search popup Panel start==========*/
.wrapper {

}
.mainSearchPopup {
	position: absolute;
	top: 0;
	/*width: 85.9%;*/
	width:calc(100% - 178px)  ;
	max-width: 100%;
	height: 300px;
	background: #ffffff;
	border-radius: 5px 0 10px 10px;
	/*border-top: 1px solid #dadada;*/
	overflow: hidden;
	box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.1);
}
.mainSearchTopPanel {
	display: flex;
	justify-content: space-between;
	margin-top: 23px;
}
.mainSearchTopPanel .history {
	margin-left: 24px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 16px;
	color: #3d3d3d;
}
.cleanHistory {
	margin-right: 16px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 16px;
	color: #487dc4;
	cursor: pointer;
}
.mainSearchContent {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin: 15px 15px 0 24px;
}
.mainSearchContent ul li {
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.mainSearchContent ul li span {
	margin-left: 10px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 16px;
	color: #646464;
	overflow: hidden;
}
.searchHistoryEl {
	margin-bottom: 10px;
}
.searchHistoryEl span {
	max-width: 100%;
	white-space: nowrap;
	cursor: pointer;
	overflow: hidden;
}
.searchItemInCategory {
	/*max-height: 150px;*/
	padding: 20px 0 20px 24px;
	border-bottom: 1px solid #dadada;
	overflow: hidden;
}
.listSearchResult {
	display: flex;
	flex-direction: column;
	gap: 15px;
	overflow: hidden;
	white-space: nowrap;
	/* width: 100px;*/
}
.listSearchResult .name {
	color: #343434;
	font-size: 14px;
	font-weight: 600;
	line-height: normal;
	cursor: pointer;
}
.listSearchResult .categoryName {
	color: #9f9f9f;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	cursor: pointer;
}
/*==============Search popup Panel end==========*/
@media (max-width: 2200px) {
	.mainSearchContainer {
		top: 67px;
	}
}
@media (max-width: 1024px) {
	.mainSearchContainer {
		top: 67px;
	}
	.mainSearchPopup {
		position: initial;
		/*top: 24px;*/
		width: 100%;
		/*width: 100%;*/
		/*max-width: 1034px;*/
		height: 100vh;
		background: #ffffff;
		border-radius: 5px 0 10px 10px;
		border-top: none;
		overflow: hidden;
		box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.1);
	}
	.mainSearchTopPanel {
		display: flex;
		justify-content: space-between;
		margin-top: 23px;
	}
	.mainSearchTopPanel .history {
		margin-left: 24px;
		font-family: var(--myMainFontRegular) sans-serif;
		font-size: 16px;
		color: #3d3d3d;
	}
	.cleanHistoryMobileContainer {
		display: flex;
		justify-content: flex-end;
		margin-right: 15px;
	}
	.cleanHistoryMobile {
		margin: 2px 0;
		display: inline-block;
		width: 140px;
		height: 40px;
		font-family: var(--myMainFontRegular) sans-serif;
		font-size: 14px;
		color: #487dc4;
		cursor: pointer;
		background-color: #d9d9d947;
		border-radius: 4px;
	}
	.mainSearchContent {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin: 15px 15px 0 24px;
	}
	.mainSearchContent ul {
		width: 100%;
	}
	.mainSearchContent ul li {
		max-width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.mainSearchContent ul li span {
		margin-left: 10px;
		font-family: var(--myMainFontRegular) sans-serif;
		font-size: 16px;
		color: #646464;
	}
	.searchHistoryEl {
		margin-bottom: 10px;
	}
	.searchHistoryEl span {
		max-width: 100%;
		white-space: nowrap;
		cursor: pointer;
		overflow: hidden;
	}
	.searchItemInCategory {
		/*max-height: 150px;*/
		padding: 20px 15px 20px 24px;
		border-bottom: 1px solid #dadada;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.listSearchResult {
		display: flex;
		flex-direction: column;
		gap: 15px;
		overflow: hidden;
		white-space: nowrap;
		/* width: 100px;*/
	}
	.listSearchResult .name {
		color: #343434;
		font-size: 14px;
		font-weight: 600;
		line-height: normal;
		cursor: pointer;
	}
	.listSearchResult .categoryName {
		color: #9f9f9f;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		cursor: pointer;
	}
	.listSearchResult li {
		color: #9f9f9f;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		cursor: pointer;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.searchButtonContainer {
		margin: 0 15px;
	}
	.button {
		width: 100%;
		padding: 10px 5px;
		background-color: #f8f8f8;
		margin: 0 auto;
		white-space: wrap;
	}
	.button p {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
