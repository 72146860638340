.bestsellersContainer {
	width: 100%;
}
.bestsellersContainerTopPanel {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 60px;
	padding-bottom: 25px;
	padding-right: 2px;
	flex-wrap: wrap;
}
.bestsellersLocNameContainer {
	display: flex;
	align-items: center;
	gap: 38px;
}
.bestsellersContainerTopPanelMobile {
	display: flex;
	flex-direction: column;
	padding-top: 25px;
}

.bestsellersPopularProductBlock {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.bestsellersTopPanelTitleMobile {
	color: #3d3d3d;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
}

.locationSelectWrapperMobile {
}

.tabsWrapperMobile {
	display: flex;
	justify-content: center;
	padding: 17px 0px 30px 0px;
}
.bestsellersContainer h5 {
	font-family: var(--myMainFontMedium);
	font-size: 22px;
	line-height: 30px;
	margin-left: 4px;
	color: #2b2a38;
}
.locationSelectWrapper {
	width: 330px;
}
/*========Tabs start======================*/
.tabsContainer {
	caret-color: transparent;
}
.react-tabs__tab--selected {
	background-color: #fee58d;
}
.tabsPanel {
	display: flex;
	/*flex-wrap: wrap;*/
	font-family: var(--myMainFontRegular);
	line-height: 19px;
	color: #3d3d3d;
	box-shadow: 0 0 17px rgba(0, 0, 0, 0.11);
	border-radius: 5px;
}
.tabsElement {
	padding: 8px 20px;
	cursor: pointer;
	background-color: white;
}
.tabsElement button {
	font-size: 14px;
	line-height: 12px;
	color: #3d3d3d;
}
.tabsElement:hover {
	background-color: #f5f5f5;
}
.active {
	background-color: #fee58d !important;
}
.tabsElement:not(:last-child) {
	border-right: 1px solid #dadada;
}
.tabsElement:first-child {
	border-radius: 5px 0 0 5px;
}
.tabsElement:last-child {
	border-radius: 0 5px 5px 0;
}
.tabsElement:hover {
	/*box-shadow:0 1px 1px 0 rgba(0,0,0,0.75);*/
	/*border-color: black;*/
	/*background-color: white;*/
}
/*========Tabs end======================*/
/*==========Content start ================*/
.bestsellersContent {
	display: flex;
	flex-wrap: wrap;
	/* justify-content: center;*/
	gap: 10px;
}

.bestsellersMobileContent {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: space-between;
}

/*==========Content end ================*/
/*==========Bottom start ================*/
.bestsellersBottomPanel {
	display: flex;
	padding: 15px 23px 0 0;
	justify-content: flex-end;
}

.bestsellersBottomPanel button {
	font-family: var(--myMainFontRegular);
	line-height: 19px;
	text-transform: capitalize;
	color: #1754a6;
}

.bestsellersBottomPanel svg {
	margin-left: 10px;
}
/*======== Location Select ============*/
.locationSelectContainer {
	position: relative;
	height: 40px;
}
.locationChanged {
	max-width: 100%;
	padding-bottom: 3px;
	height: 40px;
	display: inline-block;
	/*height: 20px;*/
}
.locationChanged:hover {
	border-bottom: 1px solid #00a9ce;
}
.locationMainName {
	white-space: nowrap;
	overflow: hidden;
	/*padding-left:13px*/
	color: #9d9d9d;
	font-size: 14px;
	font-weight: 400;
	text-overflow: ellipsis;
	cursor: pointer;
	height: 40px;
	/*width: 86px;*/
	display: flex;
	align-items: center;
}
.locationChanged:hover .locationMainName {
	color: #00a9ce;
}
.locationMainName svg {
	min-width: 15px;
	/*min-height: 20px;*/
	margin-bottom: 1px;
	margin-right: 13px;
	fill: #9d9d9d;
}
.locationMainName:hover svg {
	margin-right: 13px;
	fill: #00a9ce;
}

.locationSelectMenuList {
	padding: 15px 20px 23px 25px;
	position: absolute;
	/*min-height: 40px;*/
	top: -5px;
	left: -25px;
	z-index: 50;
	width: 230px;
	max-height: 350px;
	border-radius: 8px;
	background: #fff;
	box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
}
.topSearch {
	display: flex;
	padding-bottom: 12px;
}
.topSearch .input {
	padding-top: 3px;
	padding-left: 14px;
	caret-color: #dadada;
}
.topSearch svg {
	min-width: 15px;
	min-height: 20px;
}
.selectMenuContainer {
	border-top: 1px solid #dadada;
}
.locationSelectMenu {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	/*height: auto;*/
	max-height: 260px;
	overflow-x: hidden;
	overflow-y: auto;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.selectOption {
	width: 170px;
	color: #7b7b7b;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	/*overflow: hidden;*/
	cursor: pointer;
}
.selectOption:hover {
	color: #383748;
}
/* Общие стили для скроллбара */
.locationSelectMenu::-webkit-scrollbar {
	width: 6px; /* Ширина скроллбара */
}

/* Стили для "бегунка" (ползунка) */
.locationSelectMenu::-webkit-scrollbar-thumb {
	background-color: #b3b3b3; /* Цвет бегунка */
	border-radius: 6px; /* Закругленные углы бегунка */
}

/* Стили для трека (фона) */
.locationSelectMenu::-webkit-scrollbar-track {
	background-color: #f1f1f1; /* Цвет фона трека */
	border-radius: 26px;
}

.bannerWrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

/*==========Bottom end ================*/
@media (max-width: 2200px) {
	.bestsellersBottomPanel {
		padding: 0 23px 0 0;
	}
}
@media (max-width: 1285px) {
	.bestsellersContent {
		padding: 0 5px;
		/*justify-content: space-around;*/
		/*column-gap: 20px;*/
		gap: 5px;
	}
}
@media (max-width: 1025px) {
	/*.topSearch {      Ростік, якщо це ти додавав згідно моб дизайну - вибачай, мені здається має бути з відступом.Якщо треба - зміни
        padding-bottom: 0;
    }*/
	.locationSelectMenuList {
		top: -5px;
		left: -220px;
	}
	.topSearch .input {
		padding-top: 3px;
		padding-left: 14px;
		caret-color: #dadada;
	}
	.bestsellersContent {
		padding: 0 5px;
		justify-content: center;
		/*column-gap: 20px;*/
		column-gap: 5%;
		row-gap: 15px;
	}

	.bestsellersContainerTopPanel {
		margin-top: 35px;
		padding: 0 0 30px 0;
	}
	.tabsWrapper {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		/*text-align: right;*/
	}
	.bestsellersContainer h5 {
		color: #3d3d3d;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 22px;
	}
	.bestsellersBottomPanel {
		padding: 35px 0 35px 0;
		justify-content: center;
	}
	.tabsContainer {
		/* margin-top:23px; */
		max-width: 333px;
		width: 100%;
	}
	.tabsPanel {
		max-width: 100%;
		font-family: var(--myMainFontRegular);
		font-size: 12px;
		color: #3d3d3d;
		box-shadow: 0 0 17px rgba(0, 0, 0, 0.11);
		border-radius: 5px;
		justify-content: space-between;
		height: 34px;
	}
	.tabsElement {
		padding: 9px 7px;
		cursor: pointer;
		background-color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		/* min-width: 25%; */
		width: 100%;
	}
	.tabsElement button {
		font-size: 12px;
		line-height: 12px;
		color: #3d3d3d;
	}
	.locationSelectMenuList {
		right: 0;
	}
}
@media (min-width: 614px) and (max-width: 1024px) {
	.bestsellersContent {
		column-gap: 2%;
	}
}
