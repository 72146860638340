.container {
	display: flex;
	flex-direction: column;
	gap: 10px;
	min-width: 354px;
	max-width: 354px;
	padding: 13px 23px 13px 12px;
	border-radius: 5px;
	background: #f8f8f8;

}
.container:hover {
	background: #ececec;
}
.container:hover {
	background: #ececec;
}
.name {
	color: #3d3d3d;
	font-size: 16px;
	font-family: var(--myMainFontBold);
}
.nameAndCross {
	display: flex;
	justify-content: space-between;
}
.buttons {
	display: flex;
	flex-wrap: wrap;
	gap: 21px;
}
.button {
	background-color: #9d9d9d;
	padding: 5px 25px;
	color: #fff;
	border-radius: 4px;
	font-size: 14px;
	line-height: 130%;
}
.button.active {
	background-color: #00cefc;
}
.inputCreateAd input {
	height: 40px;
	border-radius: 5px;
	border: 1px solid #e7e7e7;
	background: #fff;
	padding-left: 20px;
	color: #727272;
	font-size: 14px;
	line-height: normal;
}
.dropdownContent .dropdownMenu {
	/*display: grid;*/
	height: 355px;
	padding-top: 16px;
	padding-right: 20px;
	color: #939393;
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	overflow: auto;
	line-height: 130%; /* 18.2px */
	padding-bottom: 10px;
}
.dropdownContent .dropdownMenu::-webkit-scrollbar {
	width: 4px;
}
.dropdownContent .dropdownMenu::-webkit-scrollbar-thumb {
	background-color: #00a9ce; /* Цвет ползунка */
	border-radius: 26px; /* Закругленные углы ползунка */
	cursor: pointer;
}
.dropdownContent .dropdownMenu::-webkit-scrollbar-track {
	border-radius: 26px;
	background: #eee;
	margin: 16px 0;
	cursor: pointer;
}
.dropdownMenu .dropdownEl {
	display: flex;
	align-items: center;
	padding-left: 7px;
	height: 30px;
	cursor: pointer;
}
.dropdownMenu .dropdownEl.active {
	color: #3d3d3d;
	background-color: #f1f1f1;
}
.dropdownMenu .dropdownEl:not(:first-child) {
	margin: 5px 0;
}
.dropdownMenu .dropdownEl:hover {
	background-color: #f1f1f1;
	color: #3d3d3d;
}
.error {
	color: red;
	border-color: red;
}
.dropdownSelect {
	max-height: 420px;
}
.addNewValueButton {
	padding: 2px 10px 2px 10px;
	border: 1px solid #e7e7e7;

	border-radius: 5px;
	cursor: pointer;
}
.addNewValueButton:hover {
	border-color: #727272;
}
@media (max-width: 430px) {
	.container {
		min-width: 100%;
		max-width: 100%;
	}
}
