.paginatorContainer {
	margin-top: 35px;
	margin-bottom: 48px;
	display: flex;
	justify-content: center;
	width: 100%;
}

.paginatorContainer ul {
	flex-wrap: nowrap;
}

.line {
	height: 1px;
	background: #dadada;
}

.noAds {
	font-size: 20px;
	text-align: center;
	color: #252525;
	margin: 20px 0;
}

.resultCardsContainer {
	max-width: 923px;
	width: 932px;
	/*display: flex;*/
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1px;
	background-color: #dcdcdc;
	caret-color: transparent;

	/*overflow: hidden;*/
}

.resultCardsContainer.grid4 {
	grid-template-columns: repeat(3, 1fr);
}

.bestsellersContainerTopPanel {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* margin-top:60px; */
	padding-bottom: 25px;
	padding-right: 2px;
	flex-wrap: wrap;
}

.cardsContainerRow {
	/* padding: 0 15px; */
	display: flex;
	flex-direction: column;
	gap: 5px;
}
