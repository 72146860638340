.selectContainer {
	position: relative;
	cursor: pointer;
}
.selectInput {
	padding: 10px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* width: 154px; */
	/* height: 31px; */
	background: #ffffff;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
}
.selectInput span {
	color: #727272;
	font-size: 14px;
}
.selectInput svg {
	stroke: #797979;
}
.hiddenOptions {
	position: absolute;
	padding-top: 15px;
	padding-bottom: 22px;
	padding-right: 5px;
	min-width: 345px;
	max-height: 335px;
	left: -2px;
	display: flex;
	flex-direction: column;
	row-gap: 15px;
	background: #ffffff;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
	border-radius: 0 0 5px;
	z-index: 10;
}

/*===================CheckBox start ==================*/
.checkboxInput {
	opacity: 0;
	position: absolute;
	z-index: -1;
}
.checkboxInput + label {
	display: flex;
	user-select: none;
	align-items: center;
	color: #727272;
}
.checkboxInput:checked + label {
	color: #3d3d3d;
}
.checkboxInput + label:hover {
	color: #3d3d3d;
}
/* .checkboxInput+label::before {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid #00A9CE;
    border-radius: 3px;
    margin-left: 7px;
    margin-right: 10px;
    cursor: pointer;
} */

.checkboxInput + label {
	content: "";
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border: 1px solid #00a9ce;
	border-radius: 3px;
	margin-left: 7px;
	margin-right: 10px;
	cursor: pointer;
	min-width: 20px;
}

.selectMenu {
	margin-right: 10px;
	max-height: 344px;
	overflow: auto;
	border-top: 1px solid #e2e2e2;
}

.selectMenu::-webkit-scrollbar {
	width: 4px;
}
.selectMenu::-webkit-scrollbar-thumb {
	background-color: #00a9ce !important; /* Цвет ползунка */
	border-radius: 26px; /* Закругленные углы ползунка */
	cursor: pointer;
}
.selectMenu::-webkit-scrollbar-track {
	padding-right: 20px;
	border-radius: 26px;
	background: #eee;
	margin: 10px 10px 10px 0;
	cursor: pointer;
}

.checkboxInput:checked + label::before {
	content: url("/src/images/checkBox.jpg");
}

.checkBoxContainer {
	padding: 15px 0px;
	display: flex;
	align-items: center;
}

@media screen and (max-width: 1024px) {
	.selectInput {
		gap: 25px;
	}
}
