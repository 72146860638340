.mobileAdsCardContainer {
	border-radius: 5px;
	padding: 13px 8px 17px;
	background: #ffffff;
}
.cardTop {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	padding-bottom: 33px;
	border-bottom: 1px solid #9d9d9d;
}
/* .cardTop a {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 33px;
    border-bottom: 1px solid #9D9D9D;
} */
.cardTop_image {
	/* flex: 0 0 25%; */
}
.cardTop__Main {
	flex: 1 1 75%;
}

.cardTop_image_Image {
	width: 76px;
	height: 78px;
	overflow: hidden;
}

.cardTop_image_Image img {
	width: 100%;
	height: auto;
	border-radius: 8px;
	object-fit: cover;
}
.mainTitle {
	display: flex;
	gap: 10px;
	justify-content: space-between;
}
.mainTitle h4 {
	flex: 0 0 50%;
	width: 127px;
	height: 30px;
	font-family: var(--myMainFontRegular);
	font-size: 12px;
	color: #000000;
	overflow: hidden;
	/* text-overflow: ellipsis; */
}
.priceInfo {
	position: relative;
	flex: 0 0 50%;
	padding-right: 8px;
	display: flex;
	flex-direction: column;
	font-family: var(--myMainFontRegular);
	font-size: 14px;
	align-items: flex-end;
	column-gap: 8px;
	color: #727272;
	justify-content: center;
	margin-left: -6px;
}
.priceSum {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 6px;
	width: 80px;
	height: 25px;
	background: #ffffff;
	border: 1px solid #e7e7e7;
	border-radius: 4px;
	text-align: right;
}

.priceCurrency {
	width: 100%;
	font-size: 10px;
	line-height: 10px;
	color: #727272;
	text-align: right;
}
.mainLocation span {
	font-size: 12px;
	color: #898c8f;
}
.mainAutoRenewal {
	margin-top: 5px;
	display: flex;
	column-gap: 16px;
	align-items: center;
}
.mainAutoRenewal div {
	font-size: 12px;
	line-height: 130%;
	color: #000000;
}
.cardMiddleInfo {
	margin-top: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.date_info {
	font-size: 12px;
	line-height: 10px;
	color: #8d8d8d;
}
.statistics_info {
	display: flex;
	column-gap: 12px;
	font-size: 12px;
	text-align: center;
	color: #3d3d3d;
}
.statistics_info svg {
	margin-right: 10px;
	fill: #3d3d3d;
}
.ratingAdsInfo {
	margin-top: 15px;
	font-size: 10px;
	line-height: 18px;
	color: #3d3d3d;
	text-align: right;
}

.bigButtons {
	display: flex;
	align-items: center;
	column-gap: 21px;
	margin-top: 30px;
}
.bigButtons button {
	width: 100%;
	padding: 11px 15px;
	text-transform: uppercase;
	border-radius: 5px;
}
.bigButtons button:first-child {
	background: #ffffff;
	border: 0.5px solid #3d3d3d;
}
.bigButtons button:last-child {
	background: #3d3d3d;
	border: 0.5px solid #898c8f;
	color: #ffffff;
}

.rejectedTitle {
	margin-top: 25px;
	margin-bottom: 20px;
}
.rejectedInfoBlock {
	padding: 7px 14px 9px 7px;
	background: #fddddd;
	border-radius: 5px;
	color: #6c6c6c;
}
.underReviewContainer h5 {
	margin-top: 5px;
	margin-bottom: 5px;
}
.rejectedInfoDate {
	font-size: 12px;
	color: #727272;
	/* margin-top: 15px; */
	text-align: right;
}

.saveNewPriceButton {
	position: absolute;
	margin-top: 6px;
	height: 25px;
	flex-shrink: 0;
	border-radius: 4px;
	border: 1px solid #0bb82e;
	background: #fff;
	color: #0bb82e;
	font-size: 12px;
	text-transform: uppercase;
	padding: 0px 10px;
	top: 40px;
}

.errorPrice {
	padding-top: 30px;
	text-align: end;
	padding-right: 5px;
}
