.mainCategoryContainer {
	/* background-color: #f6f6f7; */
	padding-bottom: 97px;
}
.mainSearchContainer {
	position: relative;
	display: block;
	/*height: 60px;*/
	margin-bottom: 148px;
}
.mainCategoryAds {
	width: 970px;
	height: auto;
	margin: 50px auto;
}
.mainCategoryContainer ::-webkit-scrollbar {
	margin-right: 5px;
	width: 10px; /* Ширина вертикального скроллбара */
}
/* Стиль для ползунка скроллбара */
.mainCategoryContainer ::-webkit-scrollbar-thumb {
	background-color: #bfbfbf;
	border-radius: 10px; /* Закругленные углы ползунка */
}

/* Стиль для фона скроллбара */
.mainCategoryContainer ::-webkit-scrollbar-track {
	background-color: #d9d9d980; /* Цвет фона скроллбара */
	border-radius: 10px; /* Закругленные углы фона */
}

/* Стиль при наведении на ползунок */
.mainCategoryContainer::-webkit-scrollbar-thumb:hover {
	background-color: #555; /* Цвет ползунка при наведении */
}
@media (max-width: 1025px) {
	.mainCategoryContainer {
		background-color: #f8f8f9;
		margin-bottom: 97px;
	}
}
