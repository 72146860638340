.container {
	width: 40px;
	/*height: 35px;*/
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 5px;

	caret-color: transparent;
	cursor: pointer;
}
.container:hover h5,
.container:hover h5:after {
	color: #ffffff;
}
.container h5 {
	/*width: 24px;*/
	font-family: var(--myMainFontLight) ;
	/*font-weight: 500;*/
	font-size: 16px;
	text-transform: uppercase;
	color: #dadada;
}
.popupWrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}
.languageSelectPopupTop
 {
	padding: 8px 28px;
	width: 87px;
	height: 58px;
	position: relative;
	top: 50px;
	left: -59px;
	background: #ffffff;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: var(--myMainFontBold);
	font-size: 16px;
	text-transform: uppercase;
	line-height: 22px;
	cursor: pointer;
	color: #ababab;
}

.active {
	color: #2b2a38 !important;
}
.switcherContainer {
	display: flex;
	flex-direction: column;
	height: 125px;
	padding: 0 12px;
}

.switcherTitle {
	color: #3d3d3d;
	font-size: 14px;
	padding: 20px 0;
}

.switcherButtonsContainer {
	display: flex;
	gap: 20px;
}

.switcherButton {
	border: 1px solid #898c8f;
	border-radius: 5px;
	width: 158px;
	height: 40px;
}

.switcherButtonActive {
	border: 1px solid #d9d9d9;
	color: #d9d9d9;
	border-radius: 5px;
	width: 158px;
	height: 40px;
}
/*
@media (max-width: 1082px) {
    .container h5:after {
        display: none;
    }
}
*/
@media (max-width: 1082px) {
	.container svg {
		display: none;
	}
}
