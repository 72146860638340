.container {
    width: 100%;
}
.counterImageContainer {
    color: #939393;
    font-size: 0.9em;
}
.gridElementContainer {
    position: relative;
    width: 100%;
    height: 100%;
}
.gridElementImage {
    width: 100%;
    height: 130px;
    object-fit: cover;
    border-radius: 10px;
}
.checkPhotoSvgContainer {

    top: 18px;
    display: flex;
    justify-content: center;
}
.svgDescription {
    width: 60px;
    text-align: center;
    position: absolute;
    top: 80px;
    color: #939393;
    font-size: 14px;
    line-height: normal;
    cursor: pointer;
}
.inputContainer {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 150px;
    padding: 18px 25px;
    border-radius: 4px 4px 0 0;
    background-color: #f1fbfd;
}
.inputText {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #939393;
    cursor: pointer;
}
.containerGrid {
    background-color: #f1fbfd;
    border-radius:0 0 4px 4px;
}

.buttonContainer {
    margin-top: 5px;
    display: flex;
    justify-content: space-around;
}
.button svg {
    width: 30px;
    height: 30px;
}
.button {
    fill: black;
}
.button:hover {
    fill: #494249;
}
.dropZoneContainer {
    width: 100%;
    max-width: 1294px;
    min-height: 112px;
    border: 1px solid #fffbef;
    background: #fff6d9;
    overflow: hidden;
    border-radius: 4px;

}

.dropZoneBlock {
    position: relative;
    min-height: 112px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 38px;
    padding: 20px 20px;
}

.photoSvgBlock {
    height: 70px;
    width: 70px;
    border-radius: 4px;
    background: #ffdb5c;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.svgPlus {
    position: absolute;
    color: white;
}

.dropZoneContainer input {
    min-width: 100%;
    min-height: 100%;
}

.dropZoneInfo {
    width: 100%;
    height: 155px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #939393;
    font-size: 18px;
    line-height: 150%; /* 27px */
}

.checkPhotoSvgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkPhotoModalSvgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkPhotoModalSvgContainer span {
    color: #9d9d9d;
}

/* .checkPhotoSvgContainer span {
    color: #9D9D9D;
} */

.checkPhotoLogoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.checkPhotoLogoContainer p {
    color: #00a9ce;
    font-size: 12px;
}

.svgDescription {
    width: 60px;
    text-align: center;
    position: absolute;
    top: 95px;
    color: #939393;
    font-size: 14px;
    line-height: normal;
}

.photoDescription {
    margin: 20px auto;
    width: 100%;
    text-align: center;
    color: #939393;
    font-size: 16px;
    line-height: 150%; /* 27px */
}

.photo .photoDescription {
    margin-left: 30px;
}

.dropZoneImagesContainer {
    background-color: #fff6d9;
    height: 184px;
    width: 100%;
}

.imageList {
    padding: 20px 0 5px 10px;
    display: flex;
    width: 100%;
    gap: 6px;
    overflow-x: auto;
}

.imageItem {
}

.imageItem button {
    width: 100%;
    text-align: center;
}

.imageContainer {
    width: 70px;
    height: 70px;
    overflow: hidden;
}
.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.deleteButton {
    margin-top: 5px;
    color: #939393;
    text-align: center;
    font-size: 14px;
    line-height: 150%; /* 21px */
}

.addNewPhotoBlock {
    display: flex;
    justify-content: center;
    padding: 25px 0 20px 0;
    background-color: #fdf4d7;
}

.addNewPhotoBlock {
    color: #2b2a38;
    font-size: 12px;
}

/*Choosing A Photo Upload Method Modal*/

.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 60;
}

.modalContainer {
    padding: 25px 16px 16px 15px;
    background: #ffffff;
    border-radius: 5px;
    width: 336px;
    height: 183px;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modalContentWrap {
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.modalContentBlock {
    display: flex;
    gap: 5px;
    padding: 0 5px;
    height: 45px;
    align-items: center;
}

.modalContentBlock:hover {
    background-color: rgb(237, 237, 237);
    border-radius: 5px;
    cursor: pointer;
}

.closeButtonWrap {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.closeButton {
    border-bottom: 1px solid #727272;
    color: #727272;
    font-size: 14px;
}
.errorSize {
     position: absolute;
     top: 70%;
     left: 10%;
     color: red;
     font-size: 12px;
     text-align: center;
     font-family: var(--MyFunnyFont) ;

 }
.removeError {
    margin: 0 5px;
    background-color: #eff9fb;
    border-radius: 10%;
    width: 90%;
    position: absolute;
    bottom: 10px;
    color: red;
    font-size: 14px;
    text-align: center;
    font-family: var(--myMainFontMedium) sans-serif;
}
.errorQuantity {
    color: red;
}
@media (max-width: 1024px) {
    .gridElementContainer {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .gridElementImage {
        width: 70px;
        height: 70px;
        object-fit: cover;
        border-radius: 10px;
    }
    .button svg {
        width: 15px;
        height: 15px;
    }
    .containerGrid {
        background-color: #fdf4d7;
        border-radius:0 0 4px 4px;
    }
    .errorSize {
        position: absolute;
        top: 70%;
        left: 10%;
        color: red;
        font-size:0.5em;
    }
}