.categoryList {
	margin-top: 15px;
	/* height: 300px; */
	/* overflow-y: scroll; */
	/* scrollbar-width: thin; */
	/* scrollbar-color: #cccccc transparent; */
 }
 
 /* Для браузерів на основі Webkit (Chrome, Safari) */
 .categoryList::-webkit-scrollbar {
	width: 5px; /* Ширина скролбару */
 }
 
 .categoryList::-webkit-scrollbar-thumb {
	background: #cccccc; /* Колір скролбару */
	border-radius: 10px; /* Закруглення */
 }
 
 .categoryList::-webkit-scrollbar-thumb:hover {
	background: #aaaaaa; /* Колір скролбару при ховері */
 }
 
 .categoryList::-webkit-scrollbar-track {
	background: transparent; /* Зробити фон прозорим */
 }

.categoryItem {
	margin-bottom: 15px;
}

.category {
	font-size: 18px;
	color: #2B3A38;
	margin-bottom: 10px;
	font-weight: 400;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: var(--myMainFontMedium) sans-serif;
}

.subcategories {
	padding-left: 20px;
}

.subcategory {
	font-size: 18px;
	color: #727272;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: var(--myMainFontMedium) sans-serif;
	transition: all 0.3s ease;
	margin-bottom: 10px;
}

.subcategoryLink {
	transition: all 0.3s ease;
	cursor: pointer;
}

.subcategoryLink:hover {
	color: #000000;
}

.count {
	color: #2b2b2b;
	font-size: 12px;
}

.mobileRubrics {
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 111;
	position: relative;
}

.active {
	font-weight: 700;
}

.adverts {
	margin: 43px auto;
	width: auto;
	height: auto;
}

