.searchCardContainer {
	/*width: 248px;*/
	/*width: 318px;*/
	/*height: 317px;*/
	/*width: 25%;*/
	height: 454px;
	padding: 18px;
	background: #ffffff;
	position: relative;
	cursor: pointer;
	font-family: var(--myMainFontRegular) sans-serif;
	display: flex;
	justify-content: center;
}

.searchCardContainer:hover {
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
	z-index: 2;
	transform: matrix(1.02, 0, 0, 1.02, 0, 0);
}

.searchCardContainer:hover .cardTitle svg {
	fill: #ffd74a;
}
.searchCardContainer:hover .cardTime span {
	color: #939393;
}
.cardWrapper {
	width: 100%;
}
.cardImage {
	/*width: 184px;*/
	/*width: 282px;*/
	width: 100%;
	/*height: 184px;*/
	height: 254px;
	overflow: hidden;
	margin-bottom: 3px;
}
.cardImage img {
	position: relative;

	/*  max-width: 100%;
    height: auto;*/
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.cardImage .topFlag {
	position: absolute;
	top: 24px;
	left: 17px;
}

.cardTitle {
	position: relative;
	display: flex;
	justify-content: space-between;
	/*margin-top: 12px;*/
	/*height: 45px;*/
	text-overflow: ellipsis;
}
.cardTitle span {
	position: absolute;
	top: -5px;
	right: -10px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 38px;
	height: 38px;
}
.cardTitle h4 {
	width: 89%;
	height: 40px;
	/*font-size: 16px;*/
	font-size: 1.15em;
	line-height: 22px;
	color: #3d3d3d;
	overflow: hidden;
}
.cardTitle h4:hover {
	text-decoration: underline;
	color: #171717;
}
.cardPrice {
	display: flex;
	justify-content: space-between;
	height: 21px;
	/*font-size: 14px;*/
	line-height: 28px;
	color: #2b2a38;
}
.cardPrice span {
	/*font-size: 20px;*/
	font-size: 1.43em;
	line-height: 20px;
}
.cardPrice span .cardPriceCurrency {
	/*font-size: 18px;*/
	font-size: 0.9em;
}
.cardTitle svg {
	fill: #c8c8c8;
}

.cardLocation {
	/*height: 20px;*/
	/*font-size: 10px*/
	font-size: 1em;
	line-height: 130%;
	padding-top: 12px;
	color: #939393;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.cardLocation span {
}
.cardTime {
	/*height: 32px;*/
	position: relative;
	/*font-size: 10px;*/
	font-size: 0.89em;
	line-height: 130%;
	display: flex;
	justify-content: space-between;
	/*padding-top: 3px;*/
	color: #c4c4c4;
}

.hiddenPopup {
	display: none;
}
.searchCardContainer:hover .hiddenInfo {
	display: flex;
	flex-direction: column;
}

/*================ROW CARD ==============*/
.cardRowContainer {
	max-width: 100%;
	height: 180px;
	display: flex;
	background: #ffffff;
	/*height: 183px;*/
	gap: 24px;
	padding: 20px 0;
	overflow: hidden;
}

.cardRowContainer .image {
	min-width: 192px;
	/*flex: 0 0 20%;*/
	height: 140px;
	/*height: 100%;*/
	position: relative;
	cursor: pointer;
}

.image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.info {
	flex: 1 1 55%;
	/*margin-right: 110px;*/
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-family: var(--myMainFontRegular) sans-serif;
	overflow: hidden;
	text-overflow: ellipsis;
}

.hiddenPhoto {
	display: none;
}

/*.image img:hover + .hiddenPhoto {
    display: block;
}*/

.infoTitle {
	max-height: 23px;
	font-size: 16px;
	line-height: 130%;
	color: #3d3d3d;
	overflow: hidden;
	text-overflow: ellipsis;
}
.infoTitle h4 {
	cursor: pointer;
}
.infoTitle:hover h4 {
	color: #1e1e1e;
	text-decoration: underline;
}
.infoDescription {
	max-width: 550px;
	max-height: 80px;
	font-size: 12px;
	line-height: 130%;
	color: #939393;
	overflow: hidden;
}

.infoLocation span {
	font-size: 16px;
	line-height: 16px;
	color: #727272;
}

.infoLocation svg {
	margin-right: 9px;
}

.panel {
	padding-right: 10px;
	flex: 1 1 25%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/*align-items: flex-end;*/
}

.panelTop {
	display: flex;
	font-size: 20px;
	line-height: 20px;
	color: #2b2a38;
	justify-content: space-between;
}

.panelTop svg {
	margin-top: 2px;
	margin-right: 45px;
	fill: #c4c4c4;
}

.panelTop svg:hover {
	fill: #ffd74a;
}

.panelBottom {
	display: flex;
	justify-content: flex-end;
	/*margin-left: 70px;*/
}

.panelBottom span {
	padding-top: 3px;
	color: #c8c8c8;
	/*cursor: pointer;*/
}

.cardRowContainer:hover .panelTop svg {
	fill: #ffd74a;
}

.hiddenInfo svg {
	stroke: #cecece;
}

.hiddenInfo svg:hover {
	stroke: rgba(140, 140, 140, 0.7);
}
.cardTime :hover .hiddenPopup.popup {
	display: flex;
	flex-direction: column;
}
.menuButton:hover .hiddenPopup.popup {
	display: flex;
	flex-direction: column;
}
.cardRowContainer:hover + .panelBottom {
	display: block;
	cursor: pointer;
}

.hiddenInfo {
	display: none;
}

/*.hiddenPhoto.image {
    position: absolute;
    width: 336px;
    height: 432px;
    right: 238px;
    top: -75%;
    z-index: 90;
    filter: drop-shadow(0px 4px 45px rgba(0, 0, 0, 0.2));
}*/
.menuButton svg {
	/*margin-left: 59px;*/
}
.cardRowContainer:hover .hiddenInfo {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.cardRowContainer:hover .dateInfo {
	display: none;
}

.cardRowContainer:hover .dayInfo {
	color: #939393;
}

.cardRowContainer .panelBottom {
	position: relative;
}
.hiddenPopup.popup {
	position: absolute;
	top: 35px;
	right: -2.5vh;
	width: 299px;
	height: 171px;
	background: #000000;
	opacity: 0.75;
	border-radius: 5px;
	color: #ffffff;
	z-index: 99;
	padding: 12px 13px;
}
.menuButton {
	display: flex;
	justify-content: space-between;
}
.hiddenPopup.popup::after {
	content: "";
	position: absolute;
	width: 10px;
	height: 10px;
	top: -10px;
	right: 10%;
	background: url("/src/images/Polygon 14.jpg");
	opacity: 0.75;
}

.popup .popupTop {
	margin-bottom: 10px;
}

.popup .popupCenter {
	margin-bottom: 5px;
}

.delCardButton {
	margin-left: 35%;
}

.hiddenPopup.popup button {
	color: #ffffff;
	margin-left: 65%;
	text-decoration-line: underline;
	cursor: pointer;
}
.popup {
	display: none;
}
.menuButton svg:hover .popup {
	display: block;
	cursor: pointer;
}
.hiddenInfo:hover .popup {
	display: block;
	cursor: pointer;
}

.addAD {
	display: flex;
	flex-direction: column;
	gap: 65px;
}

.addAD h2 {
	padding-top: 8px;
}

.addAD a {
	display: flex;
	width: 100%;
	background-color: #53e0ff;
	min-height: 43px;
	border-radius: 8px;
	color: #191848;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	cursor: pointer;
	padding: 0 15px;
}

.addAD a:hover {
	background-color: #97eafe;
}

.addAD a h3 {
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 14px;
	text-transform: uppercase;
}

/*@media (max-width: 2200px) {
    .searchCardContainer {
        width: 207px;
    }
    .cardWrapper {
        width: 100%;
    }
    .cardImage img {
        width: 100%;
        position: relative;
    }
    .cardTitle {
        !*margin-top: 6px;*!
        !*height: 24px;*!
    }
    .cardTitle h4 {

        color: #3D3D3D;
    }
    .cardPrice {
        margin-top:22px ;
        font-family: var(--myMainFontBold);
        color: #262424;
        font-size: 16px;
    }
    .cardLocation {
        padding-top: 2px;
        color: #9D9D9D;
    }
    .cardTime {
        !*margin-bottom: 10px;*!
        !*padding-top: 4px;*!
        font-family: var(--myMainFontRegular);
        color: #C4C4C4;
    }
    .cardTime span {
        !*padding-top: 5px;*!
    }
    .infoDescription {
        display: none;
    }
    .info {
        flex: 1 1 55%;
        margin-right: 0;
    }
}*/
