.topUpAccount {
	margin-bottom: 30px;
	display: flex;
	align-items: center;
}

.topUpAccount span {
	padding-left: 22px;
	margin-right: 30px;
	font-family: var(--myMainFontBold) sans-serif;
	font-size: 21px;
	color: #3d3d3d;
	font-weight: 600;
}

.topUpAccount input {
	width: 322px;
	padding: 10px 20px;
	background: #ffffff;
	border: 1px solid #c8c8c8;
	border-radius: 5px;
	font-family: var(--myMainFontRegular) sans-serif;
	font-size: 18px;
	text-align: left;
	color: #727272;
	padding-left: 10px;
}

.topUpAccount input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.topUpAccount input[type="number"] {
	-moz-appearance: textfield; /* Firefox */
}

.inputContainer {
	display: flex;
	flex-direction: column;
	position: relative;
}

.inputContainer div {
	display: flex;
	align-items: center;
	gap: 35px;
}

.inputContainer button {
	text-align: center;
	width: 187px;
	padding: 9px 20px;
	border-radius: 5px;
	color: #585858;
	border: 1px solid #e9e9e9;
	background: white;
	transition: all 0.3s;
	font-size: 18px;
}
.inputContainer button:hover {
	border: 1px solid #c4c4c4;
	color: #444444;
}

.inputContainer .errorMessage,
.inputContainer .successMessage {
	min-height: 16px;
	color: red;
	font-size: 12px;
	padding-left: 0;
	margin-top: 5px;
	position: absolute;
	bottom: -20px;
}

.inputContainer .successMessage {
	color: green;
}

.inputContainer button:disabled {
	background-color: #f0f0f0;
	color: #9d9d9d;
	border: 1px solid #e0e0e0;
	cursor: not-allowed;
}

@media (max-width: 799px) {
	.topUpAccount {
		flex-direction: column;
		padding: 0 23px;
	}

	.topUpAccount input {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 0;
	}

	.inputContainer {
		width: 100%;
	}

	.inputContainer div {
		gap: 10px;
		flex-direction: column;
	}

	.inputContainer button {
		width: 100%;
	}
}
