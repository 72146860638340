.imageContainer {
	width: 129px;
	height: 129px;
	position: relative;
	text-align: center;
}
.imageContainer input {
	position: absolute;
	top: 0;
	left: 0;
	width: 129px;
	height: 129px;
	opacity: 0;
	cursor: pointer;
}
.blueDragZone {
	display: flex;
	align-items: center;
	gap: 200px;
	width: 100%;
	padding: 18px 25px 22px 22px;
	border-radius: 4px;
	border: 2px solid rgb(236, 248, 251);
	background: #f2fcfe;
	overflow: hidden;
	position: relative;
}
.image {
	position: relative;
	display: flex;
	justify-content: center;
}
.svgDescription {
	width: 60px;
	position: absolute;
	top: 80px;
	color: #939393;
	font-size: 14px;
	line-height: normal;
}
