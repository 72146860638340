.container {
	width: 100%;
	max-width: 240px;
	margin-top: 20px;
}

/*===============Delivery start===============*/
.deliveryTable {
	margin-left: 15px;
	margin-top: 26px;
	display: flex;
	flex-direction: column;
	gap: 18px;
}

.deliveryButton {
	display: flex;
	align-items: center;
	gap: 15px;
	padding-left: 17px;
	padding-right: 14px;
	width: 210px;
	height: 60px;
	background: #ffffff;
	border: 1px solid #bdbdbd;
	transition: border 0.3s ease;
	border-radius: 4px;
}

.deliveryName {
	font-size: 14px;
	font-weight: 700;
	line-height: 17px;
}

.deliveryButton:hover {
	border: 1px solid #dfdfdf;
}

.deliveryInfo {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.deliveryInfo .deliveryName {
	color: #373737;
}

.deliveryInfo .deliveryTime {
	color: #9b9b9b;
}

.deliveryLogo img {
	width: 32px;
	height: 32px;
}

.deliveryButton.selected {
	border: 2px solid #00cefc;
}

/*h3 {
	color: #6f6f6f;
	font-size: 14px;
	font-weight: 400;
}*/

/*===============Delivery end===============*/

@media (max-width: 1024px) {
	.deliveryTable {
		margin-bottom: 20px;
	}
}
