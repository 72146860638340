.myOrdersCardMobileContainer {
	width: 100%;
	padding: 10px 7px;
	background: #ffffff;
	box-shadow: 5px 4px 30px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
}
.cardTop {
	display: flex;
	gap: 10px;
	width: 100%;
}
.cardTop_image {
	min-width: 76px;
	min-height: 78px;
	overflow: hidden;
}
.cardTop_image_Image img {
	max-width: 100%;
	height: 100%;
}
.cardTop_Main {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.mainTitle {
	display: flex;
	min-width: 100%;
	justify-content: space-between;
	cursor: pointer;
	/* align-items: center;*/
}
.mainTitle h4 {
	font-size: 10px;
	color: #000000;
}
.mainTitle svg {
	stroke: #797979;
	margin-bottom: 2px;
}
.mainLocation {
	font-size: 12px;
	line-height: 12px;
	color: #8e8e8e;
}
.mainDeliveryAndPrice {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.mainDeliveryAndPrice .delivery {
	font-size: 10px;
	line-height: 130%;
	color: #8e8e8e;
}
.price {
	color: #727272;
}
.mainDateAndSubmit {
	margin-top: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.mainDate {
	font-size: 12px;
	color: #3d3d3d;
}
.mainSubmit button {
	width: 110px;
	padding: 7px 0;
	font-size: 10px;
	text-transform: uppercase;
	color: #ffffff;
	background: #0bb82e;
	border-radius: 4px;
}
.middleChangedContainer {
	text-align: center;
	margin: 20px auto;
}
.approvedButton {
	width: 100%;
	height: 30px;
	background: #0bb82e;
	border-radius: 4px;
	font-size: 12px;
	text-transform: uppercase;
	color: #ffffff;
}
.approvedForm {
	width: 100%;
	display: flex;
	gap: 25px;
}
.approvedForm input {
	min-width: 55%;
	height: 30px;
	padding-left: 9px;
	font-size: 10px;
	color: #727272;
	background: #ffffff;
	border: 1px solid #cecece;
	border-radius: 4px;
}
.approvedForm button {
	padding: 0 10px;
	height: 30px;
	background: #ffffff;
	border: 1px solid #0bb82e;
	border-radius: 5px;
	font-size: 12px;
	text-align: center;
	text-transform: uppercase;
	color: #0bb82e;
}
.sellerInfoWrapper {
	margin: 20px auto;
}
.arrivalDate {
	font-size: 12px;
	color: #000000;
}
.arrivalDate span {
	color: #727272;
}
