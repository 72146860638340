.redactionAdsCabinetMobileContainer {
	position: relative;
	background: #f5f5f5;
	height: 100%;
}

.bottomControlPanel {
	background: #ffffff;
}
.controlPanelContainer {
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: 99;
}
/*
.tabsButtons {
    display: flex;
    margin-top: 190px;
    width: 100%;
    border-bottom: 1px solid #8D8D8D;
}*/
