.container {
	display: flex;
	align-items: center;
	gap: 12px;
}

.container h5 span,
.container h5 a {
	padding-top: 2px;
	font-family: var(--myMainFontMedium);
	font-size: 14px;
	text-transform: uppercase;
	color: #e5e5e5;
}
.container:hover span,
.container:hover a {
	color: #ffffff;
}
.container h5 a {
	display: block;
	width: 60px;
}
.imageContainer {
	width: 30px;
	height: 30px;
	border-radius: 16px;
	overflow: hidden;
}
.container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	/*width: 30px;
    height: 30px;
    border-radius: 16px;

}*/
}

@media (max-width: 1440px) {
	.container {
		flex-direction: column;
		gap: 3px;
		color: #ffffff;
	}
	.container h5 {
		font-size: 8px;
	}
	.container h5 span,
	.container h5 a {
		padding: 0;
		font-family: var(--myMainFontRegular);
		font-size: 8px;
		text-transform: uppercase;
	}
	.container h5 a {
		width: 40px;
	}
}
@media (max-width: 1024px) {
	.container {
		animation: fadeIn ease 2s;
	}
	.container svg,
	.personalCabinet {
	}
	.container h5 span,
	.container h5 a {
		display: none;
		width: 60px;
	}
	.container img {
		width: 36px;
		height: 38px;
		border-radius: 20px;
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
