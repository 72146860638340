.sellerInfoContainer {
}
.sellerInfoName,
.sellerInfoPhone {
	font-size: 16px;
	line-height: 120%;
	color: #2b2a38;
}
.sellerInfoPhone {
	padding-top: 3px;
}
.sellerChat {
	width: 50%;
	height: 30px;
	margin: 15px 0;
	display: flex;
	justify-content: center;
	gap: 17px;
	align-items: center;
	background: #ffffff;
	border: 1px solid #cecece;
	border-radius: 4px;
}
.sellerChat button {
	color: #727272;
}
.sellerChat span {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
	background: #ffd74a;
	border-radius: 10px;
	color: black;
	font-size: 10px;
}
.sellerNumberOfOrder {
	font-size: 12px;
	color: #000000;
}
.sellerNumberOfOrder span {
	color: #727272;
}
